import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Button } from "@mui/material";
import { s3baseUri } from "../../config/config";
import TextEditor from "../../components/generalComponents/TextEditor";
import { planAdd, planDetail, planEdit } from "../../DAL/Payments/Plans/Plans";
import { stripeProductList } from "../../DAL/Payments/Stripe/StripeProducts";
import { uploadModuleImageOns3 } from "../../DAL/Modules/Modules";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});
export default function AddOrUpdatePaymentPlan() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [file, setProfileImage] = React.useState();
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState();
  const [pageData, setPageData] = useState({});
  const fileChangedHandler = async (e) => {
    setIsImageLoading(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "1000");
    formData.append("height", "670");
    const imageUpload = await uploadModuleImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["plan_image"]: imageUpload.image_path,
      });
      setIsImageLoading(false);
    } else {
      setIsImageLoading(false);
      enqueueSnackbar(imageUpload.message, { variant: "error" });
    }
  };
  const [productName, setProductName] = useState("");
  const [inputs, setInputs] = useState({
    plan_title: "",
    is_plan_free: false,
    is_dont_show_full_amount: true,
    commission_type: "fixed",
    school_commission_amount: "",
    plan_package: "",
    number_of_days: "",
    plan_button_text: "",
    active_campaign_tag_id: 0,
    active_campaign_list_id: 0,
    plan_status: true,
    short_description: "",
    detailed_description: "",
    plan_currency: "gbp",
    plan_price: 0,
    stripe_product_name: "",
    payment_access: "onetime",
    plan_type: "month",
    time_period_interval: 0,
    trial_period_days: 0,
    initial_amount: 0,
    plan_order: 0,
    installment_amount: 0,
    product: "",
    programme: "",
    member: "",
    extra_coins: 0,
    is_new_user_created_for: true,
    subscriber_count: 0,
    plan_image: {},
  });

  const ChangeCommissonAmount = () => {
    if (inputs.payment_access === "onetime") {
      setInputs((values) => ({
        ...values,
      }));
    } else {
      setInputs((values) => ({
        ...values,
      }));
    }
    return true;
  };

  const renderCurrency = () => {
    return (
      <Tooltip title={"You Cannot Change the Plan Currency"}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Plan Currency *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled
            name="plan_currency"
            value={inputs.plan_currency}
            label="Plan currency"
            onChange={handleChange}>
            <MenuItem value="usd">Dollar</MenuItem>
            <MenuItem value="gbp">UK Pounds</MenuItem>
            <MenuItem value="eur">Euro</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    );
  };

  const productList = async () => {
    let result = await stripeProductList();
    if (result.code == 200) {
      setProducts(result.product);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file && !oldImage) {
      enqueueSnackbar("Plan Image is Required", { variant: "error" });
      return;
    }
    if (inputs.payment_access == "recursion") {
      if (inputs.installment_amount == 0) {
        enqueueSnackbar("Installment Amount must be greater than 0", {
          variant: "error",
        });
        return;
      }
      if (inputs.initial_amount == 0) {
        enqueueSnackbar("Initial Amount must be greater than 0", {
          variant: "error",
        });
        return;
      }
    }

    let commissionInfo = {
      commission_type: inputs.commission_type,
      school_commission_amount: inputs.school_commission_amount
        ? inputs.school_commission_amount
        : 0,
    };

    const set_commisson = await ChangeCommissonAmount();
    if (set_commisson) {
      let formDatas = {
        plan_title: inputs.plan_title,
        commission_info: commissionInfo,

        is_new_user_created_for: inputs.is_new_user_created_for,
        plan_package: inputs.plan_package,
        plan_button_text: inputs.plan_button_text,
        active_campaign_list_id:
          inputs.active_campaign_list_id >= 0
            ? inputs.active_campaign_list_id?.toString()
            : "",
        plan_status: inputs.plan_status,
        is_plan_free: inputs.is_plan_free,
        short_description: inputs.short_description,
        detailed_description: inputs.detailed_description,
      };
      if (file) {
        formDatas.plan_image = file ? inputs.plan_image : oldImage;
      } else {
        formDatas.plan_image = oldImage;
      }
      if (params && params.page_id) {
        // formDatas.created_for = "sale_page";
        formDatas.page_id = params.page_id;
      }
      if (formType == "EDIT") {
        formDatas.plan_order = inputs.plan_order;
      }
      if (inputs.payment_access == "onetime") {
        formDatas.plan_price = inputs.plan_price;
        formDatas.stripe_product_name = inputs.stripe_product_name;
      }
      if (inputs.payment_access == "recursion") {
        formDatas.initial_amount = inputs.initial_amount;
        formDatas.installment_amount = inputs.installment_amount;
      }

      if (inputs.is_plan_free == false) {
        formDatas.plan_currency = inputs.plan_currency;

        formDatas.payment_access = inputs.payment_access;
        if (inputs.payment_access == "recursion") {
          formDatas.product = productName;
          // formDatas.time_period_interval = inputs.time_period_interval;
          // formDatas.trial_period_days = 0;
          formDatas.trial_period_days = inputs.trial_period_days
            ? inputs.trial_period_days
            : 0;
          formDatas.plan_type =
            inputs.plan_type == undefined ? "month" : inputs.plan_type;
          formDatas.is_dont_show_full_amount = inputs.is_dont_show_full_amount;
          if (inputs.plan_type == "custom") {
            formDatas.number_of_days =
              inputs.number_of_days == undefined ? 0 : inputs.number_of_days;
          }
        }
      }
      const formObject = formDatas;
      setIsLoading(true);
      const result =
        formType == "ADD"
          ? await planAdd(formObject)
          : await planEdit(formObject, params.plan_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeProduct = (event) => {
    const value = event.target.value;
    setProductName(value);
  };

  const getPageDetail = async () => {};

  const getPlanDetail = async () => {
    const result = await planDetail(params.plan_id);
    if (result.code == 200) {
      handleFormatdata(result.payment_plan);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatdata = (data) => {
    setFormType("EDIT");
    setInputs({
      ...data,
      commission_type: data.commission_info?.commission_type,
      school_commission_amount: data.commission_info?.school_commission_amount,
      is_dont_show_full_amount: data?.is_dont_show_full_amount
        ? data.is_dont_show_full_amount
        : true,
    });
    setProductName(data?.product);
    setIsLoading(false);
  };

  const handlePageDetail = (data) => {
    setPageData(data);
  };
  useEffect(() => {
    productList();
    if (params && params.plan_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page);
        }
        handleFormatdata(state.plan);
      } else {
        getPlanDetail();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      setIsLoading(false);
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);

  useEffect(() => {
    setOldImage(state?.plan.plan_image);
    let totalValue = inputs.plan_price - inputs.initial_amount;
    // let installment = (totalValue / inputs.time_period_interval).toFixed(1);
    // setInputs((values) => ({
    //   ...values,
    //   ["installment_amount"]:
    //     !isNaN(installment) && installment !== "Infinity" ? installment : 0,
    // }));
  }, [inputs.plan_price, inputs.time_period_interval, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData?.sale_page_title}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Plan`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Title"
              variant="outlined"
              fullWidth
              required
              name="plan_title"
              value={inputs.plan_title}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_plan_free"
                value={inputs.is_plan_free}
                disabled
                label="Plan Type"
                onChange={handleChange}>
                <MenuItem value={true}>Free</MenuItem>
                <MenuItem value={false}>Paid</MenuItem>
              </Select>
            </FormControl>
          </div>*/}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                New User Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_new_user_created_for"
                value={inputs.is_new_user_created_for}
                label="New User Status"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="plan_status"
                value={inputs.plan_status}
                label="Plan Status"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_plan_free == false && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Plan Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="payment_access"
                    value={inputs.payment_access}
                    label="Plan Payment"
                    onChange={handleChange}>
                    <MenuItem value="onetime">OneTime</MenuItem>
                    <MenuItem value="recursion">Recurring</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-none" : "d-block"
                }`}>
                {renderCurrency()}
              </div>

              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-block" : "d-none"
                }`}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="plan_type"
                    value={inputs.plan_type ? inputs.plan_type : "month"}
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required>
                    {/* <MenuItem value="week">Weekly</MenuItem> */}
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    {/* <MenuItem value="custom">Custom</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              {/* {inputs.plan_type == "custom" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No of days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="number_of_days"
                    value={inputs.number_of_days}
                    onChange={handleChange}
                  />
                </div>
              )} */}
              {inputs.payment_access == "onetime" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Total Plan Price"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="plan_price"
                    value={inputs.plan_price}
                    onChange={handleChange}
                  />
                </div>
              )}

              {inputs.payment_access == "recursion" ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Initial Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="initial_amount"
                      value={inputs.initial_amount}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Recurring Time Period "
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      name="time_period_interval"
                      value={inputs.time_period_interval}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Installment Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="installment_amount"
                      value={inputs.installment_amount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Trial Period Days"
                      variant="outlined"
                      fullWidth
                      name="trial_period_days"
                      value={inputs.trial_period_days}
                      type="number"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    {renderCurrency()}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign Tag ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_tag_id"
              value={inputs.active_campaign_tag_id}
              onChange={handleChange}
            />
          </div> */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign List ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_list_id"
              value={inputs.active_campaign_list_id}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Don't Show Full Amount ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_dont_show_full_amount"
                value={inputs.is_dont_show_full_amount}
                label="Don't Show Full Amount ? "
                onChange={handleChange}>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Commission Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="commission_type"
                value={inputs.commission_type}
                label="Commission Type"
                onChange={handleChange}>
                <MenuItem value={"fixed"}>Fixed Amount</MenuItem>
                <MenuItem value={"percentage"}>Percentage Amount</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Commission Amount"
              variant="outlined"
              fullWidth
              required
              name="school_commission_amount"
              value={inputs.school_commission_amount}
              type="text"
              step="any"
              InputProps={{
                endAdornment: (
                  <span style={{ color: "#ea7423;" }}>
                    {inputs.commission_type == "percentage" ? "%" : ""}
                  </span>
                ),
                inputProps: {
                  min: 0,
                  max: inputs.commission_type == "percentage" && 100,
                },
              }}
              onChange={(event) => {
                const value = event.target.value;
                if (
                  value === "" ||
                  (inputs.commission_type !== "percentage" &&
                    /^\d+(\.\d{0,2})?$/.test(value)) ||
                  (inputs.commission_type === "percentage" &&
                    /^\d+(\.\d{0,2})?$/.test(value) &&
                    value >= 0 &&
                    value <= 100)
                ) {
                  handleChange(event);
                }
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Button Text"
              variant="outlined"
              fullWidth
              required
              name="plan_button_text"
              value={inputs.plan_button_text}
              onChange={handleChange}
            />
          </div>

          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Plan Order"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="plan_order"
                value={inputs.plan_order}
                onChange={handleChange}
              />
            </div>
          )}
          {inputs.payment_access == "onetime" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Stripe Product Name"
                variant="outlined"
                fullWidth
                required
                name="stripe_product_name"
                value={inputs.stripe_product_name}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Plan Image * </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-3">
                <div className="col-3">
                  {file && !isImageLoading ? (
                    <img className="image-border" src={file} height="70" />
                  ) : (
                    oldImage &&
                    !isImageLoading && (
                      <img
                        className="image-border"
                        src={s3baseUri + oldImage}
                        height="70"
                      />
                    )
                  )}
                </div>
              </div>
              <div className="col-4 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="plan_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="theme-button-contained"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    {isImageLoading ? "Uploading..." : "Upload"}
                  </Button>
                </label>
              </div>
            </div>
          </div>
          {inputs.is_plan_free == false &&
            inputs.payment_access == "recursion" && (
              <div className="col-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Product</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="productName"
                    value={productName}
                    label="product"
                    onChange={handleChangeProduct}>
                    {products.map((product) => {
                      return (
                        <MenuItem value={product._id}>{product.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <label htmlFor="">Detail Description </label>
            <TextEditor
              required
              inputs={inputs}
              setInputs={setInputs}
              name="detailed_description"
            />
          </div>

          <div className="col-12 mt-4">
            <label htmlFor="">Plan Package </label>
            <TextEditor
              required
              inputs={inputs}
              setInputs={setInputs}
              name="plan_package"
            />
          </div>
          <div className="text-end mt-4">
            <button className="theme-button-contained">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
