import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import sidepic from "../../../assets/images/7ead8c3a-23eb-4b24-8ab2-a21d3017d84d.png";
import logo from "../../../assets/images/appheaderlogo.png";
import CustomTextField from "../../../components/customTextField/CustomTextField";
import { ForGotPasswordApi } from "../../../DAL/forgotPassword";
import { GetDeatailAdmin } from "../../../DAL/auth";
import { s3baseUri } from "../../../config/config";

export default function ForgotPassword() {
  const INITIAL_VALUES = {
    email: "",
    loading: false,
    emailSent: false,
  };
  const [forgotPasswordState, setForgotPasswordState] =
    useState(INITIAL_VALUES);
  const [adminSettings, setAdminSettings] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const fetchAdminSettings = async () => {
    const response = await GetDeatailAdmin();
    if (response.code === 200) {
      setAdminSettings(response.admin_portal_settings);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminSettings();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForgotPasswordState((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(forgotPasswordState.email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
      return;
    }

    setForgotPasswordState((old) => ({ ...old, loading: true }));

    const login_data = { email: forgotPasswordState.email };
    const response = await ForGotPasswordApi(login_data);
    if (response.code === 200) {
      enqueueSnackbar("Password reset email sent!", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setForgotPasswordState((old) => ({
        ...old,
        emailSent: true,
        loading: false,
      }));
      navigate("/password-pin", {
        state: { email: forgotPasswordState.email },
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setForgotPasswordState((old) => ({ ...old, loading: false }));
    }
    setForgotPasswordState((old) => ({ ...old, loading: false }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="overflow-hidden m-0 p-0 forgot-page-side-pic">
      <div className="row no-gutters h-100 w-100 m-0 p-0">
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center p-4 overflow-hidden">
          <div className="d-flex flex-column align-items-center w-100 admin-login-logo">
            <img
              style={{ width: "80px" }}
              src={s3baseUri + adminSettings.brand_logo?.thumbnail_1}
              alt="Logo"
            />
            <h4>Forgot Password</h4>
            <form className="mt-4 w-100" noValidate onSubmit={handleSubmit}>
              <CustomTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={forgotPasswordState.email}
                onChange={handleChange}
              />
              <button
                className="w-100 login-button mt-3"
                disabled={forgotPasswordState.loading}
              >
                {forgotPasswordState.loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
            <Link
              className="mt-5 text-decoration-none custom-link cursor-pointer"
              to="/login"
            >
              Back to Login
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-8 d-flex align-items-center justify-content-center p-0 overflow-hidden">
          <div className="overflow-hidden w-100 h-100">
            <img
              className="object-fit-cover h-100 w-100"
              src={
                s3baseUri + adminSettings.login_page_image?.thumbnail_1 ||
                sidepic
              }
              alt="forgot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
