import React, { useState, useEffect } from "react";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import AddorUpdateSchools from "../../components/schools/addorupdateschool";
import { useSnackbar } from "notistack";
import {
  deleteSchoolApi,
  schoolList,
  schoolMemberList,
  schoolMemberTransactionList,
} from "../../DAL/school";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import { useNavigate, useParams } from "react-router";
import { deleteRecordingApi } from "../../DAL/recording";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MemberTransaction = () => {
  const { enqueueSnackbar } = useSnackbar();

  const TABLE_HEAD = [
    // { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Name" },
    { id: "page", label: "Page" },
    { id: "plan", label: "Plan" },
    // { id: "number", label: "Members", type: "number" },
    // { id: "status", label: "Status", type: "row_status" },
  ];

  const INITIAL_VALUES = {
    deleteSchoolId: null,
    deleteDialogOpen: false,
    drawerOpen: false,
    selectedSchool: null,
  };
  const params = useParams();
  const [schoolListState, setSchoolListState] = useState(INITIAL_VALUES);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSchool, setDeleteSchool] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchSchools = async () => {
    let postData = {
      school: params.schoolId,
      member: params.id,
    };
    const response = await schoolMemberTransactionList(postData);
    if (response.code === 200) {
      const schoolsWithDetails = response.trnasactions.map((school, index) => ({
        ...school,
        number: index + 1,
        title: school.member_info.name,
        page: school?.sale_page?.sale_page_title,
        plan: school?.plan?.plan_title,
      }));
      setTotalCount(response.total_members);
      setSchools(schoolsWithDetails);
    } else {
      enqueueSnackbar("Failed to fetch members", { variant: "error" });
    }
    setLoading(false);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    fetchSchools();
  };

  useEffect(() => {
    fetchSchools();
  }, [page, rowsPerPage, searchText]);

  const handleOpenDeleteDialog = (schoolId) => {
    setSchoolListState((old) => ({
      ...old,
      deleteSchoolId: schoolId,
      deleteDialogOpen: true,
    }));
  };

  const handleOpenEditDrawer = (school) => {
    setSchoolListState((old) => ({
      ...old,
      selectedSchool: school,
      drawerOpen: true,
    }));
  };

  const handleMembers = () => {
    navigate(`/schoolList/schoolmembers`);
  };

  // const handleDeleteSchool = async () => {
  //   const response = await deleteSchoolApi(schoolListState.deleteSchoolId);
  //   if (response.code === 200) {
  //     setSchools((oldSchools) =>
  //       oldSchools.filter(
  //         (school) => school._id !== schoolListState.deleteSchoolId
  //       )
  //     );
  //     enqueueSnackbar("School deleted successfully", { variant: "success" });
  //   } else {
  //     enqueueSnackbar("Failed to delete school", { variant: "error" });
  //   }
  //   setSchoolListState((old) => ({ ...old, deleteDialogOpen: false }));
  //   setDeleteDialogOpen(false);
  // };
  const handleDeleteSchool = async () => {
    const response = await deleteSchoolApi(schoolListState.deleteSchoolId);
    if (response.code === 200) {
      setSchools((oldSchools) =>
        oldSchools.filter(
          (school) => school._id !== schoolListState.deleteSchoolId
        )
      );
      enqueueSnackbar("School deleted successfully", { variant: "success" });
    } else {
      enqueueSnackbar("Failed to delete school", { variant: "error" });
    }
    setSchoolListState((old) => ({
      ...old,
      deleteDialogOpen: false,
      deleteSchoolId: null,
    }));
    setDeleteDialogOpen(false);
  };

  const handleOpenDrawer = () => {
    setSchoolListState((old) => ({
      ...old,
      drawerOpen: true,
      selectedSchool: null,
    }));
  };

  const handleCloseDrawer = () => {
    setSchoolListState((old) => ({ ...old, drawerOpen: false }));
  };

  const MENU_OPTIONS = [
    // {
    //   label: "Edit",
    //   icon: "akar-icons:edit",
    //   handleClick(row) {
    //     handleOpenEditDrawer(row);
    //   },
    // },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick(row) {
    //     handleOpenDeleteDialog(row._id);
    //     setDeleteDialogOpen(true);
    //   },
    // },
    {
      label: "Transactions",
      icon: "radix-icons:font-family",
      handleClick: () => handleMembers(),
    },
  ];

  return (
    <>
      <div className="row mb-3 mt-3 schoolList">
        <div className="col-lg-6 col-md-6 d-flex align-items-center">
          <Link
            to={`/schoolList/schoolmembers/${params.schoolId}`}
            className="me-2"
          >
            <ArrowBackIcon />
          </Link>
          <h4 className="mb-0">School Members</h4>
        </div>
        <div className="col-lg-6 col-md-6 d-flex justify-content-end align-items-center ">
          {/* <button
            className="theme-button-contained py-2 px-3"
            onClick={handleOpenDrawer}>
            Add Member
          </button> */}
        </div>
      </div>
      <div className="col-12">
        {loading ? (
          <PageLoading />
        ) : (
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={schools}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            // custom_pagination={{
            //   total_count: totalCount,
            //   rows_per_page: rowsPerPage,
            //   page: page,
            //   handleChangePage: handleChangePage,
            //   onRowsPerPageChange: handleChangeRowsPerPage,
            // }}
            // custom_search={{
            //   searchText: searchText,
            //   setSearchText: setSearchText,
            //   handleSubmit: searchFunction,
            // }}
            pageCount={pageCount}
          />
        )}
      </div>
      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this Member?"}
        handleAgree={handleDeleteSchool}
      />

      <CustomDrawer
        isOpenDrawer={schoolListState.drawerOpen}
        onOpenDrawer={handleCloseDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${
          schoolListState.selectedSchool ? "Update" : "Add"
        } Member`}
        componentToPassDown={
          <AddorUpdateSchools
            onCloseDrawer={handleCloseDrawer}
            selectedObject={schoolListState.selectedSchool}
            refreshList={fetchSchools}
          />
        }
      />
    </>
  );
};

export default MemberTransaction;
