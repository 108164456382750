import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "../../../components/customMUITable/CustomMUITable";
// import logo from "../../assets/images/sidecardpic21.jpg";

const RemoveMembers = () => {
  const INITIAL_VALUES = {
    status: "Specific",
    submitEnabled: false,
    selectedPrograms: [],
    selectedRows: [],
    shortDescription: "",
    loading: true,
  };
  const [removeMemberState, setRemoveMemberState] = useState(INITIAL_VALUES);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRemoveMemberState((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemoveMemberState((old) => ({ ...old, loading: false }));
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setRemoveMemberState((old) => ({
      ...old,
      submitEnabled:
        old.status === "All" ||
        (Array.isArray(old.selectedRows) && old.selectedRows.length > 0),
    }));
  }, [removeMemberState.selectedRows, removeMemberState.status]);

  const handleRemoveMembers = () => {
    enqueueSnackbar("Members removed successfully", { variant: "success" });
    navigate("/programs");
  };

  const rows = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    checkbox: (
      <Checkbox
        checked={removeMemberState.selectedRows.includes(index + 1)}
        onChange={(event) => {
          if (event.target.checked) {
            setRemoveMemberState((old) => ({
              ...old,
              selectedRows: [...old.selectedRows, index + 1],
            }));
          } else {
            setRemoveMemberState((old) => ({
              ...old,
              selectedRows: old.selectedRows.filter((id) => id !== index + 1),
            }));
          }
        }}
      />
    ),
    // image: { src: logo },
    name: `Member ${index + 1} (Member ${index + 1}@gmail.com)`,
    status: true,
  }));

  const handleParentCheckboxChange = (event) => {
    if (event.target.checked) {
      const allIds = rows.map((row) => row.id);
      setRemoveMemberState((old) => ({ ...old, selectedRows: allIds }));
    } else {
      setRemoveMemberState((old) => ({ ...old, selectedRows: [] }));
    }
  };

  const TABLE_HEAD = [
    {
      id: "checkbox",
      label: (
        <Checkbox
          checked={
            rows.length > 0 &&
            removeMemberState.selectedRows.length === rows.length
          }
          onChange={handleParentCheckboxChange}
        />
      ),
    },
    { id: "number", label: "#" },
    { id: "image", label: "Image", type: "thumbnail" },
    { id: "name", label: "Name " },
    { id: "status", label: "Status", type: "row_status" },
  ];

  return (
    <>
      {removeMemberState.loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mb-2">
            <Link className="custom-link" to={"/programs"}>
              Programmes
            </Link>
            {" / "}Remove Member Access
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2 p-2 rounded">
            <h4>Remove Members Access</h4>
          </div>
          <div className="mb-4 col-6">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="programme-access">
                Programme Access For
              </InputLabel>
              <Select
                value={removeMemberState.status}
                onChange={(e) => handleChange(e)}
                name="status"
                label="Programme Access For"
                inputProps={{
                  id: "programme-access",
                }}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="Specific">Specific</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>
          </div>
          {removeMemberState.status === "Specific" && (
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={rows}
                pagePagination={true}
              />
            </div>
          )}
          <button
            variant="contained"
            className="position-fixed bottom-0 end-0 m-3 theme-button-member-remove"
            disabled={
              removeMemberState.status === "Specific" &&
              !removeMemberState.submitEnabled
            }
            onClick={handleRemoveMembers}
          >
            Submit
          </button>
        </>
      )}
    </>
  );
};

export default RemoveMembers;
