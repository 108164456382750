import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { saleDropdownApi } from "../../DAL/sale";
import MUIAutocomplete from "../MuiAutoComplate";
import { activeSchools } from "../../DAL/school";

export default function FilterSubscription({
  handelClear,
  state,
  handleOnChange,
  handleSubmit,
  handleSelectOther,
  fetchData,
  clearFilters,
  onCloseDrawer,
  applyFilters,
  setIsSubmit,
}) {
  const [salePages, setSalePages] = useState([]);
  const [schoolsstatea, setSchools] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const fetchSalePages = async () => {
    const response = await saleDropdownApi();
    if (response && response.sale_page) {
      setSalePages(response.sale_page);
    } else {
      enqueueSnackbar("Failed to fetch sale pages", { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSalePages();
  }, [enqueueSnackbar]);

  const fetchSchools = async () => {
    const response = await activeSchools();
    if (response.code === 200) {
      setSchools(response.schools); // Update to use the correct structure
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchSchools();
  }, []);

  // const handleFilterChange = (e) => {
  //   const { name, value } = e.target;
  //   setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  // };

  // const handleApplyFilters = (e) => {
  //   e.preventDefault();
  //   applyFilters(filters);
  //   setDrawerOpen(false);
  // };

  // const handleClearFilters = () => {
  //   clearFilters();
  //   setFilters({
  //     searchText: "",
  //     startDate: "",
  //     endDate: "",
  //     salePage: "",
  //     status: "",
  //     subscriptionMode: "",
  //   });
  //   setDrawerOpen(false); // Close the drawer after clearing filters
  // };

  return (
    <form className="drawer-form" onSubmit={applyFilters}>
      {/* <MUIAutocomplete
        // multiple
        inputLabel="Sale Pages"
        selectedOption={state.sale_page}
        setSelectedOption={(value) => {
          handleSelectOther("sale_page", value);
        }}
        optionsList={salePages}
        name="sale_page_title"
      /> */}
      <MUIAutocomplete
        // multiple
        inputLabel="School"
        selectedOption={state.school}
        setSelectedOption={(value) => {
          handleSelectOther("school", value);
        }}
        optionsList={schoolsstatea}
        name="school_name"
      />

      {/* <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          label="Status"
          value={state.subscription_status}
          name="subscription_status"
          onChange={handleOnChange}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Failed</MenuItem>
        </Select>
      </FormControl> */}

      <FormControl fullWidth variant="outlined">
        <InputLabel id="subscription-mode-label">Subscription Mode</InputLabel>
        <Select
          labelId="subscription-mode-label"
          label="Subscription Mode"
          value={state.payment_mode}
          name="payment_mode"
          onChange={handleOnChange}
        >
          <MenuItem value="sandBox">Sandbox</MenuItem>
          <MenuItem value="live">Live</MenuItem>
        </Select>
      </FormControl>

      {/* <Box>
        <b>Filter By Date</b>
        <div className="mb-3 mt-3">
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            name="startDate"
            value={state.startDate}
            onChange={handleOnChange}
            InputLabelProps={{ shrink: true }}
          />
        </div>

        <TextField
          fullWidth
          label="End Date"
          type="date"
          name="endDate"
          value={state.endDate}
          onChange={handleOnChange}
          InputLabelProps={{ shrink: true }}
        />
      </Box> */}

      <Box display="flex" flexDirection="column" spacing={2}>
        <Button
          variant="outlined"
          onClick={clearFilters}
          fullWidth
          sx={{ mb: 1 }}
          startIcon={<ClearIcon />}
        >
          Clear All
        </Button>
        <Button
          variant="outlined"
          type="submit"
          fullWidth
          onClick={handleSubmit}
          startIcon={<FilterAltIcon style={{ color: "#fff" }} />}
          sx={{
            backgroundColor: "#EA7423",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#EA7423",
              color: "#FFF",
            },
          }}
        >
          Filter
        </Button>
      </Box>
    </form>
  );
}
