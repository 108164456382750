import { invokeApi } from "../utils/invokeApi";

export const familyMembers = async (page, limmit, body) => {
  const requestObj = {
    path: `api/member/list_member_family_members_by_admin?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: body,
  };
  return invokeApi(requestObj);
};
export const addFamilyMembers = async (data) => {
  const requestObj = {
    path: `api/member/add_family_member_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editFamilyMembers = async (family_member_user_id, data) => {
  const requestObj = {
    path: `api/member/update_family_member_by_admin/${family_member_user_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletefamilyMembers = async (family_member_user_id) => {
  const requestObj = {
    path: `api/member/delete_family_member_by_admin/${family_member_user_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
