import { invokeApi } from "../utils/invokeApi";

export const s3ImageUpload = async (data) => {
  const requestObj = {
    path: `api/admin/update_editor_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
