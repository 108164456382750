import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import AddOrUpdateCategory from "../../components/recordingCatogeryComponent/AddOrUpdateCategory";
import {
  RecordingCategoryListApi,
  deleteRecordingCategory,
} from "../../DAL/recordingCategories";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";

const RecordingCategory = () => {
  const INITIAL_VALUES = {
    deleteCategoryId: null,
    loading: true,
    deleteDialogOpen: false,
    openDrawer: false,
    selectedCategory: null,
    categories: [],
  };

  const [recordingCategoryState, setRecordingCategoryState] =
    useState(INITIAL_VALUES);
  const [selectedMember, setSelectedMember] = useState(null);
  const [familyMemberId, setFamilyMemberId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchCategories = async () => {
    let postData = {
      search: searchText,
    };

    const response = await RecordingCategoryListApi(
      page,
      rowsPerPage,
      postData
    );
    if (response.code === 200) {
      const categoriesWithProgramTitles =
        response.program_recording_categories.map((category) => ({
          profile_photo: {
            src: s3baseUri + category.image?.thumbnail_1,
            alt: category.name,
          },
          ...category,
          program_titles: category.program_info
            .map((program) => program.program_title)
            .join(", "),
        }));
      setTotalCount(response.total_program_categories);
      setTotalPages(
        Math.ceil(response.total_program_categories / rowsPerPage) - 1
      );

      setRecordingCategoryState((old) => ({
        ...old,
        categories: categoriesWithProgramTitles,
        loading: false,
      }));
    } else {
      enqueueSnackbar("Failed to fetch categories", { variant: "error" });
      setRecordingCategoryState((old) => ({ ...old, loading: false }));
    }
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage]);

  const handleOpenDeleteDialog = (categoryId) => {
    setRecordingCategoryState((old) => ({
      ...old,
      deleteCategoryId: categoryId,
      deleteDialogOpen: true,
    }));
  };

  const handleDeleteCategory = async () => {
    const response = await deleteRecordingCategory(
      recordingCategoryState.deleteCategoryId
    );
    if (response.code === 200) {
      setRecordingCategoryState((old) => ({
        ...old,
        deleteDialogOpen: false,
        categories: old.categories.filter(
          (category) =>
            category.program_recording_category_slug !== old.deleteCategoryId
        ),
      }));
      enqueueSnackbar(response.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleOpenEditDrawer = (category) => {
    setSelectedMember(category);
    setRecordingCategoryState((old) => ({
      ...old,
      selectedCategory: category,
      openDrawer: true,
    }));
  };

  const handleOpenDrawer = () => {
    setRecordingCategoryState((old) => ({ ...old, openDrawer: true }));
  };

  const handleCloseDrawer = () => {
    setRecordingCategoryState((old) => ({
      ...old,
      openDrawer: false,
      selectedCategory: null,
    }));
    setSelectedMember(null);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "profile_photo", label: "Image", type: "thumbnail" },
    { id: "title", label: "Title " },
    // { id: "short_description", label: "Description" },
    {
      id: "program_titles",
      label: "Programme",
      renderData: (row) => {
        return (
          <>
            {row.program_info && row.program_info.length > 0
              ? row.program_info.map((item, index) => (
                  <p key={index} style={{ marginBottom: "0px" }}>
                    {item.program_title}
                  </p>
                ))
              : "N/A"}
          </>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: async (row, index) => {
        setSelectedMember(row);
        setFamilyMemberId(row.program_recording_category_slug);
        handleOpenEditDrawer(row);
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: async (row, index) => {
        handleOpenDeleteDialog(row.program_recording_category_slug);
      },
    },
  ];

  const handleCloseDeleteDialog = () => {
    setRecordingCategoryState((old) => ({
      ...old,
      deleteDialogOpen: false,
    }));
  };
  return (
    <>
      {recordingCategoryState.loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="row mb-3 mt-3 recordingCatogoryList">
            <div className="col-lg-6 col-md-6 d-flex align-items-center">
              <h4 className="mb-0">Recording Categories</h4>
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-end align-items-center ">
              <button
                className="theme-button-contained "
                onClick={handleOpenDrawer}
              >
                Add Category
              </button>
            </div>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={recordingCategoryState.categories}
              pagePagination={true}
              MENU_OPTIONS={MENU_OPTIONS}
              loading={recordingCategoryState.loading}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
          <CustomDrawer
            isOpenDrawer={recordingCategoryState.openDrawer}
            onOpenDrawer={handleOpenDrawer}
            onCloseDrawer={handleCloseDrawer}
            pageTitle={`${
              recordingCategoryState.selectedCategory ? "Update" : "Add"
            } Category`}
            componentToPassDown={
              <AddOrUpdateCategory
                onCloseDrawer={handleCloseDrawer}
                selectedObject={selectedMember}
                refreshCategories={fetchCategories}
              />
            }
          />
          <CustomConfirmation
            open={recordingCategoryState.deleteDialogOpen}
            title={"Are you sure you want to delete this category?"}
            handleAgree={handleDeleteCategory}
            setOpen={handleCloseDeleteDialog}
          />
        </>
      )}
    </>
  );
};

export default RecordingCategory;
