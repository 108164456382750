import AppRoutes from "./routes/routes";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import theme from "./theme/mui_theme";
import "./assets/style/style.css";
import { ContextAdmin } from "./hooks/AdminContext";

function App() {
  return (
    <ContextAdmin>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <AppRoutes />
        </SnackbarProvider>
      </ThemeProvider>
    </ContextAdmin>
  );
}

export default App;
