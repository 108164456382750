import { invokeApi } from "../utils/invokeApi";

export const detailEditProfileApi = async () => {
  const requestObj = {
    path: `api/admin/detail_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const editProfilrApi = async (data) => {
  const requestObj = {
    path: `api/admin/edit_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
