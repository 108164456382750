import { invokeApi } from "../../../utils/invokeApi";

export const stripeProductList = async (data) => {
  const requestObj = {
    path: `api/product/list_product`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const stripeProductAdd = async (data) => {
  const requestObj = {
    path: `api/product/add_product`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const stripeProductEdit = async (data, id) => {
  const requestObj = {
    path: `api/product/update_product/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const stripeProductDetail = async (id) => {
  const requestObj = {
    path: `api/product/detail_product/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const stripeProductDelete = async (id) => {
  const requestObj = {
    path: `api/product/delete_product/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
