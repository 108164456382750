import React, { useEffect, useState } from "react";
import { TextField, Divider, MenuItem, FormControl } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { FileUpload } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { editMembers, memberDetail } from "../../DAL/members";
import { s3baseUri } from "../../config/config";
import moment from "moment";
import PageLoading from "../generalComponents/PageLoading";
import { activeSchools } from "../../DAL/school";

const EditMembers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [newImageSelected, setNewImageSelected] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    shortDescription: "",
    country: "",
    contactNumber: "",
    status: true,
    dob: "",
    gender: "",
    city: "",
    zipCode: "",
    memberShip: true,
    membership_date: "",
    email: "",
    school: "no-school",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingResource, setLoadingResource] = useState(true);
  const [schoolsstatea, setSchools] = useState([]);

  const location = useLocation();
  const member = location.state?.member;
  useEffect(() => {
    setFormData({
      name: member.name,
      image: member?.profile_image?.thumbnail_1,
      shortDescription: member.biography,
      email: member.email,
      contactNumber: member.contact_number,
      status: member.status,
      dob: new Date(member.date_of_birth_iso).toISOString().split("T")[0],
      gender: member.gender,
      city: member.city,
      zipCode: member.zipcode,
      school: member?.school_info
        ? member?.school_info._id
          ? member?.school_info._id
          : "no-school"
        : "no-school",
      // school: "no-school",
      memberShip: member.is_member_paid,
      country: member.country,
      membership_date: member.membership_date
        ? new Date(member.membership_date).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0], // Use member's date or today's date
    });
  }, [member]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    let newValue = value;
    if (files) {
      setNewImageSelected(true);
      newValue = files[0];
    }
    // if (name === "dob") {
    //   newValue = moment(value).format("DD/MM/YYYY");
    // }

    if (files) {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("contact_number", formData.contactNumber);
    formDataObj.append("date_of_birth", formData.dob);
    formDataObj.append("gender", formData.gender);
    formDataObj.append("city", formData.city);
    formDataObj.append("zipcode", formData.zipCode);
    formDataObj.append("is_member_paid", formData.memberShip);
    if (formData.memberShip) {
      formDataObj.append("membership_date", formData.membership_date);
    }
    formDataObj.append("status", formData.status);
    formDataObj.append("country", formData.country);
    formDataObj.append("biography", formData.shortDescription);
    if (formData.school !== "no-school") {
      formDataObj.append("school", formData.school);
    }

    if (newImageSelected) {
      formDataObj.append("profile_image", formData.image);
    }

    const response = await editMembers(member.user_id, formDataObj);
    if (response.code === 200) {
      navigate("/members");
      enqueueSnackbar("Member updated successfully.", { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };
  const fetchSchools = async () => {
    const response = await activeSchools();
    if (response.code === 200) {
      setSchools(response.schools); // Update to use the correct structure
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchSchools();
  }, []);

  const renderImagePreview = (file) => {
    if (!file) return null;

    let url = "";
    if (file instanceof File) {
      url = URL.createObjectURL(file);
    } else {
      url = s3baseUri + file;
    }

    return (
      <>
        {url && (
          <img
            src={url}
            alt="Preview"
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        )}
      </>
    );
  };

  // if (loadingResource) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className=" col-md-6 d-flex mb-3">
        <Link to="/members">
          <ArrowBackIcon />
        </Link>
        <h4 style={{ marginLeft: "20px" }}>Edit Member</h4>
      </div>
      <div className="div-gapmembers mb-3">
        <div className="col-md-6 mb-0">
          <TextField
            fullWidth
            required
            label="Name"
            variant="outlined"
            InputProps={{ style: { height: 56, borderRadius: 8 } }}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 mb-0">
          <TextField
            fullWidth
            required
            label="Email"
            variant="outlined"
            InputProps={{ style: { height: 56, borderRadius: 8 } }}
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            disabled
          />
        </div>
      </div>

      <div className="row admin-defaukt-settings-div mb-0">
        <div className="div-gapmembers">
          <div className="col-md-6">
            <TextField
              fullWidth
              label="Contact Number"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-6">
            <TextField
              fullWidth
              required
              select
              label="Status"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="status"
              value={formData?.status}
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="div-gapmembers">
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              type="date"
              label="Date of Birth"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
            />
          </div>

          {/* Gender Field */}
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              select
              label="Gender"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="col-12 d-flex div-gapmembers">
          <div className="col-md-6">
            <TextField
              required
              fullWidth
              select
              label="Paid"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="memberShip"
              value={formData.memberShip}
              onChange={handleInputChange}
            >
              <MenuItem value={false}>Lock</MenuItem>
              <MenuItem value={true}>Unlock</MenuItem>
            </TextField>
          </div>
          {formData.memberShip && (
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                type="date"
                label="Membership Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="membership_date"
                value={formData.membership_date}
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>

        {/* Address Details Section */}
        <div className="col-lg-12">
          <h5>Address Details</h5>
        </div>
        <div className="div-gapmembers">
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              label="City"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-6">
            <TextField
              fullWidth
              type="number" // Set the input type to 'number'
              inputProps={{ min: 1 }} // Set minimum value allowed (optional)
              required
              label="Zip Code"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="zipCode"
              value={formData.zipCode}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-12 d-flex div-gapmembers">
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              label="Country"
              variant="outlined"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6">
            <FormControl fullWidth variant="outlined">
              <TextField
                select
                label="School"
                name="school"
                required
                value={formData.school}
                onChange={handleInputChange}
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                id="school"
              >
                <MenuItem value="no-school">No School</MenuItem>

                {schoolsstatea.map((school) => (
                  <MenuItem key={school.id} value={school._id}>
                    {school.school_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        </div>

        {/* Upload Image Section */}
        <div className="col-lg-12 mt-4">
          {" "}
          <Divider />{" "}
        </div>

        <div className="col-12 d-flex mt-4" style={{ alignItems: "center" }}>
          <div className="col-4 m-0">
            <p>Upload image*</p>
            <p className="image-guide-members m-0">
              {" "}
              Recommended image size (300x300) and file types (jpg,png){" "}
            </p>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            {renderImagePreview(formData.image)}
          </div>
          <div className="d-flex justify-content-end col-4">
            <label className="theme-button-contained" htmlFor="image-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="image-upload"
              type="file"
              hidden
              name="image"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-12 mt-4">
          {" "}
          <Divider />{" "}
        </div>
        <div className="col-12">
          <TextField
            fullWidth
            multiline
            rows={8}
            label="Short Description"
            variant="outlined"
            InputProps={{ style: { borderRadius: 8, height: 200 } }}
            name="shortDescription"
            value={formData.shortDescription}
            onChange={handleInputChange}
          />
          <p className="add-member-p mt-1">
            Member Biography (Maximum limit 300 characters)
          </p>
        </div>

        <div className="col-12">
          <Divider />
        </div>

        {/* Submit Button */}
        <div className="col-12 d-flex justify-content-end">
          <button
            className="theme-button-contained "
            onClick={() => handleSubmit()}
          >
            {loading ? <CircularProgress size={15} /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMembers;
