import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  CircularProgress,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleImageExtensions } from "../../utils/constant";
import { s3baseUri } from "../../config/config";
import {
  addWebPageModuleListing,
  detailModule,
  detailWebPageModule,
  editWebPageModuleListing,
  uploadModuleImageOns3,
} from "../../DAL/Modules/Modules";
import WebsiteTextEditor from "../../components/generalComponents/WebsiteTextEditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdateModuleData() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [templateFields, setTemplateFields] = useState([]);
  const [salePageData, setSalePageData] = useState({});
  const [templateFieldsData, setTemplateFieldsData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [imageSize, setImageSize] = useState("");
  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleStatus, setModuleStatus] = useState(true);
  const { state } = useLocation();
  const [inputsData, setInputsData] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const getTemplateFieldList = async (formValue) => {
    setIsLoading(true);
    const result = await detailWebPageModule(
      formValue == "ADD"
        ? state?.data._id
        : state?.data?.module_configuration?._id
    );
    if (result.code === 200) {
      setSalePageData(result?.module_configuration);
      setTemplateFields(
        result?.module_configuration?.module_configuration_attributes_info
      );

      if (formValue == "EDIT") {
        getModuleDetail();
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getModuleDetail = async () => {
    const result = await detailModule(state?.data._id);
    if (result.code === 200) {
      setModuleTitle(result.module.module_title);
      setModuleStatus(result.module.status);
      let temp = result?.module?.module_data;
      if (!result?.module?.module_data) {
        temp = {};
        salePageData.module_configuration_attributes_info.map((item) => {
          temp[item.attribute_db_name] = "";
        });
      }
      const filteredKeys =
        result.module.module_configuration.module_configuration_attributes_info
          .filter((field) => field.attribute_type === "editor")
          .map((field) => field.attribute_db_name);
      const filteredTemplateFieldsData = Object.fromEntries(
        Object.entries(result?.module?.module_data || {}).filter(([key]) =>
          filteredKeys.includes(key)
        )
      );

      setTemplateFieldsData(filteredTemplateFieldsData);
      setInputsData(temp);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let tempKeys = Object.keys(inputsData);
    let tempValues = Object.values(inputsData);
    let tempData = {};

    for (let i = 0; i < tempValues.length; i++) {
      const value = tempValues[i];

      if (typeof value !== "string" && value.file) {
        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("width", value.width);
        formData.append("height", value.height);
        formData.append(
          "image_extension",
          JSON.stringify([
            ".png",
            ".jpg",
            ".jpeg",
            ".webp",
            ".gif",
            ".JPG",
            ".JPEG",
            ".PNG",
            ".WEBP",
            ".GIF",
          ])
        );

        const imgResult = await uploadModuleImageOns3(formData);
        if (imgResult.code === 200) {
          tempData[tempKeys[i]] = imgResult.image_path;
        }
      } else {
        tempData = { ...tempData, [tempKeys[i]]: value };
      }
    }
    const mergedData = { ...tempData, ...templateFieldsData };
    let postdata = {
      module_data: mergedData,
      module_configuration: state?.data._id,
      web_page_id: params.page_id,
      status: moduleStatus,
      module_title: moduleTitle,
    };
    let editPostData = {
      module_data: mergedData,
      status: moduleStatus,
      module_title: moduleTitle,
    };

    const result =
      formType == "ADD"
        ? await addWebPageModuleListing(postdata)
        : await editWebPageModuleListing(editPostData, state?.data._id);
    if (result.code === 200) {
      enqueueSnackbar(
        `${salePageData?.module_configuration_name} ${
          formType == "ADD" ? "Added" : "Updated"
        } Suceessfully`,
        { variant: "success" }
      );
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeDate = (event, name) => {
    setTemplateFieldsData((values) => ({ ...values, [name]: event }));
  };

  useEffect(() => {
    if (!!state && state?.data && state?.formType == "EDIT") {
      setFormType("EDIT");
      getTemplateFieldList("EDIT");
    } else {
      setIsLoading(false);
      setFormType("ADD");
      getTemplateFieldList("ADD");
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 d-flex mobile-margin display-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <h4 style={{ marginLeft: "20px" }}>
            {formType == "ADD" ? "Add Module Data" : "Update Module Data"}
          </h4>
          <p className="sale-page-title mb-0 mt-1 ps-2">
            ({salePageData.module_configuration_name})
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              type="text"
              required
              name="moduleTitle"
              value={moduleTitle}
              onChange={(e) => setModuleTitle(e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                required
                value={moduleStatus}
                onChange={(e) => setModuleStatus(e.target.value)}
                label="Status"
                variant="outlined"
                name="status">
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {templateFields?.map((template, i) => {
            return (
              <>
                {template.attribute_type == "input" ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4" key={i}>
                    <TextField
                      label={template?.attribute_label}
                      variant="outlined"
                      fullWidth
                      type="text"
                      required={template.required == true ? true : false}
                      name={template?.attribute_db_name}
                      value={inputsData[template?.attribute_db_name]}
                      onChange={(e) =>
                        setInputsData((prev) => {
                          return {
                            ...prev,
                            [template.attribute_db_name]: e.target.value,
                          };
                        })
                      }
                      InputLabelProps={{
                        shrink: !!inputsData[template?.attribute_db_name], // Ensures label stays above when value exists
                      }}
                      autoComplete="off"
                    />
                  </div>
                ) : template.attribute_type == "file" ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="row w-100 div-style ms-0 pt-0">
                      <div className="col-5">
                        <p className="">
                          {template.attribute_label}
                          {template.required == true ? " *" : ""}
                        </p>
                      </div>
                      <div className="col-2">
                        {inputsData[template.attribute_db_name] &&
                          typeof inputsData[template.attribute_db_name] !==
                            "string" && (
                            <img
                              height="80px"
                              width="100px"
                              // src={URL.createObjectURL(
                              //   inputsData[template.attribute_db_name]
                              // )}
                              src={URL.createObjectURL(
                                inputsData[template.attribute_db_name]?.file
                              )}
                            />
                          )}
                        {inputsData[template.attribute_db_name] &&
                          typeof inputsData[template.attribute_db_name] ===
                            "string" && (
                            <img
                              height="80px"
                              width="100px"
                              src={
                                s3baseUri +
                                inputsData[template.attribute_db_name]
                              }
                            />
                          )}
                      </div>
                      <div className="col-2"></div>
                      <div className="col-3 text-end pt-2">
                        <label htmlFor={template.attribute_db_name}>
                          <Input
                            accept="image/*"
                            id={template.attribute_db_name}
                            multiple
                            type="file"
                            name={template.attribute_db_name}
                            onChange={(e) => {
                              const file = e.target.files[0]; // Check if a file is selected
                              if (file) {
                                const img = new Image();
                                const objectUrl = URL.createObjectURL(file); // Create object URL

                                img.onload = function () {
                                  const width = template.width;
                                  const height = template.height;
                                  // Update inputsData with the new file and its dimensions
                                  setInputsData((prev) => ({
                                    ...prev,
                                    [template.attribute_db_name]: {
                                      file,
                                      width,
                                      height,
                                    },
                                  }));

                                  URL.revokeObjectURL(objectUrl); // Revoke URL to avoid memory leaks
                                };

                                img.onerror = () => {
                                  URL.revokeObjectURL(objectUrl); // Ensure URL is revoked even on error
                                  console.error("Failed to load the image.");
                                };

                                img.src = objectUrl; // Assign the object URL to the image source
                              }
                            }}
                          />

                          <Button
                            variant="outlined"
                            startIcon={
                              <FileUploadIcon className="uploadIcon" />
                            }
                            component="span">
                            Upload
                          </Button>
                        </label>
                      </div>

                      {inputs[template?.attribute_db_name]}
                    </div>
                    <p className="text-muted">
                      {"Recommended size (" +
                        template.width +
                        "px * " +
                        template.height +
                        "px ) "}
                      {"( " +
                        handleImageExtensions(template.image_extension) +
                        " )"}
                    </p>
                  </div>
                ) : template.attribute_type == "editor" ? (
                  <div className="col-12 mt-4">
                    <div className="d-flex justify-content-between">
                      <h4 className="mb-2">
                        {template?.attribute_label}
                        {template.required == true ? " *" : ""}
                      </h4>
                    </div>

                    <WebsiteTextEditor
                      setInputs={setInputs}
                      inputs={inputs}
                      name={template.attribute_db_name}
                      setTemplateFieldsData={setTemplateFieldsData}
                      templateFieldsData={templateFieldsData}
                    />
                  </div>
                ) : template.attribute_type == "date" ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label={template?.attribute_label}
                          inputFormat="dd-MM-yyyy"
                          value={
                            templateFieldsData !== undefined
                              ? templateFieldsData[template?.attribute_db_name]
                              : inputs.template?.attribute_db_name
                          }
                          onChange={
                            templateFieldsData == undefined
                              ? (e) =>
                                  handleChangeDate(
                                    e,
                                    template?.attribute_db_name
                                  )
                              : (e) =>
                                  handleChangeDate(
                                    e,
                                    template?.attribute_db_name
                                  )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={template?.attribute_db_name}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}

          <div className="text-end mt-4">
            <button className="theme-button-contained">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
}
