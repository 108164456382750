import React, { useState } from "react";
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { headerLogo } from "../../assets";
import { Edit as EditIcon, Lock as LockIcon } from "@mui/icons-material";
import GeneralMuiModal from "../../components/generalComponents/GeneralMuiModal";
import ChangePassword from "../../components/account/ChnagePassword";
import { useNavigate } from "react-router-dom";
import { logoutApi } from "../../DAL/logout";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../hooks/AdminContext";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userInfo } = useAdminContext();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleProfileNavigate = () => {
    navigate("/edit-profile");
  };

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
    handleMenuClose();
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogOut = async () => {
    setLoading(true);
    const response = await logoutApi({});

    if (response.code === 200) {
      enqueueSnackbar("Logged out successfully", { variant: "success" });
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      navigate("/login");
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
    handleLogoutDialogClose();
  };

  const MENU_OPTIONS = [
    {
      title: "Edit Profile",
      icon: <EditIcon />,
      handleClick: handleProfileNavigate,
    },
    {
      title: "Change Password",
      icon: <LockIcon />,
      handleClick: handleDialogOpen,
    },
  ];

  // let user_name = JSON.parse();

  return (
    <>
      <IconButton
        edge="end"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        color="inherit"
        sx={{ position: "relative" }}>
        <Avatar
          sx={{ marginRight: "10px" }}
          alt="Logo"
          src={headerLogo}
          className="logoAvatar"
        />
      </IconButton>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 22,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            zIndex: 0,
            transform: "rotate(45deg)",
          },
        }}>
        <div className="account-name px-3 mb-2">
          <h3>Core Ni Admin</h3>
          <p className="mb-0">{userInfo?.name}</p>
          <p>{userInfo?.email}</p>
        </div>
        <Divider />
        {MENU_OPTIONS.map((item) => (
          <MenuItem onClick={item.handleClick} key={item.title}>
            <div className="account-menu-option">
              {item.icon}
              <div className="menu-title">{item.title}</div>
            </div>
          </MenuItem>
        ))}
        <div className="text-center my-2">
          <button
            className="theme-button-contained"
            onClick={handleLogoutDialogOpen}>
            Logout
          </button>
        </div>
      </Menu>

      <GeneralMuiModal
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={"Change Password"}
        componentToPass={<ChangePassword />}
      />

      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description">
        <DialogContent sx={{ margin: 0 }}>
          <DialogContentText
            sx={{ fontWeight: "bold", marginBottom: 0, fontSize: "20px" }}
            id="logout-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutDialogClose}
            sx={{ fontWeight: "bold", color: "" }}
            disabled={loading}>
            No
          </Button>
          <Button
            onClick={handleLogOut}
            sx={{ color: "red", fontWeight: "bold" }}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={15} /> : null}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
