import { invokeApi } from "../../../utils/invokeApi";

export const planList = async (id) => {
  const requestObj = {
    path: `api/payment_plan/list_payment_plan_by_sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const planAdd = async (data) => {
  const requestObj = {
    path: `api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const planEdit = async (data, id) => {
  const requestObj = {
    path: `api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const planDetail = async (id) => {
  const requestObj = {
    path: `api/payment_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const planDelete = async (id) => {
  const requestObj = {
    path: `api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
