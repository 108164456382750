import { invokeApi } from "../utils/invokeApi";

export const emailTemplatesListApi = async () => {
  const requestObj = {
    path: `api/email_template/list_email_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getEmailTemplate = async (email_template_slug) => {
  const requestObj = {
    path: `api/email_template/detail_email_template/${email_template_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const updateEmailTemplate = async (email_template_slug, data) => {
  const requestObj = {
    path: `api/email_template/update_email_template/${email_template_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
