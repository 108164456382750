import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function FieldPractice() {
  const [fields, setFields] = useState([{ id: 1, value: "" }]);

  const handleAddField = (index) => {
    const newField = { id: Date.now(), value: "" };
    const newFields = [...fields];
    newFields.splice(index + 1, 0, newField);
    setFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    if (newFields.length > 1) {
      newFields.splice(index, 1);
      setFields(newFields);
    }
  };

  const handleChange = (index, event) => {
    const newFields = [...fields];

    newFields[index].value = event.target.value;
    setFields(newFields);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        gap: "10px",
      }}
    >
      {fields.map((field, index) => (
        <div
          key={field.id}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
          }}
        >
          <TextField
            label={`Field ${index + 1}`}
            variant="outlined"
            value={field.value}
            onChange={(event) => handleChange(index, event)}
            sx={{ width: "500px" }}
          />
          <IconButton
            onClick={() => handleAddField(index)}
            sx={{ marginLeft: 0 }}
          >
            <AddIcon
              style={{
                borderRadius: "50%",
                backgroundColor: "#EA7423",
                color: "white",
              }}
            />
          </IconButton>
          {fields.length > 1 && (
            <IconButton onClick={() => handleRemoveField(index)}>
              <RemoveIcon
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#EA7423",
                  color: "white",
                }}
              />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
}
