import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import {
  deleteWebPageModule,
  webPageModuleListing,
} from "../../DAL/Modules/Modules";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import {
  stripeProductDelete,
  stripeProductList,
} from "../../DAL/Payments/Stripe/StripeProducts";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",

    type: "action",
  },
  {
    id: "number",
    label: "#",
    type: "number",
  },

  {
    id: "name",
    label: "Name",
  },
  {
    id: "stripe_product_id",
    label: "Stripe Product Id",
  },
  //   {
  //     id: "createdAt",
  //     label: "Created At",
  //   },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const StripeProducts = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [moduleListing, setModuleListing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const is_website_pages = window.location.pathname.includes("website-pages");

  const handleNavigate = () => {
    navigate(`/stripe-products/add-product`);
  };

  const handleEditjmj = (value) => {
    if (is_website_pages) {
      navigate(
        `/website-pages/${params?.page_id}/${state?.module_configuration_slug}/edit-module/${value?._id}`,
        {
          state: {
            data: value,
            formType: "EDIT",
          },
        }
      );
    } else {
      navigate(
        `/template-pages/${params?.page_id}/${state?.module_configuration_slug}/edit-module/${value?._id}`,
        {
          state: {
            data: value,
            formType: "EDIT",
          },
        }
      );
    }
  };
  const handleEdit = (value) => {
    navigate(`/stripe-products/edit-product/${value._id}`, {
      state: value,
    });
  };
  const getProductListing = async () => {
    setIsLoading(true);
    const postdata = {
      page_id: params?.page_id,
      module_configuration: state?._id,
    };
    const result = await stripeProductList(postdata);
    if (result.code === 200) {
      const data = result.product.map((product, index) => {
        return {
          ...product,
          status: true,
        };
      });
      setModuleListing(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await stripeProductDelete(selectedRow?._id);
    if (result.code === 200) {
      await getProductListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedRow(value);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getProductListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-2 mt-2">
            <h4>Stripe Products</h4>
          </div>
          <div className="col-md-6 mb-2 mt-2 text-end justify-content-end">
            <button
              onClick={() => {
                handleNavigate();
              }}
              className="theme-button-contained"
            >
              Add Product
            </button>
          </div>

          {/* )} */}
          <div className="col-12 mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={moduleListing}
              bubbleSize={"55%"}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>

        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogTitle>Delete Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
            <Button onClick={() => handleDelete()} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default StripeProducts;
