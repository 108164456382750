import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { membersListApi, deleteMemberApi } from "../../DAL/members";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { getMenuListApi } from "../../DAL/webPages/webPages";
import { deleteMenuApi } from "../../DAL/Menus/Menus";

export default function Menus() {
  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    members: [],
  };
  const [memberState, setMemberState] = useState(INITIAL_VALUES);
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting_memberid, setDeletingMemberId] = useState("");

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "menu_title", label: "Menu" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await getMenuListApi();
      if (response.code === 200) {
        let members = response.menus.map((member) => {
          return {
            ...member,
          };
        });
        setMembersList(members);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleAddMember = () => {
    navigate("/menu/add-menu");
  };

  const handleDeleteMember = async () => {
    const response = await deleteMenuApi(deleting_memberid);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setMembersList(
        membersList.filter((member) => member.menu_slug !== deleting_memberid)
      );
      setDeleteDialogOpen(false);
    } else {
      setDeleteDialogOpen(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/menu/${value._id}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeletingMemberId(value.menu_slug);
    setDeleteDialogOpen(true);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Menus</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <button className="theme-button-contained" onClick={handleAddMember}>
            Add Menu
          </button>
        </div>
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
          />
        </div>
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Menu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this menu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleDeleteMember(deleting_memberid)}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
