import React, { useEffect, useState } from "react";
import { TextField, Divider, MenuItem, FormControl } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { activeSchools } from "../../DAL/school";
import { addSchoolTransactions } from "../../DAL/Payments/Transactions/Transactions";

const AddSchoolTransaction = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    payable: "",
    remaining: "",
    shortDescription: "",
    is_member_paid: "gbp",
    school: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [schoolsstatea, setSchools] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formDataObj = new FormData();
    if (formData.payable > formData.school?.due_commission) {
      setLoading(false);
      enqueueSnackbar(
        "Payable Amount can not be greater than the net remaining amount",
        { variant: "error" }
      );
      return;
    }
    if (formData.payable == "") {
      setLoading(false);
      enqueueSnackbar(" Payable Amount is Required", { variant: "error" });
      return;
    }
    if (formData.school) {
      formDataObj.append("school", formData.school?._id);
    }
    formDataObj.append("amount", formData.payable);

    formDataObj.append("transaction_note", formData.shortDescription);

    const response = await addSchoolTransactions(formDataObj);
    if (response.code === 200) {
      navigate(-1);
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };
  const fetchSchools = async () => {
    const response = await activeSchools();
    if (response.code === 200) {
      setSchools(response.schools); // Update to use the correct structure
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchSchools();
  }, []);

  return (
    <div className="container-fluid">
      <div className="col-md-6 d-flex mb-2 mt-2 " style={{ marginLeft: "5px" }}>
        <Link to="/school-transactions">
          <ArrowBackIcon />
        </Link>
        <h4 className="container">Add Transaction</h4>
      </div>

      <div
        className="container admin-defaukt-settings-div"
        container
        spacing={2}
      >
        <div className="col-12 d-flex div-gapmembers">
          <div className="col-md-6">
            <Tooltip title={"You Cannot Change the Plan Currency"}>
              <TextField
                fullWidth
                required
                select
                label="Plan Currency "
                variant="outlined"
                disabled
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="is_member_paid"
                value={formData.is_member_paid}
                onChange={handleInputChange}
              >
                <MenuItem value={"gbp"}>UK Pounds</MenuItem>
                <MenuItem value={"dollar"}>dollar</MenuItem>
              </TextField>
            </Tooltip>
          </div>
          <div className="col-md-6">
            <FormControl fullWidth variant="outlined">
              <TextField
                select
                label="School"
                name="school"
                value={formData.school}
                onChange={handleInputChange}
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                id="school"
              >
                {schoolsstatea.map((school) => (
                  <MenuItem key={school.id} value={school}>
                    {school.school_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        </div>

        <div className="div-gapmembers">
          {formData.school && (
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                disabled
                label="Net Remaining"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="remaining"
                value={
                  formData.school?.due_commission == undefined
                    ? 0
                    : formData.school?.due_commission.toFixed(1)
                }
                onChange={handleInputChange}
              />
            </div>
          )}
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              label="Payable Amount"
              variant="outlined"
              type="number"
              InputProps={{ style: { height: 56, borderRadius: 8 } }}
              name="payable"
              value={formData.payable}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-lg-12 mt-4">
          {" "}
          <Divider />{" "}
        </div>
        <div className="col-12">
          <TextField
            fullWidth
            multiline
            rows={8}
            label="Transaction Note"
            variant="outlined"
            InputProps={{ style: { borderRadius: 8, height: 200 } }}
            name="shortDescription"
            value={formData.shortDescription}
            onChange={handleInputChange}
          />
          {/* <p className="add-member-p mt-1">
            Transaction note (Maximum limit 300 characters)
          </p> */}
        </div>

        <div className="col-12">
          <Divider />
        </div>
        {/* Submit Button */}
        <div className="col-12 d-flex justify-content-end">
          {formData.school?.due_commission === 0 ? (
            <Tooltip
              title={
                "You can't pay this user because this user has 0 Due commission"
              }
            >
              <button className="theme-button-contained " disabled>
                {loading ? <CircularProgress size={15} /> : "Submit"}
              </button>
            </Tooltip>
          ) : (
            <button className="theme-button-contained " onClick={handleSubmit}>
              {loading ? <CircularProgress size={15} /> : "Submit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddSchoolTransaction;
