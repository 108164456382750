import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
import schooimg from "../../../assets/images/cardpic.png";
import CustomMUITable from "../../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../../components/generalComponents/CustomConfirmation";
import AddOrUpdateManageRecordingDrawer from "../../../components/manageRecordingsComponents/AddOrUpdateManageRecordingDrawer";
import CustomDrawer from "../../../components/generalComponents/CustomDrawer";
import { ListManageRecording } from "../../../DAL/recording";
import { s3baseUri } from "../../../config/config";
import PageLoading from "../../../components/generalComponents/PageLoading";

const ManageRecording = () => {
  const INITIAL_VALUES = {
    deleteRecordingId: null,
    loading: true,
    deleteDialogOpen: false,
    drawerOpen: false,
    selectedRecording: null,
    recordings: [],
  };

  const [recordingListState, setRecordingListState] = useState(INITIAL_VALUES);
  const { enqueueSnackbar } = useSnackbar();
  const { program_recording_category_id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchRecordings = async () => {
    setRecordingListState((prevState) => ({
      ...prevState,
      loading: true, // Set loading to true before fetching
    }));

    const response = await ListManageRecording(page, rowsPerPage, {
      search: searchText,
      program_recording_category_id: program_recording_category_id,
    });

    if (response.code === 200) {
      setTotalCount(response.total_recordings);
      setTotalPages(Math.ceil(response.total_recordings / rowsPerPage) - 1);
      setRecordingListState((prevState) => ({
        ...prevState,
        recordings: response.recordings.map((recording, index) => ({
          ...recording,
          id: recording._id,
          image: { src: s3baseUri + recording.image.thumbnail_1 },
          resources: "N/A",
          programme: "N/A",
        })),
        loading: false, // Set loading to false after data is fetched
      }));
    } else {
      enqueueSnackbar("Failed to fetch recordings", { variant: "error" });
      setRecordingListState((prevState) => ({
        ...prevState,
        loading: false, // Set loading to false on error
      }));
    }
    setRecordingListState((prevState) => ({
      ...prevState,
      loading: false, // Set loading to false on error
    }));
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchRecordings();
  };

  useEffect(() => {
    fetchRecordings();
  }, [enqueueSnackbar, program_recording_category_id]);

  const handleOpenDeleteDialog = (recordingId) => {
    setRecordingListState((prevState) => ({
      ...prevState,
      deleteRecordingId: recordingId,
      deleteDialogOpen: true,
    }));
  };

  const handleOpenEditDrawer = (recording) => {
    setRecordingListState((prevState) => ({
      ...prevState,
      selectedRecording: recording,
      drawerOpen: true,
    }));
  };

  const handleOpenAddDrawer = () => {
    setRecordingListState((prevState) => ({
      ...prevState,
      selectedRecording: null,
      drawerOpen: true,
    }));
  };

  const handleCloseDrawer = () => {
    setRecordingListState((prevState) => ({
      ...prevState,
      drawerOpen: false,
    }));
  };

  const handleDeleteRecording = () => {
    const updatedRecordings = recordingListState.recordings.filter(
      (recording) => recording.id !== recordingListState.deleteRecordingId
    );
    setRecordingListState((prevState) => ({
      ...prevState,
      recordings: updatedRecordings,
      deleteDialogOpen: false,
    }));
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick(row) {
        handleOpenEditDrawer(row);
      },
    },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleOpenDeleteDialog,
    // },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title " },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  return (
    <>
      <div className="container">
        <Link to={"/programmes"} className="custom-link">
          Programme
        </Link>
        {"  /  "}
        <Link to={-1} className="custom-link">
          Manage Recording Category
        </Link>
        {" / "}
        <Link className="custom-link">Manage Recording</Link>
      </div>

      <div className="container-fluid d-flex mt-2 justify-content-between">
        <h4>Manage Recording</h4>
        <div className="col-6">
          <div className="col-lg-12 col-md-6 d-flex justify-content-end align-items-center">
            {/* <button
              className="theme-button-contained"
              onClick={handleOpenAddDrawer}
            >
              Add Recording
            </button> */}
          </div>
        </div>
      </div>

      {recordingListState.loading ? (
        <div className="loader-container">
          <PageLoading />
        </div>
      ) : (
        <div className="col-12 mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={recordingListState.recordings}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        </div>
      )}

      <CustomConfirmation
        open={recordingListState.deleteDialogOpen}
        setOpen={(isOpen) =>
          setRecordingListState((prevState) => ({
            ...prevState,
            deleteDialogOpen: isOpen,
          }))
        }
        title={"Are you sure you want to delete this recording?"}
        handleAgree={handleDeleteRecording}
      />
      <CustomDrawer
        isOpenDrawer={recordingListState.drawerOpen}
        onOpenDrawer={handleCloseDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${
          recordingListState.selectedRecording ? "Update" : "Add"
        } Recording`}
        componentToPassDown={
          <AddOrUpdateManageRecordingDrawer
            onCloseDrawer={handleCloseDrawer}
            selectedObject={recordingListState.selectedRecording}
            programId={program_recording_category_id}
            fetchRecordings={fetchRecordings}
          />
        }
      />
    </>
  );
};

export default ManageRecording;
