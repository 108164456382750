import { invokeApi } from "../utils/invokeApi";

export const login = async (data) => {
  const requestObj = {
    path: `api/admin/admin_login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const GetDeatailAdmin = async () => {
  const requestObj = {
    path: `api/admin_portal_settings/get_admin_portal_settings`,
    method: "GET",
    headers: {},
  };
  return invokeApi(requestObj);
};
