import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import logo from "../../assets/images/appheaderlogo.png";
import sidepic from "../../assets/images/7ead8c3a-23eb-4b24-8ab2-a21d3017d84d.png";
import { validateEmail } from "../../utils/constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import CustomTextField from "../../components/customTextField/CustomTextField";
import { login, GetDetailAdmin, GetDeatailAdmin } from "../../DAL/auth";
import { s3baseUri } from "../../config/config";
import { useAdminContext } from "../../hooks/AdminContext";

const LoginPage = () => {
  const INITIAL_VALUES = {
    email: "",
    password: "",
    showPassword: false,
    loading: false,
  };
  const { setUserInfo } = useAdminContext();
  const [loginStates, setLoginStates] = useState(INITIAL_VALUES);
  const [adminSettings, setAdminSettings] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const fetchAdminSettings = async () => {
    const response = await GetDeatailAdmin();
    if (response.code === 200) {
      setAdminSettings(response.admin_portal_settings);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminSettings();
  }, [enqueueSnackbar]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(loginStates.email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
      return;
    }
    setLoginStates((old) => ({ ...old, loading: true }));
    let login_data = {
      email: loginStates.email,
      password: loginStates.password,
    };
    const response = await login(login_data);
    if (response.code === 200) {
      localStorage.setItem("token", response.auth_user_info.auth_token);
      localStorage.setItem("user", JSON.stringify(response.auth_user_info));
      setUserInfo(JSON.stringify(response.auth_user_info));
      enqueueSnackbar("Admin Login Successfully..", {
        variant: "success",
      });
      navigate("/dashboard");
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoginStates((old) => ({ ...old, loading: false }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginStates((old) => ({ ...old, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setLoginStates((old) => ({
      ...old,
      showPassword: !loginStates.showPassword,
    }));
  };

  return (
    <div className="container-fluid vh-100 d-flex flex-column overflow-hidden">
      <div className="row flex-grow-1 no-gutters h-100">
        <div className="col-md-4 d-flex align-items-center justify-content-center p-4">
          <div className="text-center w-100 admin-login-logo">
            <img
              style={{ width: "80px" }}
              src={s3baseUri + adminSettings.brand_logo?.thumbnail_1 || logo}
              alt="Logo"
            />
            <h4 className="mb-4">
              {adminSettings.admin_login_page_text ||
                "Hi, Login Here CORE Kids"}
            </h4>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={loginStates.email}
                onChange={(e) => handleChange(e)}
              />
              <CustomTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={loginStates.showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={loginStates.password}
                onChange={(e) => handleChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {loginStates.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="text-end mb-2">
                <Link className="custom-link" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                className="w-100 login-button"
                disabled={loginStates.loading}
              >
                {loginStates.loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-8 p-0 d-none d-md-flex">
          <div className="overflow-hidden w-100 h-100">
            <img
              className="object-fit-cover h-100 w-100"
              src={
                s3baseUri + adminSettings.login_page_image?.thumbnail_1 ||
                sidepic
              }
              alt="Login"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
