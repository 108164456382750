import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { updateOrder } from "../../DAL/recording";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";

const INITIAL_VALUES = {
  title: "",
  description: "",
  programme: [],
  status: true,
  image: null,
  imagePreview: null,
  dob: "",
  contact: "",
  email: "",
  zipcode: "",
  gender: true,
  recording: "",
  order: "",
  categories: "",
};

export default function AddOrUpdateManageRecordingDrawer(props) {
  const { selectedObject, onCloseDrawer, programId, fetchRecordings } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [orderError, setOrderError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "order") {
      if (value <= 0) {
        setOrderError("Order must be greater than 0");
      } else {
        setOrderError("");
      }
    }
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputs((old) => ({
        ...old,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.order <= 0) {
      setOrderError("Order must be greater than 0");
      return;
    }

    const updateData = {
      program_recording_category_id: programId,
      recording_id: selectedObject?._id,
      order: parseInt(inputs.order, 10),
    };
    setLoading(true);

    const response = await updateOrder(updateData);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      onCloseDrawer();
      fetchRecordings();
    } else {
      console.error(response.message);
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedObject) {
      setInputs({
        ...INITIAL_VALUES,
        ...selectedObject,
        status: Boolean(selectedObject.status),
      });
    } else {
      setInputs(INITIAL_VALUES);
    }
  }, [selectedObject]);

  return (
    <form onSubmit={handleSubmit} className="drawer-form">
      <TextField
        required
        type="number"
        label="Order"
        variant="outlined"
        value={inputs.order}
        name="order"
        onChange={handleChange}
        fullWidth
        error={Boolean(orderError)}
        helperText={orderError}
      />

      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}{" "}
        </button>
      </div>
    </form>
  );
}
