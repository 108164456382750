import { invokeApi } from "../utils/invokeApi";

export const membersListApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/member/list_member_by_admin?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addMembers = async (data) => {
  const requestObj = {
    path: `api/member/add_member_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editMembers = async (member, data) => {
  const requestObj = {
    path: `api/member/update_member_by_admin/${member._id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberDetail = async (member_user_id) => {
  const requestObj = {
    path: `api/member/detail_member_by_admin/${member_user_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteMemberApi = async (member_user_id) => {
  const requestObj = {
    path: `api/member/delete_member_by_admin/${member_user_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberssubscription = async (member_id) => {
  const requestObj = {
    path: `api/member/subscription_list_against_member/${member_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberSubbcriptionDelete = async (data) => {
  const requestObj = {
    path: `api/member/cancel_subscription`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
