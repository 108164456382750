import React, { useState, useEffect } from "react";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import PageLoading from "../../components/generalComponents/PageLoading";
import { enqueueSnackbar } from "notistack";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import FilterSubscription from "../../components/subscriptionList/FilterSubscription";
import { commissionList } from "../../DAL/Payments/Commissions/commissions";

const Commission = () => {
  const [state, setState] = useState({
    selectedRow: null,
    loading: true,
    drawerOpen: false,
    subscriptions: [],
    page: 0,
    rowsPerPage: 50,
    totalCount: 0,
    pageCount: 1,
    searchText: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChangeRowsPerPage = (event) => {
    setIsLoading(true); // Set loading to true before fetching data
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchData = async (clearFilter = false) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const postData = {
      search: clearFilter ? "" : state.searchText,
    };

    const response = await commissionList(
      state.page,
      state.rowsPerPage,
      postData
    );

    if (response.code === 200) {
      const subscriptions = response.schools.map((subscription) => ({
        ...subscription,
        schoolName: subscription.school_name,

        totalCommission: "£" + subscription?.total_commission,
        paidCommission: "£" + subscription?.paid_commission,
        dueCommission: "£" + subscription?.due_commission,
      }));
      setState((prevState) => ({
        ...prevState,
        subscriptions: subscriptions,
        totalCount: response.total_schools,
        pageCount: Math.ceil(response.total_schools / state.rowsPerPage) - 1,
        loading: false,
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchData();
  }, [state.page, state.rowsPerPage]);

  // const handleChangeRowsPerPage = (event) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     rowsPerPage: parseInt(event.target.value, 10),
  //     page: 0,
  //   }));
  // };

  // const handleChangePage = (event, newPage) => {
  //   setState((prevState) => ({ ...prevState, page: newPage }));
  // };

  const applyFilters = () => {
    fetchData();
    handleCloseDrawer();
  };

  const clearFilters = () => {
    setState((prevState) => ({
      ...prevState,
      searchText: "",
      page: 0,
    }));
    fetchData(true);
    handleCloseDrawer();
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, page: 0 }));
    fetchData();
  };

  const handleFilter = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };

  const handleCloseDrawer = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  };

  const handleSelectOther = (name, value) => {
    setState((values) => ({ ...values, [name]: value }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setState((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "schoolName", label: "School Name" },
    { id: "totalCommission", label: "Total Commission" },
    {
      id: "paidCommission",
      label: "Paid Commission",
    },
    { id: "dueCommission", label: "Due Commission" },
  ];

  if (state.loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Commissions</h4>
        </div>
        <div className="col-6 my-2 text-end">
          {/* <button className="theme-button-contained" onClick={handleFilter}>
            <FilterListIcon sx={{ mr: 1 }} />
            Filter
          </button> */}
        </div>

        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={state.subscriptions}
            pagePagination={true}
            custom_pagination={{
              total_count: state.totalCount,
              rows_per_page: state.rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: state.searchText,
              setSearchText: (text) =>
                setState((prevState) => ({ ...prevState, searchText: text })),
              handleSubmit: searchFunction,
            }}
            pageCount={state.pageCount}
            totalPages={Math.ceil(state.totalCount / state.rowsPerPage)}
            handleChangePages={(e, newPage) =>
              setState((prevState) => ({ ...prevState, page: newPage - 1 }))
            }
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={state.drawerOpen}
        onOpenDrawer={handleFilter}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={"Filter Subscription"}
        componentToPassDown={
          <FilterSubscription
            setDrawerOpen={handleCloseDrawer}
            fetchData={fetchData}
            setState={setState}
            applyFilters={applyFilters}
            handleSelectOther={handleSelectOther}
            clearFilters={clearFilters}
            state={state}
            handleOnChange={handleFilterChange}
          />
        }
      />
    </div>
  );
};

export default Commission;
