import React from "react";
import CustomPopoverSection from "../../../components/customMUITable/CustomPopoverSection";
import { Tooltip } from "@mui/material";
import { Visibility, GetApp } from "@mui/icons-material";
import { s3baseUri } from "../../../config/config";

export default function ResourceAction(props) {
  const { item, MENU_OPTIONS, setPdfDialogOpen, setActiveDoc } = props;
  const handleClick = (item) => {
    setPdfDialogOpen(true);
    setActiveDoc(s3baseUri + item.url);
  };

  const downloadPdf = (item) => {
    const url = s3baseUri + item.url;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.setAttribute("download", url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  return (
    <div className="d-flex">
      <CustomPopoverSection menu={MENU_OPTIONS} data={item} />
      <div className="download-pdf ps-3 pe-2">
        <a
          onClick={() => {
            downloadPdf(item);
          }}
        >
          <GetApp style={{ cursor: "pointer" }} />
        </a>
      </div>
      <div className="preview-resource px-2">
        <Tooltip title="Preview Resource" placement="top-start">
          <a href={s3baseUri + item.url} download target="_blank">
            <Visibility style={{ cursor: "pointer" }} />
          </a>
        </Tooltip>
        {/* <Tooltip title="Preview Resource" placement="top-start">
          <Visibility
            onClick={() => {
              handleClick(item);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip> */}
      </div>
    </div>
  );
}
