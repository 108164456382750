import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import { emailTemplatesListApi } from "../../DAL/emailTemplatesListApi";

const EmailTemplates = () => {
  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    emailTemplates: [],
  };

  const [emailTemplateState, setEmailTemplateState] = useState(INITIAL_VALUES);
  const [emailTemplatesList, setEmailTemplatesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingTemplateId, setDeletingTemplateId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "email_subject", label: "Email Subject" },
    { id: "link", label: "Preview", type: "link" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const fetchData = async () => {
    const postData = { search: searchText };
    const response = await emailTemplatesListApi(page, rowsPerPage, postData);

    if (response.code === 200) {
      const emailTemplates = response.email_template.map((template) => ({
        ...template,
        date_created: moment(template.date_created).format("DD/MM/YYYY hh:mm"),
        status: template.status ? true : false,
        link: {
          to: `/email-templates/preview-email-template/${template.email_template_slug}`,
          target: "_self",
          show_text: "preview",
        },
      }));

      setTotalCount(response.email_template.length);
      setTotalPages(
        Math.ceil(response.email_template.length / rowsPerPage) - 1
      );
      setEmailTemplatesList(emailTemplates);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleDeleteTemplate = async (templateId) => {
    setDeleteLoading(true);

    setDeleteLoading(false);
  };

  const handleAddTemplate = () => {
    navigate("/email-templates/add-email-template");
  };

  const handleEdit = (row) => {
    navigate(
      `/email-templates/edit-email-template/${row.email_template_slug}`,
      {
        state: { template: row },
      }
    );
  };

  const handleDelete = (row) => {
    setDeletingTemplateId(row._id);
    setDeleteDialogOpen(true);
  };

  const handlePreview = (row) => {
    navigate(
      `/email-templates/preview-email-template/${row.email_template_slug}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: (row) => handleEdit(row),
    },
  ];

  if (isLoading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 email-templates_heading">
          <h4>Email Templates</h4>
        </div>
        <div className="col-6 my-2 text-end"></div>
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={emailTemplatesList}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            handlePreview={handlePreview}
          />
        </div>
      </div>

      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this email template?"}
        handleAgree={() => handleDeleteTemplate(deletingTemplateId)}
      />
    </div>
  );
};

export default EmailTemplates;
