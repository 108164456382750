import { invokeApi } from "../../../utils/invokeApi";

export const schoolTransactionsList = async (page, limmit, data) => {
  const requestObj = {
    path: `api/school_transaction/list_transaction?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSchoolTransactions = async (data) => {
  const requestObj = {
    path: `api/school_transaction/add_transaction`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
