import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function CustomMUIInput(props) {
  const { is_password, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getInputProps = () => {
    if (!is_password) return rest.InputProps;
    return {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={togglePasswordVisibility}
            edge="end"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    };
  };

  return (
    <TextField
      {...rest}
      type={is_password ? (showPassword ? "text" : "password") : rest.type}
      InputProps={getInputProps()}
    />
  );
}
