import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import {
  memberssubscription,
  memberSubbcriptionDelete,
} from "../../DAL/members";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import data from "@iconify/icons-eva/search-fill";
import Label from "../../components/Label";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Memberscsubscription = () => {
  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    members: [],
  };

  const [memberState, setMemberState] = useState(INITIAL_VALUES);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingMemberId, setDeletingMemberId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { subscription_id, member_user_id } = useParams();
  const location = useLocation();
  const program = location.state?.program;

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "concatenated_info", label: "Product" },
    { id: "school", label: "School" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let className = "pending-ticket";
        return (
          <div style={{ display: "flex" }}>
            <Label
              variant="ghost"
              color={
                row.status.includes("Cancels") ||
                row.status.includes("Cancelled")
                  ? "warning"
                  : row.status.includes("Trial")
                  ? "info"
                  : row.status === "Expired"
                  ? "error"
                  : "success"
              }>
              {row.status.includes("Cancels") && (
                <AccessTimeIcon
                  style={{ marginRight: "5px", width: "18px", height: "18px" }}
                />
              )}

              {row.status}
            </Label>
          </div>
        );
      },
    },
    { id: "next_invoice_date", label: "Created At" },
  ];

  const fetchData = async () => {
    const response = await memberssubscription(subscription_id);

    if (response.code === 200) {
      const subscriptions = response.subscriptions.map((subscription) => {
        let statusTab = "Active";
        if (subscription?.is_cancelled == true) {
          statusTab = "Cancelled";
        } else if (subscription?.cancelation_requested == true) {
          let date = moment(subscription.subscription_cancelled_date).format(
            "DD MMMM YYYY"
          );
          statusTab = "Cancels at: " + date;
        } else if (subscription?.is_on_trial == true) {
          let date = moment(subscription?.trial_ending_date).format(
            "DD MMMM YYYY"
          );
          statusTab = "Trial Ends at: " + date;
        }
        return {
          ...subscription,
          concatenated_info: `${subscription.sale_page.sale_page_title || ""} ${
            (subscription.plan_info?.plan_title && "|") || ""
          } ${subscription.plan_info?.plan_title || ""}`,
          school: subscription?.school_name || "No School",
          status:
            subscription?.is_cancelled == true
              ? statusTab
              : subscription?.cancelation_requested == true
              ? statusTab
              : subscription?.is_on_trial === true
              ? statusTab
              : subscription.status === "active"
              ? "Active"
              : "Expired",
          MENU_OPTIONS: handleMenu(subscription),
          next_invoice_date: subscription.createdAt
            ? moment(subscription.createdAt).format("DD/MM/YYYY")
            : "N/A",
        };
      });

      setSubscriptionsList(subscriptions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleDeleteMember = async (memberId) => {
    setDeleteLoading(true);

    const postData = {
      subscription_id: memberId.stripe_subscription_id,
      user_id: program.user_id._id,
    };

    const response = await memberSubbcriptionDelete(postData);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setDeleteDialogOpen(false);
      fetchData();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setDeleteDialogOpen(false);
    }
    setDeleteLoading(false);
  };

  const handleAddMember = () => {
    navigate("/members/add-members");
  };

  const handleEdit = (row) => {
    navigate(`/members/edit-members`, {
      state: { member: row },
    });
  };

  const handleDelete = (row) => {
    setDeletingMemberId(row);
    setDeleteDialogOpen(true);
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [];
    if (row.cancelation_requested === false && row.is_cancelled === false) {
      MENU_OPTIONS.push({
        label: "Cancel",
        icon: "line-md:cancel",
        handleClick: (row, index) => handleDelete(row),
      });
    }

    return MENU_OPTIONS;
  };

  if (isLoading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12  d-flex mx-2">
          <Link to={-1} className="mx-2">
            <ArrowBackIcon />
          </Link>
          <div className="col-md-66 d-flex justify-content-between">
            <h4>Member Subscription</h4>
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}>
              ({program.title})
            </h5>
          </div>
        </div>

        <div className="col-6 my-2 text-end"></div>
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={subscriptionsList}
            pagePagination={true}
          />
        </div>
      </div>

      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to cancel this subscription?"}
        handleAgree={() => handleDeleteMember(deletingMemberId)}
      />
    </div>
  );
};

export default Memberscsubscription;
