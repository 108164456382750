import { invokeApi } from "../../utils/invokeApi";

export const uploadImageOns3 = async (data) => {
  const requestObj = {
    path: `api/admin/update_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
