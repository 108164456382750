import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  addCampaignSettingApi,
  campaignSettingApi,
} from "../../DAL/AllSettings/AllSettings";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const CampaignSetting = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = React.useState({
    key: "Active Campaign",
    url: "",
    id: "",
    token: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await campaignSettingApi();
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ["url"]: result?.compaign_setting?.active_campaign_url,
        ["token"]: result?.compaign_setting?.active_campaign_token,
        ["id"]: result?.compaign_setting?.active_campaign_signup_list_id,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      active_campaign_url: inputs.url,
      active_campaign_token: inputs.token,
      active_campaign_signup_list_id: inputs.id,
    };

    setIsLoading(true);
    const result = await addCampaignSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <h4>Campaign Setting</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="What do You want to use for Marketing?"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="key"
              disabled
              value={inputs.key}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign URL"
              variant="outlined"
              fullWidth
              required
              type="url"
              name="url"
              value={inputs.url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign Api Token"
              variant="outlined"
              fullWidth
              required
              name="token"
              value={inputs.token}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign Signup List ID"
              variant="outlined"
              fullWidth
              name="id"
              value={inputs.id}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="theme-button-contained">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CampaignSetting;
