import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const RecentLessonsCard = ({ transactions }) => {
  const formattedDate = moment(transactions.date).format("DD-MM-YYYY hh:mm A");

  return (
    <div className="card h-100 recent-lesson-card">
      <div className="card-body">
        <h5 className="mb-3 dashboard-h5">Recent Transactions</h5>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Member</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="m-8">
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    {transaction?.member_info?.name}
                    {transaction?.member_info?.email && (
                      <>({transaction.member_info.email})</>
                    )}{" "}
                  </td>

                  <td>{formattedDate}</td>

                  <td>{" £ " + transaction?.total_amount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Define PropTypes for the component
RecentLessonsCard.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RecentLessonsCard;
