import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { addFamilyMembers, editFamilyMembers } from "../../DAL/familyMembers";
import { useSnackbar } from "notistack";
import { s3baseUri } from "../../config/config";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const INITIAL_VALUES = {
  name: "",
  biography: "",
  status: true,
  profile_image: "",
  imagePreview: "",
  date_of_birth: dayjs(new Date()),
  date_of_birth_iso: "",
  contact_number: "",
  email: "",
  zipcode: "",
  gender: "male",
  country: "",
  city: "",
  relationship: "",
  is_member_paid: false,
};

export default function AddOrUpdateFamilyDrawer(props) {
  const {
    selectedObject,
    onCloseDrawer,
    member_user_id,
    family_member_user_id,
    familyMembers,
  } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleChangeData = (value) => {
    setInputs((old) => ({ ...old, ["date_of_birth"]: value.$d }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputs((old) => ({
        ...old,
        profile_image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };
  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split("T")[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.profile_image) {
      enqueueSnackbar(" image is  required.", { variant: "error" });
      return;
    }

    const formDataObj = new FormData();
    formDataObj.append("name", inputs.name);
    formDataObj.append("biography", inputs.biography);
    formDataObj.append("status", inputs.status);
    formDataObj.append("date_of_birth", inputs.date_of_birth);
    formDataObj.append("contact_number", inputs.contact_number);
    formDataObj.append("zipcode", inputs.zipcode);
    formDataObj.append("gender", inputs.gender);
    formDataObj.append("country", inputs.country);
    formDataObj.append("city", inputs.city);
    formDataObj.append("relationship", inputs.relationship);
    if (inputs.profile_image instanceof File) {
      formDataObj.append("profile_image", inputs.profile_image);
    }
    formDataObj.append("email", inputs.email);

    if (!selectedObject) {
      formDataObj.append("member_user_id", member_user_id);
    }

    setLoading(true);

    let response;
    if (family_member_user_id) {
      response = await editFamilyMembers(family_member_user_id, formDataObj);
    } else {
      response = await addFamilyMembers(formDataObj);
    }
    if (response.code === 200) {
      enqueueSnackbar("Member successfully processed.", {
        variant: "success",
      });
      let body = {
        member_user_id: member_user_id,
      };
      familyMembers(body);
      onCloseDrawer();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedObject) {
      setInputs((old) => ({
        ...INITIAL_VALUES,
        ...selectedObject,
        status: Boolean(selectedObject.status),
        email: selectedObject.user_id?.email,
        date_of_birth: moment(selectedObject.date_of_birth_iso).format(
          "MM DD YYYY"
        ),
        imagePreview:
          s3baseUri + selectedObject.profile_image.thumbnail_1 || "",
      }));
    } else {
      setInputs(INITIAL_VALUES);
    }
  }, [selectedObject]);

  return (
    <form className="drawer-form" onSubmit={handleSubmit}>
      <TextField
        required
        label="Name"
        variant="outlined"
        value={inputs.name}
        name="name"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        required
        label="Short Description"
        variant="outlined"
        multiline
        rows={4}
        value={inputs.biography}
        name="biography"
        onChange={handleChange}
        fullWidth
      />
      <p className="add-member-p mt-0 mb-0">
        Family member short description (Maximum limit 300 characters)
      </p>
      <TextField
        required
        label="Country"
        variant="outlined"
        value={inputs.country}
        name="country"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        required
        label="City"
        variant="outlined"
        value={inputs.city}
        name="city"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        required
        label="Relationship"
        variant="outlined"
        value={inputs.relationship}
        name="relationship"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Email"
        variant="outlined"
        value={inputs.email}
        name="email"
        onChange={handleChange}
        fullWidth
        disabled={Boolean(selectedObject)} // Disable the email field if in edit mode
      />

      <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
        <DemoContainer components={["DatePicker"]} fullWidth>
          <DatePicker
            name="date_of_birth"
            label="Date of Birth"
            required
            fullWidth
            value={dayjs(inputs.date_of_birth)}
            onChange={handleChangeData}
          />
        </DemoContainer>
      </LocalizationProvider>
      <TextField
        required
        type="number"
        label="Contact Number"
        variant="outlined"
        value={inputs.contact_number}
        name="contact_number"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        required
        type="number"
        label="Zip Code"
        variant="outlined"
        value={inputs.zipcode}
        name="zipcode"
        onChange={handleChange}
        fullWidth
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          required
          labelId="status-label"
          label="Status"
          value={inputs.status}
          name="status"
          onChange={handleSelectChange}>
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="gender-label">Gender</InputLabel>
        <Select
          required
          labelId="gender-label"
          label="Gender"
          value={inputs.gender}
          name="gender"
          onChange={handleSelectChange}>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div>
            {inputs.imagePreview && (
              <img
                src={inputs.imagePreview}
                alt="Preview"
                style={{ width: "100px", height: "auto" }}
              />
            )}
          </div>
          <p className="mt-0 mb-0">Upload image*</p>
        </div>

        <label htmlFor="image-selection" className="theme-button-contained">
          <FileUpload />
          Upload
          <input
            type="file"
            id="image-selection"
            hidden
            accept="image/*"
            onChange={handleImageChange}
          />
        </label>
      </div>
      <div className="mt-1 image-family-member">
        <p>Recommended image size (300x300) and file types (jpg, png)</p>
      </div>
      {/* <div className="mt-1 image-family-member">
        <p>Recommended image size (300x300) and file types (jpg, png)</p>
      </div> */}
      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          disabled={loading}>
          {loading ? (
            <CircularProgress size={15} color="inherit" />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
