import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FileUpload, Clear } from "@mui/icons-material";
import { ActiveProgrammeList } from "../../DAL/programmes";
import { editResourceApi, ResourceDetailApi } from "../../DAL/Resource";
import PageLoading from "../generalComponents/PageLoading";

const EditResource = () => {
  const INITIAL_VALUES = {
    title: "",
    videoURL: "",
    status: "Active",
    documentType: "PDF",
    loading: false,
    selectedPrograms: [],
    settings: {},
    shortDescription: "",
  };

  const [addResourcesState, setAddResourcesState] = useState(INITIAL_VALUES);
  const [settings, setSettings] = useState({});
  const [programs, setPrograms] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loadingResource, setLoadingResource] = useState(false);
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();
  const { resource_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const resource = location.state?.program;

  useEffect(() => {
    setAddResourcesState({
      title: resource.title,
      videoURL: resource.videoURL || "",
      documentType: resource.documentType,
      status: resource.status ? "Active" : "Inactive",
      mainImageFile: "",
      // imagePreview: s3baseUri + program.main_image.thumbnail_1,
      selectedPrograms: resource.program_info?.map((prog) => prog._id) || [],
      shortDescription: resource.description || "",
    });
    setFileName(resource.url);
    const fetchPrograms = async () => {
      const result = await ActiveProgrammeList();
      if (result.code === 200) {
        setPrograms(result.programs);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };

    fetchPrograms();
  }, []);

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    if (name === "selectedPrograms") {
      setAddResourcesState((old) => ({
        ...old,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setAddResourcesState((old) => ({ ...old, [name]: value }));
    }
  };

  const handleAddResource = async () => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append("title", addResourcesState.title);
    formData.append("description", addResourcesState.shortDescription);
    formData.append(
      "document_file_type",
      settings.fileUpload
        ? "." + addResourcesState.documentType?.toLowerCase()
        : addResourcesState.documentType
    );
    formData.append(
      "status",
      addResourcesState.status === "Active" ? true : false
    );
    formData.append(
      "programs",
      JSON.stringify(addResourcesState.selectedPrograms)
    );
    if (settings.fileUpload) {
      formData.append("file", settings.fileUpload);
    }

    const result = await editResourceApi(resource.resource_slug, formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/manage-programme-resources");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setSubmitting(false); // Set submitting to false after the form submission is completed
  };

  const renderImageGuide = () => (
    <div className="resource-image-size">
      <p>(.pdf, .doc, .docx, .xls, .xlsx files are supported only)</p>
    </div>
  );

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (name === "fileUpload") {
      const file = files[0];
      const fileType = file.name.split(".").pop().toUpperCase();
      setAddResourcesState((old) => ({
        ...old,
        documentType: fileType,
      }));
      setSettings({ ...settings, fileUpload: file });
      setFileName(file.name);
    }
  };

  const truncateFileName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  const handleRemoveFile = () => {
    setSettings({ ...settings, fileUpload: null });
    setFileName("");
  };

  if (loadingResource) return <PageLoading />;
  return (
    <div className="containerfluid">
      <div className="col-12 container">
        <Link className="custom-link" to={"/manage-programme-resources"}>
          Resources
        </Link>{" "}
        {" / "}Edit Resource
      </div>
      <h4 className="mb-3 mt-3 container">Edit Resource</h4>
      <div className="container admin-default-settings-div">
        <div className="row mb-3">
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              name="title"
              label="Title"
              variant="outlined"
              value={addResourcesState.title}
              onChange={handleChangeInputs}
              InputProps={{
                style: {
                  height: 56,
                  borderRadius: 10,
                },
              }}
            />
          </div>
          <div className="col-md-6">
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                required
                name="status"
                value={addResourcesState.status}
                onChange={handleChangeInputs}
                label="Status"
                variant="outlined"
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <FormControl fullWidth required>
              <InputLabel id="select-program-label">
                Select a Program
              </InputLabel>
              <Select
                labelId="select-program-label"
                multiple
                value={addResourcesState.selectedPrograms}
                onChange={handleChangeInputs}
                label="Select a Program"
                name="selectedPrograms"
                variant="outlined"
                sx={{ borderRadius: 2 }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // This limits the height of the dropdown list
                      width: 50,
                    },
                  },
                }}
              >
                {programs.map((program) => (
                  <MenuItem key={program._id} value={program._id}>
                    {program.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <TextField
              fullWidth
              required
              label="Short Description"
              variant="outlined"
              multiline
              rows={4}
              name="shortDescription"
              value={addResourcesState.shortDescription}
              onChange={handleChangeInputs}
              InputProps={{
                style: {
                  height: 150,
                  borderRadius: 10,
                },
              }}
            />
            <p className="add-member-p mt-1">
              Programme short description (Maximum limit 300 characters)
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <p className="col-lg-12 m-0">Upload file*</p>
                <p>{renderImageGuide()}</p>
              </div>
            </div>
          </div>
          <div className="col-4">
            {fileName && (
              <div className="d-flex justify-content-center align-items-center flex-row">
                <p
                  style={{
                    backgroundColor: "#FFE7D9	",
                    padding: "7px",
                    borderRadius: "7px",
                  }}
                >
                  {" "}
                  {truncateFileName(fileName)}
                  <IconButton onClick={handleRemoveFile}>
                    <Clear
                      style={{
                        backgroundColor: "grey	",
                        color: "white",
                        borderRadius: "20px",
                      }}
                    />
                  </IconButton>
                </p>
              </div>
            )}
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-end  text-end">
              <label className="btn theme-button-contained">
                <FileUpload />
                Upload
                <input
                  name="fileUpload"
                  type="file"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 d-flex justify-content-end text-end">
            <button
              className="theme-button-contained"
              onClick={handleAddResource}
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                "Update "
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditResource;
