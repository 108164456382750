import { invokeApi } from "../utils/invokeApi";

export const adminsettings = async (data) => {
  const requestObj = {
    path: `api/admin_portal_settings/add_admin_portal_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getAdminSettings = async () => {
  const requestObj = {
    path: `api/admin_portal_settings/get_admin_portal_settings_with_auth`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
