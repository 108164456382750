import React from "react";
import moment from "moment";

export default function RecentMembersCard({ members }) {
  const recentMembers = members
    ?.sort((a, b) => new Date(b.date_joined) - new Date(a.date_joined))
    .slice(0, 5);

  return (
    <div className="card h-100 recent-members-card">
      <div className="card-body">
        <h5 className="mb-3 dashboard-h5">Recent Members</h5>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Date Joined</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {recentMembers.length > 0 ? (
                recentMembers.map((member, index) => (
                  <tr key={index}>
                    <td>{member.name}</td>
                    <td>{member.email}</td>
                    <td>
                      {moment(member.date_joined).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      <span
                        className={`badge bg-${
                          member.status ? "success" : "danger"
                        }`}
                        style={{
                          borderRadius: "25px",
                          fontWeight: "normal",
                          paddingLeft: "18px",
                          paddingRight: "18px",
                          whiteSpace: "nowrap",
                          fontSize: "13px",
                        }}
                      >
                        {member.status ? "Active" : "Inactive"}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No recent members
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
