import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import {
  deleteWebPageModule,
  webPageModuleListing,
} from "../../DAL/Modules/Modules";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",

    type: "action",
  },
  {
    id: "number",
    label: "#",
    type: "number",
  },

  {
    id: "module_title",
    label: "title",
  },
  // { id: "order", label: "Order" },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function WebPagesModules() {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [moduleListing, setModuleListing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const is_website_pages = window.location.pathname.includes("website-pages");

  const handleNavigate = (value) => {
    if (is_website_pages) {
      navigate(
        `/website-pages/${params?.page_id}/${state?.module_configuration_slug}/add-module`,
        {
          state: {
            data: value,
            formType: "ADD",
          },
        }
      );
    } else {
      navigate(
        `/template-pages/${params?.page_id}/${state?.module_configuration_slug}/add-module`,
        {
          state: {
            data: value,
            formType: "ADD",
          },
        }
      );
    }
  };

  const handleEdit = (value) => {
    if (is_website_pages) {
      navigate(
        `/website-pages/${params?.page_id}/${state?.module_configuration_slug}/edit-module/${value?._id}`,
        {
          state: {
            data: value,
            formType: "EDIT",
          },
        }
      );
    } else {
      navigate(
        `/template-pages/${params?.page_id}/${state?.module_configuration_slug}/edit-module/${value?._id}`,
        {
          state: {
            data: value,
            formType: "EDIT",
          },
        }
      );
    }
  };

  const getModuleListing = async () => {
    setIsLoading(true);
    const postdata = {
      page_id: params?.page_id,
      module_configuration: state?._id,
    };
    const result = await webPageModuleListing(postdata);
    if (result.code === 200) {
      setModuleListing(result.module);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteWebPageModule(selectedRow?._id);
    if (result.code === 200) {
      await getModuleListing();
      setIsLoading(false);
      enqueueSnackbar(
        `${state?.module_configuration_name} Deleted Suceessfully`,
        { variant: "success" }
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedRow(value);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getModuleListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-2">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-md-6 mb-2">
            <h4>{state?.module_configuration_name}</h4>
          </div>
          <div className="col-md-6 mb-2 text-end justify-content-end">
            <button
              onClick={() => {
                handleNavigate(state);
              }}
              className="theme-button-contained"
            >
              Add {state?.module_configuration_name}
            </button>
          </div>

          {/* )} */}
          <div className="col-12 mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={moduleListing}
              bubbleSize={"55%"}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>

        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
            <Button onClick={() => handleDelete()} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default WebPagesModules;
