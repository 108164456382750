import { Icon } from "@iconify/react";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Programmes",
    icon: getIcon("healthicons:child-program"),
    children: [
      {
        title: "Programme",
        route: "/programmes",
        icon: getIcon("fluent:eye-lines-20-filled"),
      },
      {
        title: "Resources",
        route: "/manage-programme-resources",
        icon: getIcon("grommet-icons:resources"),
      },
      {
        title: "Recording Category",
        route: "/recording-catogery",
        icon: getIcon("bxs:category-alt"),
      },
      {
        title: "Recording ",
        route: "/recording-list",
        icon: getIcon("carbon:recording-filled-alt"),
      },
    ],
  },
  { title: "Members", route: "/members", icon: getIcon("fe:users") },
  { title: "School", route: "schoolList", icon: getIcon("ic:round-school") },

  {
    title: "App Settings",
    route: "/app-general-setting",
    icon: getIcon("material-symbols-light:settings"),
  },
  {
    title: "General Setting",
    icon: getIcon("icon-park-solid:setting-web"),
    children: [
      {
        title: "Default Setting",
        route: "/admin-general-setting",
        icon: getIcon("lets-icons:setting-alt-fill"),
      },
      {
        title: "Email Setting",
        route: "/email-setting",
        icon: getIcon("ic:round-email"),
      },
      {
        title: "Stripe Setting",
        route: "/stripe-setting",
        icon: getIcon("formkit:stripe"),
      },
      // {
      //   title: "Campaign Setting",
      //   route: "/campaign-setting",
      //   icon: getIcon("lets-icons:setting-alt-fill"),
      // },
      {
        title: "Invoice Setting",
        route: "/invoice-setting",
        icon: getIcon("mdi:invoice"),
      },

      {
        title: "Email Templates",
        route: "/email-templates",
        icon: getIcon("mdi:email-open"),
      },
    ],
  },
  {
    title: "Payments",
    icon: getIcon("icon-park-solid:setting-web"),
    children: [
      {
        title: "Stripe Products",
        route: "/stripe-products",
        icon: getIcon("ph:stripe-logo-light"),
      },
      {
        title: "Transactions",
        route: "transactions",
        icon: getIcon("hugeicons:transaction"),
      },
      {
        title: "Subscription List",
        route: "/payment-subscription",
        icon: getIcon("fluent-mdl2:subscribe"),
      },
      {
        title: "School Transactions",
        route: "/school-transactions",
        icon: getIcon("hugeicons:transaction"),
      },
      {
        title: "Commissions",
        route: "/commissions",
        icon: getIcon("fluent-mdl2:nonprofit-logo-32"),
      },
    ],
  },
  {
    title: "Manage Website",
    path: "/list",
    icon: getIcon("icon-park-solid:setting-web"),
    children: [
      {
        title: "Pages",
        route: "/website-pages",
        icon: getIcon("fluent-mdl2:reopen-pages"),
      },
      {
        title: "Template Pages",
        route: "/template-pages",
        icon: getIcon("fluent-mdl2:reopen-pages"),
      },
      {
        title: "Menus",
        route: "/menus",
        icon: getIcon("bi:menu-button-fill"),
      },
    ],
  },
];
