import React, { useState } from "react";
import CustomMUIInput from "../generalComponents/CustomMUIInput";
import { useSnackbar } from "notistack";
import { changePasswordApi } from "../../DAL/changeUserPassword";
import CircularProgress from "@mui/material/CircularProgress";

const EMPTY_VALUES = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

export default function ChangePassword({ menuClose }) {
  const [inputs, setInputs] = useState(EMPTY_VALUES);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    if (!inputs.old_password) {
      error = "Old password is required";
    } else if (!inputs.new_password) {
      error = "New password is required";
    } else if (inputs.new_password !== inputs.confirm_password) {
      error = "Passwords do not match";
    }

    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    setLoading(true);

    const response = await changePasswordApi({
      old_password: inputs.old_password,
      new_password: inputs.new_password,
      confirm_password: inputs.confirm_password,
    });

    if (response.code === 200) {
      enqueueSnackbar("Password changed successfully", {
        variant: "success",
      });
      setInputs(EMPTY_VALUES); // Clear the input fields
      if (menuClose) menuClose(); // Close the menu if menuClose function is provided
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CustomMUIInput
        required
        label="Old Password"
        type="password"
        variant="outlined"
        value={inputs.old_password}
        name="old_password"
        onChange={handleChange}
        fullWidth
        size="small"
        autoComplete="new-password"
        is_password={true}
        className={"mb-3"}
      />
      <CustomMUIInput
        required
        label="New Password"
        type="password"
        variant="outlined"
        value={inputs.new_password}
        name="new_password"
        onChange={handleChange}
        fullWidth
        size="small"
        autoComplete="new-password"
        is_password={true}
        className={"mb-3"}
      />
      <CustomMUIInput
        required
        label="Repeat New Password"
        type="password"
        variant="outlined"
        value={inputs.confirm_password}
        name="confirm_password"
        onChange={handleChange}
        fullWidth
        size="small"
        autoComplete="new-password"
        is_password={true}
        className={"mb-3"}
      />
      <div className="text-end">
        <button
          type="submit"
          className="theme-button-contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Save"}
        </button>
      </div>
    </form>
  );
}
