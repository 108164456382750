import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ActiveProgrammeList } from "../../DAL/programmes";
import {
  RecordingCategoryAddApi,
  updateRecordingCategoryApi,
} from "../../DAL/recordingCategories";
import { s3baseUri } from "../../config/config";

const INITIAL_VALUES = {
  title: "",
  description: "",
  programme: [],
  status: true,
  image: null,
  imagePreview: null,
};

export default function AddOrUpdateCategory(props) {
  const { selectedObject, onCloseDrawer, refreshCategories } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [programs, setPrograms] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "programme") {
      setInputs((old) => ({
        ...old,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setInputs((old) => ({ ...old, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputs((old) => ({
        ...old,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!selectedObject && !inputs.image) {
      enqueueSnackbar("Image is required", { variant: "error" });
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.description);
    formData.append("programs", JSON.stringify(inputs.programme));
    formData.append("status", inputs.status);
    if (inputs.image) {
      formData.append("image", inputs.image);
    }

    if (selectedObject) {
      await updateRecordingCategoryApi(
        selectedObject.program_recording_category_slug,
        formData
      );
      enqueueSnackbar("Category updated successfully", {
        variant: "success",
      });
    } else {
      await RecordingCategoryAddApi(formData);
      enqueueSnackbar("Category added successfully", { variant: "success" });
    }
    onCloseDrawer();
    refreshCategories();
    setSubmitting(false);
  };

  const fetchPrograms = async () => {
    const result = await ActiveProgrammeList();
    if (result.code === 200) {
      setPrograms(result.programs);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchPrograms();
    if (selectedObject) {
      const category = selectedObject;
      setInputs({
        title: category.title,
        description: category.short_description,
        programme: category.program_info.map((program) => program._id),
        status: category.status,
        imagePreview: s3baseUri + category.image?.thumbnail_1 || null,
        image: null,
      });
    }
  }, [selectedObject]);

  return (
    <form onSubmit={handleSubmit} className="drawer-form">
      <TextField
        required
        label="Title"
        variant="outlined"
        value={inputs.title}
        name="title"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        required
        label="Short  Description"
        variant="outlined"
        multiline
        rows={4}
        value={inputs.description}
        name="description"
        onChange={handleChange}
        fullWidth
      />
      <p className="add-member-p mt-0 mb-0">
        Recording Category short description (Maximum limit 300 characters)
      </p>
      {/* <FormControl fullWidth required variant="outlined" sx={{ mt: 2 }}>
        <InputLabel id="programme-label">Programme</InputLabel>
        <Select
          labelId="programme-label"
          label="Programme"
          value={inputs.programme}
          name="programme"
          onChange={handleSelectChange}
          sx={{
            height: "auto",
            alignItems: "flex-start",
            ".MuiSelect-select": {
              display: "flex",
              flexWrap: "wrap",
              overflow: "auto",
            },
          }}
        >
          {programs.map((program) => (
            <MenuItem key={program._id} value={program._id}>
              {program.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <FormControl fullWidth required variant="outlined">
        <InputLabel id="categories-label">Programme</InputLabel>
        <Select
          required
          labelId="programme-label"
          label="Categories"
          multiple
          value={inputs.programme}
          name="programme"
          onChange={handleSelectChange}
          className="fixed-height-select"
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 200, // This limits the height of the dropdown list
                width: 50,
              },
            },
          }}
        >
          {programs.map((program) => (
            <MenuItem key={program._id} value={program._id}>
              {program.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          required
          labelId="status-label"
          label="Status"
          value={inputs.status}
          name="status"
          onChange={handleSelectChange}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div>
            {inputs.imagePreview && (
              <img
                src={inputs.imagePreview}
                alt="Preview"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            <p className="mb-0 mt-0">Upload image*</p>
          </div>
        </div>

        <label htmlFor="image-selection" className="theme-button-contained">
          <FileUpload />
          Upload
          <input
            type="file"
            id="image-selection"
            hidden
            onChange={handleImageChange}
          />
        </label>
      </div>
      <div className="mt-1 image-family-member">
        <p>Recommended image size (300x300) and file types (jpg, png)</p>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
