import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import { enqueueSnackbar } from "notistack";
import { planDelete, planList } from "../../DAL/Payments/Plans/Plans";
import { convertCurrencyToSign } from "../../utils/constant";

const PaymentPlans = () => {
  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    members: [],
  };
  const params = useParams();
  const { state } = useLocation();
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting_memberid, setDeletingMemberId] = useState("");
  const is_website_pages = window.location.pathname.includes("website-pages");
  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },

    { id: "number", label: "#", type: "number" },
    { id: "plan_title", label: "Title" },
    { id: "planImage", label: "Image", type: "thumbnail" },

    {
      id: "is_free",
      label: "Plan",
    },
    { id: "planPrice", label: "Plan Price" },
    { id: "initialAmount", label: "Initial Price" },
    { id: "installmentAmount", label: "Installment Price" },

    { id: "commission", label: "Commission" },
    // {
    //   id: "time_period_interval_param",
    //   label: "Time Period Interval",
    // },
    {
      id: "trial_period_days",
      label: "Trial Period Days",
    },

    { id: "plan_status", label: "Status", type: "row_status" },
  ];

  const navigate = useNavigate();
  const handleAddPlan = () => {
    if (params.page_id) {
      if (is_website_pages) {
        navigate(`/website-pages/${params.page_id}/payment-plans/add-plan`);
      } else {
        navigate(`/template-pages/${params.page_id}/payment-plans/add-plan`);
      }
    }
  };
  const handleEditPlan = (value) => {
    if (params.page_id) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_id}/payment-plans/edit-plan/${value._id}`,
          { state: { plan: value, page: state } }
        );
      } else {
        navigate(
          `/template-pages/${params.page_id}/payment-plans/edit-plan/${value._id}`,
          { state: { plan: value, page: state } }
        );
      }
    }
  };

  const handleDeletePlan = async (value) => {
    const response = await planDelete(value._id);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      getPlansList();
      setDeleteDialogOpen(false);
    } else {
      setDeleteDialogOpen(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEditPlan,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: (row) => {
        setDeletingMemberId(row);
        setDeleteDialogOpen(true);
      },
    },
  ];
  const getPlansList = async () => {
    setIsLoading(true);
    const response = await planList(params.page_id);
    if (response.code == 200) {
      const data = response.payment_plan.map((plan) => {
        let commissionData = "N/A";
        if (plan.commission_info) {
          if (plan.commission_info.commission_type == "fixed") {
            commissionData =
              convertCurrencyToSign(plan?.plan_currency) +
              plan.commission_info.school_commission_amount;
          } else {
            commissionData =
              plan.commission_info.school_commission_amount + "%";
          }
        }
        return {
          ...plan,
          commission: commissionData,
          trial_period_days: plan.trial_period_days
            ? plan.trial_period_days
            : 0,
          is_free:
            plan.is_plan_free == true
              ? "Free"
              : `Paid ( ${
                  plan.payment_access === "recursion"
                    ? "Recurring"
                    : plan.payment_access
                } ${
                  plan.payment_access == "onetime"
                    ? ""
                    : plan.product?.name
                    ? `| ${plan.product.name} `
                    : ""
                })`,
          productName: plan.product?.name ? plan.product.name : "N/A",
          planImage: {
            src: s3baseUri + plan.plan_image,
            alt: plan.plan_title,
          },

          time_period_interval_param: `${plan.time_period_interval}  ${
            plan.payment_access == "recursion" && plan.plan_type
              ? plan.plan_type
              : ""
          }`,
          installmentAmount: plan.installment_amount
            ? "£" + plan.installment_amount
            : "£0",
          initialAmount: plan.initial_amount ? "£" + plan.initial_amount : "£0",
          planPrice:
            plan.is_plan_free == true
              ? "£0"
              : plan.plan_price
              ? plan.plan_currency == "usd"
                ? "$" + plan.plan_price
                : plan.plan_currency == "gbp"
                ? "£" + plan.plan_price
                : plan.plan_currency == "eur"
                ? "€" + plan.plan_price
                : "" + plan.plan_price
              : "£0",
        };
      });
      setMembersList(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPlansList();
  }, []);

  if (isLoading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Payment Plans</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <button className="theme-button-contained" onClick={handleAddPlan}>
            Add Payment Plans
          </button>
        </div>
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
          />
        </div>
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleDeletePlan(deleting_memberid)}
            color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentPlans;
