import { invokeApi } from "../utils/invokeApi";

export const schoolList = async (page, limmit, data) => {
  const requestObj = {
    path: `api/school/list_school?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addSchool = async (data) => {
  const requestObj = {
    path: `api/school/add_school`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateSchool = async (school_id, data) => {
  const requestObj = {
    path: `api/school/update_school/${school_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteSchoolApi = async (school_id) => {
  const requestObj = {
    path: `api/school/delete_school/${school_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const activeSchools = async () => {
  const requestObj = {
    path: `api/school/list_active_school`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const schoolMemberList = async (page, limmit, data) => {
  const requestObj = {
    path: `api/member/list_member_by_school?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const schoolMemberTransactionList = async (data) => {
  const requestObj = {
    path: `api/member/list_transaction_by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
