import { invokeApi } from "../../../utils/invokeApi";

export const commissionList = async (page, limmit, data) => {
  const requestObj = {
    path: `api/school/get_commission_info?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
