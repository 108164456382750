import { invokeApi } from "../../utils/invokeApi";

export const stripeSettingApi = async () => {
  const requestObj = {
    path: `api/setting/get_stripe_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addStripeSettingApi = async (data) => {
  const requestObj = {
    path: `api/setting/add_stripe_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const campaignSettingApi = async () => {
  const requestObj = {
    path: `api/setting/get_compaign_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addCampaignSettingApi = async (data) => {
  const requestObj = {
    path: `api/setting/add_compaign_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EmailSettingApi = async () => {
  const requestObj = {
    path: `api/setting/get_email_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addEmailSettingApi = async (data) => {
  const requestObj = {
    path: `api/setting/add_email_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
