import { Dialog } from "@mui/material";
import React from "react";
import { Close as CloseIcon } from "@mui/icons-material";

export default function GeneralMuiModal(props) {
  const { open, setOpen, componentToPass, title, paperClass } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{
        className: `general-mui-modal ${paperClass || ""}`,
      }}
    >
      <div className="general-modal-close-icon" onClick={handleClose}>
        <CloseIcon />
      </div>
      <div className="general-modal-title mt-4">
        <h2>{title}</h2>
      </div>
      <div className="general-modal-content">{componentToPass}</div>
    </Dialog>
  );
}
