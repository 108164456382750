import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { saleDropdownApi } from "../../DAL/sale";
import MUIAutocomplete from "../MuiAutoComplate";
import { activeSchools } from "../../DAL/school";

export default function FilterTransaction({
  handelClear,
  state,
  handleOnChange,
  handleSelectOther,
  fetchData,
  clearFilters,
  onCloseDrawer,
  applyFilters,
  isSubmit,
  setIsSubmit,
}) {
  const [salePages, setSalePages] = useState([]);
  const [schoolsstatea, setSchools] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchSalePages = async () => {
      const response = await saleDropdownApi();
      if (response && response.sale_page) {
        setSalePages(response.sale_page);
      } else {
        enqueueSnackbar("Failed to fetch sale pages", { variant: "error" });
      }
    };

    fetchSalePages();
  }, [enqueueSnackbar]);

  const fetchSchools = async () => {
    const response = await activeSchools();
    if (response.code === 200) {
      setSchools(response.schools);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  const validateForm = () => {
    if (state.startDate && !state.endDate) {
      enqueueSnackbar("End Date is required.", { variant: "error" });
      return false;
    }
    if (state.endDate && !state.startDate) {
      enqueueSnackbar("Start Date is required.", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validateForm()) {
      applyFilters(e);
    }
  };

  return (
    <form className="drawer-form" onSubmit={handleFormSubmit}>
      <MUIAutocomplete
        inputLabel="School"
        selectedOption={state.school}
        setSelectedOption={(value) => {
          handleSelectOther("school", value);
        }}
        optionsList={schoolsstatea}
        name="school_name"
      />

      <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          label="Status"
          value={state.transaction_Status}
          name="transaction_Status"
          onChange={handleOnChange}
        >
          <MenuItem value="payment_failed">Failed</MenuItem>
          <MenuItem value="succeeded">Succeeded</MenuItem>
          <MenuItem value="requires_action">Require Action</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="subscription-mode-label">Subscription Mode</InputLabel>
        <Select
          labelId="subscription-mode-label"
          label="Subscription Mode"
          value={state.transaction_mode}
          name="transaction_mode"
          onChange={handleOnChange}
        >
          <MenuItem value="sandBox">Sandbox</MenuItem>
          <MenuItem value="live">Live</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>

      <Box>
        <b>Filter By Date</b>
        <div className="mb-3 mt-3">
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            name="startDate"
            value={state.startDate}
            onChange={handleOnChange}
            InputLabelProps={{ shrink: true }}
          />
        </div>

        <TextField
          fullWidth
          label="End Date"
          type="date"
          name="endDate"
          value={state.endDate}
          onChange={handleOnChange}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box display="flex" flexDirection="column" spacing={2}>
        <Button
          variant="outlined"
          onClick={clearFilters}
          fullWidth
          sx={{ mb: 1 }}
          startIcon={<ClearIcon />}
        >
          Clear All
        </Button>
        <Button
          variant="outlined"
          type="submit"
          fullWidth
          startIcon={<FilterAltIcon style={{ color: "#fff" }} />}
          sx={{
            backgroundColor: "#EA7423",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#EA7423",
              color: "#FFF",
            },
          }}
        >
          Filter
        </Button>
      </Box>
    </form>
  );
}
