import { invokeApi } from "../utils/invokeApi";

export const getDashboardDetail = async () => {
  const requestObj = {
    path: `api/init/admin_dashboard`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
