import React, { useState, useEffect, useCallback, useRef } from "react";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ListIcon from "@mui/icons-material/List";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { useNavigate } from "react-router";
import { programmeDeleteApi, programmeListApi } from "../../DAL/programmes";
import { s3baseUri } from "../../config/config";
import moment from "moment";
import PageLoading from "../../components/generalComponents/PageLoading";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { TextField } from "@mui/material";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomPopoverSection from "../../components/customMUITable/CustomPopoverSection";

const TABLE_HEAD = [
  { id: "action", label: "Action", type: "action", align: "left" },
  { id: "number", label: "#", type: "number", align: "center" },
  { id: "profile_photo", label: "Image", type: "thumbnail", align: "center" },
  { id: "title", label: "Title", align: "left" },
  { id: "recording_count", label: "Recordings", align: "center" },
  { id: "resource_count", label: "Resources", align: "center" },
  {
    id: "program_recording_category_count",
    label: "Recording Categories",
    align: "left",
  },
  { id: "order", label: "Order", align: "center" },
  { id: "status", label: "Status", type: "row_status", align: "center" },
];
const menuOptions = [
  {
    label: "Edit",
    action: (programme, navigate) =>
      navigate(`/programs/edit-programme/${programme.program_slug}`),
  },
  {
    label: "Delete",
    action: (
      programme,
      setDeleteProgramme,
      setDeleteDialogOpen,
      setProgrammeSlug
    ) => {
      setDeleteProgramme(programme.program_slug);
      setProgrammeSlug(programme.program_slug);
      setDeleteDialogOpen(true);
    },
  },
];

const ProgrammesList = () => {
  const INITIAL_VALUES = {
    showProgrammesInCardsForm: true,
    searchString: "",
  };
  const observer = useRef();
  const navigate = useNavigate();
  const [programmeListState, setProgrammeListState] = useState(INITIAL_VALUES);
  const [programmeList, setProgrammeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteProgramme, setDeleteprogramme] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [programmeSlug, setProgrammeSlug] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [cardSearchText, setCardSearchText] = useState("");

  const [loadMorePath, setLoadMorePath] = useState(
    "/program/list_program?page=1&limit=50"
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchData = async (load_more) => {
    if (!load_more) {
      setLoading(true);
    }
    let postData = {
      search: searchText || cardSearchText,
    };

    let data = { page, rowsPerPage, postData, load_more };

    const response = await programmeListApi(data);
    if (response.code === 200) {
      let programs = response.programs.map((program) => {
        return {
          ...program,
          profile_photo: {
            src: s3baseUri + program.main_image?.thumbnail_1,
            alt: program.title,
          },
          date_of_birth: moment(program.createdAt).format("DD/MM/YYYY hh:mm"),
        };
      });
      setTotalCount(response.total_programs);
      setTotalPages(Math.ceil(response.total_programs / rowsPerPage) - 1);
      setLoadMorePath(response.load_more_url);
      if (load_more) {
        setPageNumber((old) => old + 1);
        setProgrammeList((old) => [...old, ...programs]);
      } else {
        setProgrammeList(programs);
        setPageNumber(1);
      }
      setLoading(false);
      setIsLoadingMore(false);
    } else {
      setLoading(false);
      setIsLoadingMore(false);
      console.error("Error fetching data:", response.message);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  const handleCardClick = (data) => {
    navigate(`/programmes/programmes-detail/${data.program_slug}`);
  };

  const handleAddProgramme = () => {
    navigate("/programmes/add-programme");
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const deleteProgram = (row) => {
    setProgrammeSlug(row.program_slug);
    setDeleteprogramme(row.program_slug);
    setDeleteDialogOpen(true);
  };

  const editProgramme = (row) => {
    setProgrammeSlug(row?.program_slug);
    navigate(`/programmes/edit-programme`, {
      state: { program: row },
    });
  };

  const manageResources = (row) => {
    navigate(`/programmes/manage-resource/${row._id}`, {
      state: { program: row },
    });
  };

  const manageCategory = (row) => {
    navigate(`/programmes/manage-recording-categories/${row._id}`, {
      state: { program: row },
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: editProgramme,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: deleteProgram,
    },

    {
      label: "Manage Resources",
      icon: "grommet-icons:resources",
      handleClick: manageResources,
    },
    {
      label: "Manage Recording category",
      icon: "bxs:category-alt",
      handleClick: manageCategory,
    },
  ];

  const handleDelete = async () => {
    setDeleteLoading(true);
    const response = await programmeDeleteApi(programmeSlug);
    if (response.code === 200) {
      setProgrammeList(
        programmeList.filter(
          (programme) => programme.program_slug !== programmeSlug
        )
      );
      setDeleteDialogOpen(false);
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setDeleteLoading(false);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    fetchData(loadMorePath);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  if (loading) return <PageLoading />;

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3 mt-2">
          <div className="col-md-8 d-flex justify-content-between">
            <h4>Programme</h4>
          </div>
          <div className="col-md-4 ">
            <div className="d-flex justify-content-end">
              <button
                onClick={handleAddProgramme}
                className="theme-button-contained"
              >
                Add Programme
              </button>
              <div
                className="program-list-icon"
                onClick={() =>
                  setProgrammeListState((old) => ({
                    ...old,
                    showProgrammesInCardsForm:
                      !programmeListState.showProgrammesInCardsForm,
                  }))
                }
              >
                {programmeListState.showProgrammesInCardsForm ? (
                  <WidgetsIcon />
                ) : (
                  <ListIcon />
                )}
              </div>
            </div>
          </div>
        </div>

        {programmeListState.showProgrammesInCardsForm ? (
          <div className="row mt-2">
            {" "}
            <div className="search-container me-2 d-flex text-end justify-content -end mb-3 ">
              <TextField
                variant="outlined"
                size="small"
                value={cardSearchText}
                onChange={(e) => setCardSearchText(e.target.value)}
                placeholder="Search By Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") searchFunction(e);
                }}
                style={{ marginRight: "8px" }}
              />
              <button
                onClick={searchFunction}
                className="theme-button-contained"
              >
                Search
              </button>
            </div>
            {programmeList.map((programme) => (
              <div
                className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch"
                key={programme._id}
              >
                <div
                  // onClick={() => handleCardClick(programme)}
                  className="card program-card"
                  style={{ width: "100%" }}
                >
                  <img
                    onClick={() => handleCardClick(programme)}
                    style={{ cursor: "pointer" }}
                    className="program-card img "
                    src={s3baseUri + programme.main_image.thumbnail_1}
                    alt={programme.title}
                  />
                  <div className="card-body p-2">
                    <h5
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCardClick(programme)}
                    >
                      {programme.title}
                    </h5>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCardClick(programme)}
                    >
                      {programme.short_description}
                    </p>
                  </div>
                  <div className="mb-0 recording-programme d-flex justify-content-between">
                    <p>Recordings: {programme.recording_count}</p>
                    <CustomPopoverSection
                      menu={MENU_OPTIONS}
                      data={programme}
                    />
                  </div>
                </div>
              </div>
            ))}
            {totalPages >= pageNumber ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMore ? <CircularProgress size={24} /> : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={programmeList}
              pagePagination={true}
              MENU_OPTIONS={MENU_OPTIONS}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        )}
      </div>
      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this programme?"}
        handleAgree={handleDelete}
      />
    </>
  );
};

export default ProgrammesList;
