import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CameraAltIcon from "@mui/icons-material/CameraAltOutlined";
import PersonIcon from "@mui/icons-material/PersonOutline";
import logo from "../../assets/images/appheaderlogo.png";
import GeneralMuiModal from "../../components/generalComponents/GeneralMuiModal";
import { detailEditProfileApi, editProfilrApi } from "../../DAL/editProfile";
import { baseUri, s3baseUri } from "../../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import PageLoading from "../../components/generalComponents/PageLoading";
import { useAdminContext } from "../../hooks/AdminContext";

const EditProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const { setUserInfo } = useAdminContext();

  const INITIAL_VALUES = {
    name: "",
    email: "",
    anchorEl: null,
    avatar: logo,
    dialogOpen: false,
    loading: false,
  };

  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      setLoadingDetails(true); // Set loading to true before the API call
      const response = await detailEditProfileApi();
      if (response.code === 200) {
        const { name, email, profile_image } = response.admin;
        setInputs((old) => ({
          ...old,
          name,
          email,
          avatar: s3baseUri + response.admin.profile_image ?? logo,
        }));
      } else {
        enqueueSnackbar("Failed to fetch admin details", {
          variant: "error",
        });
      }
      setLoadingDetails(false);
    };

    fetchAdminDetails();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleMenuOpen = (event) => {
    setInputs((old) => ({ ...old, anchorEl: event.currentTarget }));
  };

  const handleMenuClose = () => {
    setInputs((old) => ({ ...old, anchorEl: null }));
  };

  const handleUpdateImage = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setInputs((old) => ({ ...old, avatar: reader.result }));
        enqueueSnackbar("Image updated successfully!", { variant: "success" });
        handleMenuClose();
      };
      reader.readAsDataURL(file);
    }
  };

  const handleViewProfile = () => {
    setInputs((old) => ({ ...old, dialogOpen: true }));
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setInputs((old) => ({ ...old, dialogOpen: false }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!inputs.name || !inputs.email) {
      enqueueSnackbar("All fields are required", { variant: "error" });
      return;
    }
    setInputs((old) => ({ ...old, loading: true }));

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("email", inputs.email);
    if (selectedFile) {
      formData.append("profile_image", selectedFile);
    }

    const response = await editProfilrApi(formData);
    if (response.code === 200) {
      const { name, email, profile_image } = response.admin;
      setInputs((old) => ({
        ...old,
        name,
        email,
        avatar: s3baseUri + response.admin.profile_image ?? logo,
      }));
      localStorage.setItem("user", JSON.stringify(response.admin));
      setUserInfo(JSON.stringify(response.admin));
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setInputs((old) => ({ ...old, loading: false }));
  };

  if (loadingDetails) {
    return <PageLoading />;
  }

  return (
    <div className="card edit-profile-card">
      <div className="d-flex edit-profle-image">
        <Avatar
          alt="Profile Picture"
          src={inputs.avatar}
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <IconButton
          color="primary"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          sx={{
            bgcolor: "black",
            color: "#EA7423",
            borderRadius: "50%",
            bottom: "50px",
            left: "40px",
            "&:hover": {
              color: "#EA7423",
              backgroundColor: "black",
            },
          }}
        >
          <CameraAltIcon />
        </IconButton>

        <Menu
          id="profile-menu"
          anchorEl={inputs.anchorEl}
          open={Boolean(inputs.anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleUpdateImage}>
            <ListItemIcon>
              <CameraAltIcon fontSize="small" />
            </ListItemIcon>
            Update Image
          </MenuItem>
          <MenuItem onClick={handleViewProfile}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            View Profile
          </MenuItem>
        </Menu>
        <input
          hidden
          accept="image/*"
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
      <form component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
        <div className="container">
          <div className="col-12 ">
            <TextField
              fullWidth
              margin="normal"
              required
              id="name"
              label="Name"
              name="name"
              value={inputs.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="col-12 ">
            <TextField
              fullWidth
              margin="normal"
              required
              id="email"
              label="Email"
              name="email"
              type="email"
              value={inputs.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="container d-flex justify-content-end mb-3">
          <button className="theme-button-contained" type="submit">
            {inputs.loading ? <CircularProgress size={15} /> : "Update"}
          </button>
        </div>
      </form>
      <GeneralMuiModal
        open={inputs.dialogOpen}
        setOpen={handleDialogClose}
        componentToPass={
          <>
            <img
              className="de-flex text-center justify-cotent-center"
              alt="Profile"
              src={inputs.avatar}
              sx={{ width: 200, height: 200, margin: "0 auto" }}
            />
          </>
        }
      />
    </div>
  );
};

export default EditProfile;
