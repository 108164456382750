import { invokeApi } from "../utils/invokeApi";

export const RecordingCategoryListApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/program_recording_category/list_program_recording_category?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const RecordingCategoryAddApi = async (data) => {
  const requestObj = {
    path: `api/program_recording_category/add_program_recording_category/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateRecordingCategoryDetailApi = async (
  recording_Category_slug
) => {
  const requestObj = {
    path: `api/program_recording_category/detail_program_recording_category/${recording_Category_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const updateRecordingCategoryApi = async (
  recording_Category_slug,
  data
) => {
  const requestObj = {
    path: `api/program_recording_category/update_program_recording_category/${recording_Category_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteRecordingCategory = async (recording_Category_slug) => {
  const requestObj = {
    path: `api/program_recording_category/delete_program_recording_category/${recording_Category_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manageRecordingCategoryApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/program_recording_category/list_program_recording_category_against_program?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateManageRecordinCatgApi = async (data) => {
  const requestObj = {
    path: `api/program_recording_category/manage_program_recording_category_order`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
