import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Divider,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import TextEditor from "../../components/generalComponents/TextEditor";
import { addAppSettingApi } from "../../DAL/appSetting";
import { s3baseUri } from "../../config/config";
import { FileUpload } from "@mui/icons-material";
import PageLoading from "../../components/generalComponents/PageLoading";
import { uploadModuleImageOns3 } from "../../DAL/Modules/Modules";

import {
  addInvoiceSettings,
  getInvoiceSetings,
} from "../../DAL/invoiceSetting";
import { s3ImageUpload } from "../../DAL/uploadImage";

export default function InvoiceSettings() {
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    invoice_from: "",
    skin_sister_address: "",
    payment_info: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [invoiceLogo, setInvoiceLogo] = useState(null);
  const [invoiceLogoPreview, setInvoiceLogoPreview] = useState(null);
  const [invoiceImage, setInvoiceImage] = useState(null);
  const [invoiceImagePreview, setInvoiceImagePreview] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getInvoiceSetings();
      if (response.code === 200) {
        const { invoice_setting } = response;
        setFormValues({
          invoice_from: invoice_setting.invoice_from,
          skin_sister_address: invoice_setting.skin_sister_address,
          payment_info: invoice_setting.payment_info,
        });
        setInvoiceImage(invoice_setting.invoice_logo);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setLoading(false);
    };

    fetchSettings();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // const handleLogoChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setInvoiceLogo(file);
  //     setInvoiceLogoPreview(URL.createObjectURL(file));
  //   }
  // };
  const fileChangedHandlerUpdate = async (e) => {
    setLoadingImage(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("width", 600);
    const imageUpload = await s3ImageUpload(formData);
    if (imageUpload.code === 200) {
      setLoadingImage(false);
      setInvoiceImage(imageUpload.image_path);
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
      setLoadingImage(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInvoiceImage(file);
      setInvoiceImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const jsonData = {
      invoice_from: formValues.invoice_from,
      // skin_sister_address: formValues.skin_sister_address,
      payment_info: formValues.payment_info,
      invoice_logo: invoiceImage,
      // invoice_image: invoiceImagePreview,
    };

    const reqBody = {
      invoice_setting: jsonData,
    };

    // const formData = new FormData();
    // formData.append("invoice_from", formValues.invoice_from);
    // formData.append("skin_sister_address", formValues.skin_sister_address);
    // formData.append("payment_info", formValues.payment_info);

    // if (invoiceLogo) {
    //   formData.append("invoice_logo", invoiceLogo);
    // }
    // if (invoiceImage) {
    //   formData.append("invoice_image", invoiceImage);
    // }

    const response = await addInvoiceSettings(reqBody);

    if (response.code === 200) {
      enqueueSnackbar("Settings saved successfully.", { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }

    setSubmitting(false);
  };

  if (loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="container col-12 mt-1 mb-4">
        <h4>Invoice Setting</h4>
      </div>

      <form onSubmit={handleSave}>
        <div className="row">
          <div className="col-6 mb-3">
            <TextField
              required
              fullWidth
              label="Invoice From"
              variant="outlined"
              name="invoice_from"
              value={formValues.invoice_from}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mb-3">
            {/* <Typography variant="body1" gutterBottom>
              Default Address *
            </Typography>
            <TextEditor
              inputs={formValues}
              setInputs={setFormValues}
              name="skin_sister_address"
            /> */}
          </div>

          <div className="col-12 mb-3">
            <Typography variant="body1" gutterBottom>
              Payment Info *
            </Typography>
            <TextEditor
              inputs={formValues}
              setInputs={setFormValues}
              name="payment_info"
            />
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col-md-4">
                <Typography variant="body1" gutterBottom>
                  Invoice Logo
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  image size (250*100)
                </Typography>
              </div>
              <div className="col-md-4">
                <img
                  src={invoiceImage ? s3baseUri + invoiceImage : ""}
                  alt="Invoice Logo Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100px",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </div>
              <div className="col-md-4 text-end">
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: "16px",
                    background: "#EA7423",
                    color: "#FFFFFF",
                    "&:hover": {
                      boxShadow: "none ",
                      backgroundColor: "#EA7423",
                    },
                    mt: 2,
                  }}
                  startIcon={<FileUpload style={{ color: "white" }} />}
                >
                  {loadingImage ? "Uploading..." : "Upload"}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={fileChangedHandlerUpdate}
                  />
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <Divider className="my-2" />
          </div>

          <div className="col-12 text-end">
            <button
              type="submit"
              className="theme-button-contained"
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
