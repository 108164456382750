import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextEditor from "../../../components/generalComponents/TextEditor";
import { editProgrammeApi } from "../../../DAL/programmes";
import { s3baseUri } from "../../../config/config";

const EditProgrammes = () => {
  const INITIAL_VALUES = {
    title: "",
    email: "",
    short_description: "",
    detailed_description: "",
    imageFile: "",
    mainImageFile: "",
    imagePreview: "",
    mainImagePreview: "",
    video_url: "",
    status: true,
    is_recommended: true,
    order: "",
  };
  const location = useLocation();

  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const program = location.state?.program;

  useEffect(() => {
    setInputs({
      title: program.title,
      email: "",
      short_description: program.short_description,
      detailed_description: program.detailed_description,
      imageFile: "",
      mainImageFile: "",
      // imagePreview: s3baseUri + program.main_image.thumbnail_1,
      mainImagePreview: s3baseUri + program.main_image.thumbnail_2,
      video_url: program.video_url,
      status: program.status,
      is_recommended: program.is_recommended,
      order: program.order,
    });
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "mainImageFile") {
      setInputs((old) => ({
        ...old,
        [name]: files[0],
        mainImagePreview: URL.createObjectURL(files[0]),
      }));
    } else if (name === "imageFile") {
      setInputs((old) => ({
        ...old,
        [name]: files[0],
        imagePreview: URL.createObjectURL(files[0]),
      }));
    } else {
      setInputs((old) => ({
        ...old,
        [name]: value,
      }));
    }
  };

  const handleEditProgramme = async () => {
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    // if (inputs.imageFile) formData.append("imageFile", inputs.imageFile);
    formData.append("video_url", inputs.video_url);
    formData.append("status", inputs.status);
    formData.append("is_recommended", inputs.is_recommended);
    if (inputs.mainImageFile)
      formData.append("main_image", inputs.mainImageFile);
    formData.append("order", inputs.order);

    setLoading(true);
    const result = await editProgrammeApi(program.program_slug, formData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message || "Failed to edit program", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Link to={-1}>
            <ArrowBackIcon />
          </Link>
        </div>
        <div className="col-12 mt-3">
          <h4>Edit Programme</h4>
        </div>
        <div className="col-md-6 mt-3">
          <TextField
            fullWidth
            required
            label="Title"
            name="title"
            variant="outlined"
            value={inputs.title}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-md-6 mt-3">
          <TextField
            fullWidth
            name="video_url"
            label="Video URL"
            variant="outlined"
            value={inputs.video_url}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-md-6 mt-3">
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              required
              value={inputs.status}
              onChange={(e) => handleChange(e)}
              label="Status"
              variant="outlined"
              name="status">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-6 mt-3">
          <FormControl fullWidth>
            <InputLabel>Is Recommended</InputLabel>
            <Select
              value={inputs.is_recommended}
              onChange={(e) => handleChange(e)}
              label="Is Recommended"
              variant="outlined"
              name="is_recommended">
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-6 mt-3">
          <TextField
            fullWidth
            name="order"
            label="Order"
            variant="outlined"
            value={inputs.order}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className="col-12 d-flex justify-content-between align-items-start mt-3">
          <div className="col-md-4">
            <p className="m-0"> Programme Image*</p>
            <p className="add-programme-p mt-1">
              Recommended image size (1000x670) and file types (jpg, png)
            </p>
          </div>
          <div className="col-md-4 text-center">
            {inputs.mainImagePreview && (
              <img
                className="mb-3 ml-3"
                src={inputs.mainImagePreview}
                alt="Preview"
                style={{
                  maxWidth: "30%",
                  marginTop: 15,
                  borderRadius: 8,
                  marginRight: "30px",
                }}
              />
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="my-3 d-flex align-items-center">
              <label
                className="theme-button-contained"
                htmlFor="main-image-upload">
                <FileUpload />
                Upload
              </label>
              <input
                id="main-image-upload"
                type="file"
                hidden
                name="mainImageFile"
                accept="image/*"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <TextField
            fullWidth
            label="Short Description"
            name="short_description"
            multiline
            rows={4}
            value={inputs.short_description}
            onChange={handleChange}
          />
          <p className="add-member-p mt-1">
            Programme short description (Maximum limit 300 characters)
          </p>
        </div>
        <div className="col-12 mt-3">
          <p>Detailed Description</p>
          <TextEditor
            inputs={inputs}
            setInputs={setInputs}
            name="detailed_description"
            value={inputs.detailed_description}
          />
        </div>
        <Divider />
        <div className="col-12 d-flex justify-content-end mt-3">
          <button
            className="theme-button-contained"
            onClick={handleEditProgramme}
            disabled={loading}>
            {loading ? <CircularProgress size={15} /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProgrammes;
