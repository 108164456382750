import { useState, useEffect } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupIcon from "@mui/icons-material/Group";
import CodeIcon from "@mui/icons-material/Code";
import RecentMembersCard from "../../components/dashboardComponents/RecentMembersCard";
import RecentLessonsCard from "../../components/dashboardComponents/RecentLessonsCard";
import { useSnackbar } from "notistack";
import { getDashboardDetail } from "../../DAL/dashboard";
import PageLoading from "../../components/generalComponents/PageLoading";
import { getAdminSettings } from "../../DAL/adminDefaultSetting";

const Dashboard = () => {
  const INITIAL_VALUES = {
    totalMembers: 0,
    totalPrograms: 0,
    totalAmount: 0,
    recentMembers: [],
    recentTransactions: [],
    headingText: " ",
  };

  const [dashboardState, setDashboardState] = useState(INITIAL_VALUES);
  const [isloading, setIsloading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchDashboardDetail = async () => {
    const response = await getDashboardDetail();
    if (response.code === 200) {
      setDashboardState({
        totalMembers: response.admin_dashboard.total_members,
        totalPrograms: response.admin_dashboard.total_programs,
        totalAmount: response.admin_dashboard.total_amount,
        recentMembers: response.admin_dashboard.recent_members,
        recentTransactions: response.admin_dashboard.recent_transactions,
        headingText: response.admin_dashboard.admin_portal_welcome_text,
      });
      setIsloading(false);
    } else {
      setIsloading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDashboardDetail();
  }, []);

  if (isloading) return <PageLoading />;

  return (
    <div className="container-fluid p-1">
      <div className="row">
        <div className="col-md-8 col-lg-6 mb-5 mt-3 dashboard-welcome-heading">
          <h4>{dashboardState.headingText}</h4>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-md-4 mb-3">
          <div className="card h-100 text-center card-total-amount">
            <div className="card-body">
              <div className="avatar mb-3 mt-3">
                <div className="p-3 rounded-circle d-inline-flex align-items-center justify-content-center amount-icon">
                  <AttachMoneyIcon />
                </div>
              </div>
              <p className="dashboard-cards-typography">
                ${dashboardState.totalAmount.toFixed(2)}
              </p>
              <p className="dashboard-card-short-typography">Total Amount</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 mb-3">
          <div className="card h-100 text-center card-members">
            <div className="card-body">
              <div className="avatar mb-3 mt-3">
                <div className="p-3 rounded-circle d-inline-flex align-items-center justify-content-center group-icon">
                  <GroupIcon />
                </div>
              </div>
              <p className="dashboard-cards-typography">
                {dashboardState.totalMembers}
              </p>
              <p className="dashboard-card-short-members">Members</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 mb-3">
          <div className="card h-100 text-center card-programmes">
            <div className="card-body">
              <div className="avatar mb-3 mt-3">
                <div className="p-3 rounded-circle d-inline-flex align-items-center justify-content-center programmes-icon">
                  <CodeIcon />
                </div>
              </div>
              <p className="dashboard-cards-typography">
                {dashboardState.totalPrograms}
              </p>
              <p className="dashboard-programme-card">Programmes</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 justify-content-center">
        <div className="col-12 col-md-6 mb-4">
          <RecentMembersCard members={dashboardState.recentMembers} />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <RecentLessonsCard transactions={dashboardState.recentTransactions} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
