import { invokeApi } from "../utils/invokeApi";

export const getAppSettings = async () => {
  const requestObj = {
    path: `api/app_settings/get_app_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addAppSettingApi = async (data) => {
  const requestObj = {
    path: `api/app_settings/add_app_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
