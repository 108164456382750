import React, { useState, useEffect } from "react";
import { Breadcrumbs, Link as MuiLink, CircularProgress } from "@mui/material";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomMUITable from "../../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../../components/generalComponents/CustomDrawer";
import AddOrUpdateManageCatogeryDrawer from "../../../components/managerecordingCatogeries/AddOrUpdateManageCatogeryDrawer";
import { manageRecordingCategoryApi } from "../../../DAL/recordingCategories";
import { CloudDone } from "@mui/icons-material";
import PageLoading from "../../../components/generalComponents/PageLoading";

const ManageRecordingCategory = () => {
  const INITIAL_VALUES = {
    deleteCategoryId: null,
    deleteDialogOpen: false,
    drawerOpen: false,
    selectedCategory: null,
    categories: [],
  };

  const [state, setState] = useState(INITIAL_VALUES);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const { program_id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const program = location.state?.program;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const navigate = useNavigate();

  const fetchCategories = async () => {
    setLoading(true);
    // const formData = new FormData();
    // formData.append("program_id", program_id);
    // formData.append("search", searchText);
    const postData = {
      program_id: program_id,
      search: searchText,
    };

    const response = await manageRecordingCategoryApi(
      page,
      rowsPerPage,
      postData
    );
    if (response.code === 200) {
      setState((oldState) => ({
        ...oldState,
        categories: response.program_recording_categories,
      }));
      setTotalCount(response.total_program_categories);
      setTotalPages(
        Math.ceil(response.total_program_categories / rowsPerPage) - 1
      );
    } else {
      console.error("Error fetching categories", response.message);
    }
    setLoading(false);
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage]);

  const handleOpenDeleteDialog = (categoryId) => {
    setState((oldState) => ({
      ...oldState,
      deleteCategoryId: categoryId,
      deleteDialogOpen: true,
    }));
  };

  const handleOpenEditDrawer = (category) => {
    setState((oldState) => ({
      ...oldState,
      selectedCategory: category,
      drawerOpen: true,
    }));
  };

  const handleDeleteCategory = () => {
    const updatedCategories = state.categories.filter(
      (category) => category._id !== state.deleteCategoryId
    );
    setState((oldState) => ({
      ...oldState,
      deleteDialogOpen: false,
      categories: updatedCategories,
    }));
  };

  const handleOpenDrawer = () => {
    setState((oldState) => ({
      ...oldState,
      selectedCategory: null,
      drawerOpen: true,
    }));
  };

  const handleCloseDrawer = () => {
    setState((oldState) => ({
      ...oldState,
      drawerOpen: false,
    }));
  };

  const handleNavigateRecordings = () => {
    navigate("/programs/manage-recordings");
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleOpenEditDrawer,
    },
    {
      label: "Manage Recording",
      icon: "carbon:recording-filled-alt",
      handleClick(row) {
        return navigate(`/programmes/manage-recordings/${row._id}`);
      },
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title " },
    { id: "recording_count", label: "Recordings" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="col-12">
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MuiLink
              component={RouterLink}
              to={-1}
              style={{ color: "#EA7423", textDecoration: "none" }}
            >
              Previous Page
            </MuiLink>
            <p style={{ color: "#EA7423", margin: 0 }}>
              Manage Recording Category
            </p>
            <p style={{ margin: 0 }}>{program?.title}</p>
          </Breadcrumbs>
        </div>
      </div>
      <div
        className="colo-lg-12 d-flex mt-2 mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Manage Recording Category </h4>
        <div className="col-md-6" xs={6}>
          <CustomDrawer
            isOpenDrawer={state.drawerOpen}
            onOpenDrawer={handleCloseDrawer}
            onCloseDrawer={handleCloseDrawer}
            pageTitle={`${state.selectedCategory ? "Update" : "Add"} Category`}
            componentToPassDown={
              <AddOrUpdateManageCatogeryDrawer
                onCloseDrawer={handleCloseDrawer}
                selectedObject={state.selectedCategory}
                fetchCategories={fetchCategories}
                programId={program_id}
              />
            }
          />
        </div>
      </div>
      <div className="col-12 mt-3">
        {loading ? (
          <div>
            <PageLoading />
          </div>
        ) : (
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={state.categories}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        )}
      </div>
      <CustomConfirmation
        open={state.deleteDialogOpen}
        setOpen={(open) =>
          setState((oldState) => ({ ...oldState, deleteDialogOpen: open }))
        }
        title={"Are you sure you want to delete this category?"}
        handleAgree={handleDeleteCategory}
      />
    </>
  );
};

export default ManageRecordingCategory;
