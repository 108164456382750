import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FileUpload, Close as CloseIcon } from "@mui/icons-material";
import { addResourcesApi } from "../../DAL/Resource";
import { ActiveProgrammeList } from "../../DAL/programmes";

const AddResource = () => {
  const INITIAL_VALUES = {
    title: "",
    videoURL: "",
    status: "Active",
    documentType: "PDF",
    loading: false,
    selectedPrograms: [],
    settings: {},
    shortDescription: "",
  };

  const [addResourcesState, setAddResourcesState] = useState(INITIAL_VALUES);
  const [settings, setSettings] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [programError, setProgramError] = useState(false);

  const [programs, setPrograms] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchPrograms = async () => {
      const result = await ActiveProgrammeList();
      if (result.code === 200) {
        setPrograms(result.programs);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };

    fetchPrograms();
  }, [enqueueSnackbar]);

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    if (name === "selectedPrograms") {
      setAddResourcesState((old) => ({
        ...old,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setAddResourcesState((old) => ({ ...old, [name]: value }));
    }
  };

  const handleAddResource = async () => {
    if (!settings.fileUpload) {
      setFileError(true);
      enqueueSnackbar("Please upload a file", { variant: "error" });
      return;
    }
    if (addResourcesState.selectedPrograms.length === 0) {
      setProgramError(true);
      enqueueSnackbar("Please choose a program", { variant: "error" });
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    formData.append("title", addResourcesState.title);
    formData.append("description", addResourcesState.shortDescription);
    formData.append(
      "document_file_type",
      "." + addResourcesState.documentType?.toLowerCase()
    );
    formData.append(
      "status",
      addResourcesState.status === "Active" ? true : false
    );
    formData.append(
      "programs",
      JSON.stringify(addResourcesState.selectedPrograms)
    );
    formData.append("file", settings.fileUpload);
    const result = await addResourcesApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/manage-programme-resources");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  const renderImageGuide = () => (
    <div className="resource-image-size">
      <p>(.pdf, .doc, .docx, .xls, .xlsx files are supported only)</p>
    </div>
  );

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "fileUpload") {
      const file = files[0];
      const fileType = file.name.split(".").pop().toUpperCase();
      setAddResourcesState((old) => ({
        ...old,
        documentType: fileType,
      }));
      setSettings({ ...settings, fileUpload: file });
      setFileError(false);
    } else {
      setAddResourcesState((old) => ({ ...old, [name]: value }));
    }
  };

  const handleRemoveFile = () => {
    setSettings({ ...settings, fileUpload: null });
    setAddResourcesState({ ...addResourcesState, documentType: "PDF" });
  };

  return (
    <div className="containerfluid">
      <div className="col-12 container">
        <Link className="custom-link" to={"/manage-programme-resources"}>
          Resources
        </Link>{" "}
        {" / "}Add Resource
      </div>
      <h4 className="mb-3 mt-3 container">Add Resource</h4>
      <div className="container admin-default-settings-div">
        <div className="row mb-3">
          <div className="col-md-6">
            <TextField
              fullWidth
              required
              name="title"
              label="Title"
              variant="outlined"
              value={addResourcesState.title}
              onChange={handleChangeInputs}
              InputProps={{
                style: {
                  height: 56,
                  borderRadius: 10,
                },
              }}
            />
          </div>
          <div className="col-md-6">
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                required
                name="status"
                value={addResourcesState.status}
                onChange={handleChangeInputs}
                label="Status"
                variant="outlined"
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <FormControl fullWidth required error={programError}>
              <InputLabel>Select a Program</InputLabel>
              <Select
                multiple
                value={addResourcesState.selectedPrograms}
                onChange={handleChangeInputs}
                label="Select a Program"
                name="selectedPrograms"
                variant="outlined"
                sx={{ borderRadius: 2 }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // This limits the height of the dropdown list
                      width: 50,
                    },
                  },
                }}
              >
                {programs.map((program) => (
                  <MenuItem key={program._id} value={program._id}>
                    {program.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <TextField
              fullWidth
              required
              label="Short Description"
              variant="outlined"
              multiline
              rows={4}
              name="shortDescription"
              value={addResourcesState.shortDescription}
              onChange={handleChangeInputs}
              InputProps={{
                style: {
                  height: 150,
                  borderRadius: 10,
                },
              }}
            />
            <p className="add-member-p mt-1">
              Resources short description (Maximum limit 300 characters)
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-md-4">
                <p className="m-0">Upload File*</p>
                {renderImageGuide()}
              </div>
              <div className="col-md-4 d-flex align-items-center">
                {settings.fileUpload && (
                  <>
                    <p className="file-name text-end p=2">
                      {settings.fileUpload.name.length > 20
                        ? settings.fileUpload.name.slice(0, 20) + "..."
                        : settings.fileUpload.name}
                      <IconButton
                        aria-label="remove file"
                        onClick={handleRemoveFile}
                        color="error"
                        size="small"
                      >
                        <CloseIcon
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                            borderRadius: "20px",
                          }}
                        />
                      </IconButton>
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4 text-end">
                <label className="theme-button-contained" htmlFor="file-upload">
                  <FileUpload />
                  Upload
                </label>
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  accept=".pdf,.doc,.docx,.xls,.xlsx"
                  name="fileUpload"
                  onChange={handleChange}
                />
                {fileError && (
                  <p style={{ color: "red", marginTop: "8px" }}>
                    File is required
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="row mb-3 mt-3">
          <div className="col-12 d-flex justify-content-end">
            <button
              className="theme-button-contained"
              onClick={handleAddResource}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={15} /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddResource;
