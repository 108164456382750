import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/loginScreen/Login";
import Members from "../pages/members/Members";
import AddMembers from "../components/membersComponents/AddMemers";
import EditMembers from "../components/membersComponents/EditMmembers";
import ForgotPassword from "../pages/loginScreen/forgotPassword/ForgotPassword";
import PasswordPin from "../pages/loginScreen/passwordPin/PasswordPin";
import AppContentSetting from "../pages/settings/AppContentSetting";
import AppGeneralSetting from "../pages/settings/AppGeneralSetting";
import RecordingCatogery from "../pages/recordingCatogeries/RecordingCatogery";
import RecordingList from "../pages/recordingOperations/RecordingList";
import FamilyMembers from "../pages/familyMemberOperation/FamilyMembers";
import EmailSettings from "../pages/generalSetting/EmailSettings";
import ConfirmPassword from "../pages/loginScreen/confirmPasword/ConfirmPassword";
import EditProfile from "../pages/adminNavigation/EditProfile";
import ManageProgrammeResources from "../pages/programmes/manageResources/ManageProgrammeResources";
import ManageResourceOperation from "../pages/programmes/programmeResources/MangeResourceOperation";

import ManageRecordingCatogery from "../pages/programmes/manageRecordingCategories/ManageRecordinCatogery";
import ManageRecording from "../pages/programmes/manageRecording/ManageRecording";
import EditEmailTemplate from "../pages/emailTemplates/EditEmailTemplate";
import { Dashboard, ProgrammesList } from "../pages";
import AddResource from "../components/resourcesComponent/AddResource";
import EditResource from "../components/resourcesComponent/EditResource";
import EmailTemplates from "../pages/emailTemplates/EmailTemplates";
import AdminGeneralSettings from "../pages/generalSetting/defaultSetting";
import DashboardLayout from "../layout/dashboard";
import TemplatePages from "../pages/TemplatePages/TemplatePages";
import Menus from "../pages/WebPages/Menus";
import { MenuItems } from "../pages/WebPages/MenuItems";
import AddOrUpdatePage from "../pages/WebPages/AddOrUpdatePage";
import UpdatePageContent from "../pages/WebPages/UpdatePageContent";
import PaymentPlans from "../pages/WebPages/PaymentPlans";
import AddOrUpdatePaymentPlan from "../pages/WebPages/AddOrUpdatePaymentPlan";
import SocialSharingSetting from "../pages/WebPages/SoicalSharingSetting";
import WebPagesModules from "../pages/Modules/WebPagesModules";
import UpdateModuleData from "../pages/Modules/UpdateModuleData";
import StripeSettings from "../pages/generalSetting/StripeSettings";
import CampaignSetting from "../pages/generalSetting/CampaignSetting";
import StripeProducts from "../pages/Payments/StripeProducts";
import AddorUpdateProduct from "../pages/Payments/AddorUpdateProduct";
import AddProgrammes from "../pages/programmes/programmeComponents/AddProgramme";
import EditProgrammes from "../pages/programmes/programmeComponents/EditProgramme";
import DetailPageProgrammeCard from "../pages/programmes/programmeComponents/DetailCard";
import RemoveMembers from "../pages/programmes/programmeComponents/RemoveMembersAccess";
import Transactions from "../pages/Payments/Transaction";
import SubscriptionList from "../pages/Payments/SubscriptionList";
import SchhoolList from "../pages/schools/SchoolList";
import SchoolMembers from "../pages/schools/SchoolMembers";
import InvoiceSettings from "../pages/generalSetting/InvoiceSetting";
import EmailTemplatePreview from "../pages/emailTemplates/TemplatePreview";
import MemberTransaction from "../pages/schools/MemberTransaction";
import SchoolTransactions from "../pages/Payments/SchoolTransaction";
import AddSchoolTransaction from "../components/schoolTransaction/AddSchoolTransaction";
import Commission from "../pages/Payments/Commision";
import UnpublishSetting from "../pages/WebPages/UnpublishSetting";
import Memberscsubscription from "../pages/membersSubscription/MembersSubscription";
import FieldPractice from "../FieldPractice";

const AppRoutes = () => {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/dashboard"> </Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"> </Navigate>;
    }
  };

  return (
    <Routes>
      {/*************************************{Dashboard Layout}*************************** */}
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/****************************************{Programme}*********************************/}
        <Route path="/programmes" element={<ProgrammesList />} />
        <Route
          path="/programmes/manage-recording-catogeries"
          element={<ManageRecordingCatogery />}
        />
        <Route
          path="/programmes/manage-recordings/:program_recording_category_id"
          element={<ManageRecording />}
        />
        <Route
          path="/programmes/programmes-detail/:program_slug"
          element={<DetailPageProgrammeCard />}
        />
        <Route
          path="/manage-programme-resources"
          element={<ManageResourceOperation />}
        />
        <Route
          path="/programmes/manage-resource/:program_id"
          element={<ManageProgrammeResources />}
        />
        <Route
          path="/manage-programme-resources/add-resources"
          element={<AddResource />}
        />
        <Route
          path="/manage-programme-resources/edit-resources"
          element={<EditResource />}
        />
        <Route path="/recording-catogery" element={<RecordingCatogery />} />
        <Route path="/recording-list" element={<RecordingList />} />
        <Route path="/programmes/add-programme" element={<AddProgrammes />} />
        <Route path="/programmes/edit-programme" element={<EditProgrammes />} />
        <Route
          path="/programs/remove-members-access"
          element={<RemoveMembers />}
        />
        /
        <Route
          path="/programmes/manage-recording-categories/:program_id"
          element={<ManageRecordingCatogery />}
        />
        {/* ************************************{Member}*************************** */}
        <Route path="/members" element={<Members />} />
        <Route path="/members/add-members" element={<AddMembers />} />
        <Route path="/members/edit-members" element={<EditMembers />} />
        <Route
          path="/members/members-subscription/:subscription_id"
          element={<Memberscsubscription />}
        />
        <Route
          path="/members/family-member-operation/:member_user_id"
          element={<FamilyMembers />}
        />
        <Route path="/field-practice" element={<FieldPractice />} />
        {/* ************************************{School}*************************** */}
        <Route path="/schoolList" element={<SchhoolList />} />
        <Route
          path="/schoolList/schoolmembers/:id"
          element={<SchoolMembers />}
        />
        <Route
          path="/schoolmembers/:schoolId/transactions/:id"
          element={<MemberTransaction />}
        />
        {/* ************************************{App Setting}*************************** */}
        <Route path="/app-general-setting" element={<AppGeneralSetting />} />
        <Route exact="/app-content-setting" component={<AppContentSetting />} />
        {/* ************************************{Email Settings}*************************** */}
        <Route path="/email-template" element={<EmailTemplates />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route
          path="/email-templates/edit-email-template/:email_template_slug"
          element={<EditEmailTemplate />}
        />
        <Route
          path="/email-templates/preview-email-template/:slug"
          element={<EmailTemplatePreview />}
        />
        {/* ************************************{Admin General Settings}*************************** */}
        <Route
          path="/admin-general-setting"
          element={<AdminGeneralSettings />}
        />
        <Route path="/email-setting" element={<EmailSettings />} />
        <Route path="/stripe-setting" element={<StripeSettings />} />
        <Route path="/campaign-setting" element={<CampaignSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSettings />} />
        {/* <Route path="/app-general-setting" element={<AppGeneralSetting/>}/> */}
        <Route path="/edit-profile" element={<EditProfile />}></Route>
        {/* ************************************{Manage Website}*************************** */}
        <Route path="/template-pages" element={<TemplatePages />} />
        <Route
          path="/template-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/template-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<PaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/template-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route path="/website-pages" element={<TemplatePages />} />
        <Route path="/website-pages/add-page" element={<AddOrUpdatePage />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans"
          element={<PaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route path="/menus" element={<Menus />} />
        <Route path="/menu/add-menu" element={<MenuItems />} />
        <Route path="/menu/:id" element={<MenuItems />} />
        {/* ************************************{Modules}*************************** */}
        <Route
          path="/website-pages/:page_id/:module_slug"
          element={<WebPagesModules />}
        />
        <Route
          path="/website-pages/:page_id/:module_slug/add-module"
          element={<UpdateModuleData />}
        />
        <Route
          path="/website-pages/:page_id/:module_slug/edit-module/:id"
          element={<UpdateModuleData />}
        />
        <Route
          path="/template-pages/:page_id/:module_slug"
          element={<WebPagesModules />}
        />
        <Route
          path="/template-pages/:page_id/:module_slug/add-module"
          element={<UpdateModuleData />}
        />
        <Route
          path="/template-pages/:page_id/:module_slug/edit-module/:id"
          element={<UpdateModuleData />}
        />
        {/* ************************************{Payments}*************************** */}
        <Route path="/stripe-products" element={<StripeProducts />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/payment-subscription" element={<SubscriptionList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddorUpdateProduct />}
        />{" "}
        <Route
          path="/stripe-products/edit-product/:id"
          element={<AddorUpdateProduct />}
        />
        <Route path="/school-transactions" element={<SchoolTransactions />} />
        <Route path="/commissions" element={<Commission />} />
        <Route
          path="/school-transactions/add-school-transaction"
          element={<AddSchoolTransaction />}
        />
      </Route>

      {/* ************************************{Admin On Boarding}*************************** */}
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/password-pin" element={<PasswordPin />}></Route>
      <Route path="/confirm-password" element={<ConfirmPassword />}></Route>
    </Routes>
  );
};

export default AppRoutes;
