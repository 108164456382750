import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Divider,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import TextEditor from "../../components/generalComponents/TextEditor";
import { getAppSettings, addAppSettingApi } from "../../DAL/appSetting";
import { s3baseUri } from "../../config/config";
import { FileUpload } from "@mui/icons-material";
import PageLoading from "../../components/generalComponents/PageLoading";

export default function AppGeneralSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues = {
    introduction_video_url: "",
    introduction_video_description: "",
    terms_and_conditions: "",
    privacy_policy_text: "",
    contact_us_support_email: "",
    signup_statement: "",
    already_have_account_statement: "",
    recommended_program_statement: "",
    home_screen_heading: "",
    program_screen_heading: "",
    premium_payment_description: "",
    new_member_description: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [emailError, setEmailError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [urlErrors, setUrlErrors] = useState({
    videoUrl: false,
    termsUrl: false,
    privacyUrl: false,
  });
  const [app_logo, setapp_logo] = useState(null);
  const [app_logoPreview, setapp_logoPreview] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getAppSettings();
      if (response.code === 200) {
        const { app_settings } = response;
        setFormValues({
          introduction_video_url:
            app_settings.introduction_content.introduction_video_url,
          introduction_video_description:
            app_settings.introduction_content.introduction_video_description,
          terms_and_conditions: app_settings.terms_and_conditions,
          privacy_policy_text: app_settings.privacy_policy_text,
          contact_us_support_email: app_settings.contact_us_support_email,
          signup_statement: app_settings.signup_statement,
          already_have_account_statement:
            app_settings.already_have_account_statement,
          recommended_program_statement:
            app_settings.recommended_program_statement,
          home_screen_heading: app_settings.home_screen_heading,
          program_screen_heading: app_settings.program_screen_heading,
          premium_payment_description: app_settings.premium_payment_description,
          new_member_description: app_settings.new_member_description,
        });
        setapp_logoPreview(s3baseUri + app_settings.app_logo.thumbnail_1);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setLoading(false);
    };

    fetchSettings();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === "contact_us_support_email") {
      validateEmail(value);
    } else if (
      [
        "introduction_video_url",
        "terms_and_conditions",
        "privacy_policy_text",
      ].includes(name)
    ) {
      validateUrl(name, value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };

  const validateUrl = (name, url) => {
    const urlRegex =
      /^(https?:\/\/)?([\w\d\-]+\.)+[\w\d\-]+(\/[\w\d\-._~:\/?#@!$&'()*+,;=]*)?$/;
    setUrlErrors((prev) => ({
      ...prev,
      [name]: !urlRegex.test(url),
    }));
  };

  const handleapp_logoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setapp_logo(file);
      setapp_logoPreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const isEmptyField = Object.values(formValues).some(
      (value) => !value.trim()
    );
    if (emailError) {
      enqueueSnackbar("Please enter a valid email.", { variant: "error" });
    } else {
      setSubmitting(true);
      const formData = new FormData();
      for (const [key, value] of Object.entries(formValues)) {
        formData.append(key, value);
      }
      if (app_logo) {
        formData.append("app_logo", app_logo);
      }

      const response = await addAppSettingApi(formData);
      if (response.code === 200) {
        enqueueSnackbar("Settings saved successfully.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setSubmitting(false);
    }
  };

  if (loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="container col-12 mt-1 mb-4">
        <h4>App Setting</h4>
      </div>

      <form onSubmit={handleSave}>
        <div className="row">
          <div className="col-12 mb-3">
            <TextField
              required
              fullWidth
              label="Introduction Video URL"
              variant="outlined"
              name="introduction_video_url"
              value={formValues.introduction_video_url}
              onChange={handleChange}
              type="url"
            />
          </div>

          <div className="col-12 mb-3">
            <Typography variant="body1" gutterBottom>
              Introduction Video Description
            </Typography>
            <TextEditor
              inputs={formValues}
              setInputs={setFormValues}
              name="introduction_video_description"
            />
          </div>
          <div className="col-12 mb-3">
            <Typography variant="body1" gutterBottom>
              New Member Description
            </Typography>
            <TextEditor
              inputs={formValues}
              setInputs={setFormValues}
              name="new_member_description"
            />
          </div>

          <div className="col-12 mb-3">
            <TextField
              required
              fullWidth
              label="Terms and Condition"
              variant="outlined"
              name="terms_and_conditions"
              value={formValues.terms_and_conditions}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mb-3">
            <TextField
              required
              fullWidth
              label="Privacy Policy Text"
              variant="outlined"
              name="privacy_policy_text"
              value={formValues.privacy_policy_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mb-3">
            <TextField
              fullWidth
              required
              label="Contact Us Email"
              variant="outlined"
              name="contact_us_support_email"
              value={formValues.contact_us_support_email}
              onChange={handleChange}
              error={emailError}
              helperText={emailError ? "Invalid email format" : ""}
              type="email"
            />
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col-md-4">
                <Typography variant="body1" gutterBottom>
                  App Logo*
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  image size (200*100)
                </Typography>
              </div>
              <div className="col-md-4">
                {app_logoPreview && (
                  <img
                    src={app_logoPreview}
                    alt="App Logo Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 text-end">
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: "16px",
                    background: "#EA7423",
                    color: "#FFFFFF",
                    "&:hover": {
                      boxShadow: "none ",
                      backgroundColor: "#EA7423",
                    },
                    mt: 2,
                  }}
                  startIcon={<FileUpload style={{ color: "white" }} />}
                >
                  Upload
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleapp_logoChange}
                  />
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <Divider className="my-2" />
          </div>

          <div className="col-12 text-end">
            <button
              type="submit"
              className="theme-button-contained"
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
