import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import sidepic from "../../../assets/images/7ead8c3a-23eb-4b24-8ab2-a21d3017d84d.png";
import logo from "../../../assets/images/appheaderlogo.png";
import CustomTextField from "../../../components/customTextField/CustomTextField";
import { useSnackbar } from "notistack";
import { confirmpasswordApi } from "../../../DAL/confirmPassword";
import { GetDeatailAdmin } from "../../../DAL/auth";
import { s3baseUri } from "../../../config/config";

const ConfirmPassword = () => {
  const INITIAL_VALUES = {
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    loading: false,
  };

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [passwordStates, setPasswordStates] = useState(INITIAL_VALUES);
  const [adminSettings, setAdminSettings] = useState({});

  const location = useLocation();

  const email = location.state?.email || "dynamiclogix@gmail.com";
  const fetchAdminSettings = async () => {
    const response = await GetDeatailAdmin();
    if (response.code === 200) {
      setAdminSettings(response.admin_portal_settings);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchAdminSettings();
  }, [enqueueSnackbar]);

  const handlePasswordToggle = () => {
    setPasswordStates((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleConfirmPasswordToggle = () => {
    setPasswordStates((prevState) => ({
      ...prevState,
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!passwordStates.password || !passwordStates.confirmPassword) {
      enqueueSnackbar("Both password fields are required.", {
        variant: "error",
      });
      return;
    }

    if (passwordStates.password !== passwordStates.confirmPassword) {
      enqueueSnackbar("Passwords do not match. Please try again.", {
        variant: "error",
      });
      return;
    }

    setPasswordStates((prevState) => ({ ...prevState, loading: true }));

    const response = await confirmpasswordApi({
      email: email,
      password: passwordStates.password,
      confirm_password: passwordStates.confirmPassword,
    });

    if (response.code === 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setPasswordStates((prevState) => ({ ...prevState, loading: false }));
  };

  return (
    <div className="d-flex vh-100 overflow-hidden">
      <div className="row g-0 flex-grow-1">
        <div className="col-md-4 d-flex flex-column align-items-center justify-content-center p-4 bg-light admin-login-logo">
          <img
            style={{ width: "80px" }}
            src={s3baseUri + adminSettings.brand_logo?.thumbnail_1}
            alt="Logo"
          />{" "}
          <h4 className="mb-3">Confirm Password</h4>
          <form onSubmit={handleSubmit} className="w-100">
            <div className="mb-4">
              <CustomTextField
                fullWidth
                label="Password"
                type={passwordStates.showPassword ? "text" : "password"}
                required
                value={passwordStates.password}
                onChange={(e) =>
                  setPasswordStates((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handlePasswordToggle} edge="end">
                      {passwordStates.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  ),
                }}
                variant="outlined"
              />
            </div>
            <div className="mb-3">
              <CustomTextField
                fullWidth
                label="Confirm Password"
                type={passwordStates.showConfirmPassword ? "text" : "password"}
                required
                value={passwordStates.confirmPassword}
                onChange={(e) =>
                  setPasswordStates((prevState) => ({
                    ...prevState,
                    confirmPassword: e.target.value,
                  }))
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleConfirmPasswordToggle}
                      edge="end"
                    >
                      {passwordStates.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  ),
                }}
                variant="outlined"
              />
            </div>
            <button
              className="w-100 login-button mt-3"
              disabled={passwordStates.loading}
            >
              {passwordStates.loading ? (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
          <Link to={"/login"} className="custom-link mt-5">
            Back to Login
          </Link>
        </div>

        <div className="col-md-8 p-0">
          <div className="w-100 h-100 overflow-hidden">
            <img
              className="object-fit-cover h-100 w-100"
              src={
                s3baseUri + adminSettings.login_page_image?.thumbnail_1 ||
                sidepic
              }
              alt="forgot"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
