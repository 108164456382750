import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  stripeProductAdd,
  stripeProductDetail,
  stripeProductEdit,
} from "../../DAL/Payments/Stripe/StripeProducts";
import TextEditor from "../../components/generalComponents/TextEditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddorUpdateProduct = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = React.useState({
    name: "",
    description: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      name: inputs.name,
      description: inputs.description,
    };

    setIsLoading(true);
    const result =
      formType === "ADD"
        ? await stripeProductAdd(postData)
        : await stripeProductEdit(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getProductDetail = async () => {
    setIsLoading(true);
    const result = await stripeProductDetail(params.id);
    if (result.code == 200) {
      setInputs(result?.product);
      setFormType("Edit");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!!state) {
      //   setInputs(state);
      setFormType("EDIT");
      getProductDetail();
    } else {
      setIsLoading(false);
      setFormType("ADD");
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"}`} Product</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Name *"
            variant="outlined"
            fullWidth
            name="name"
            value={inputs.name}
            onChange={handleChange}
          />
        </div>

        <div className="col-12 mt-5">
          <h4>Description</h4>
          <TextEditor
            inputs={inputs}
            setInputs={setInputs}
            name="description"
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="theme-button-contained">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddorUpdateProduct;
