import { invokeApi } from "../utils/invokeApi";

export const ResourcesListApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/resources/list_resources?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addResourcesApi = async (data) => {
  const requestObj = {
    path: `api/resources/add_resource`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const ResourceDetailApi = async (resource_slug) => {
  const requestObj = {
    path: `api/resources/detail_resource/${resource_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editResourceApi = async (resource_slug, data) => {
  const requestObj = {
    path: `api/resources/update_resource/${resource_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteResourceApi = async (resource_slug) => {
  const requestObj = {
    path: `api/resources/delete_resource/${resource_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manageResourceOrderApi = async (data) => {
  const requestObj = {
    path: `api/resources/manage_resource_order`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
