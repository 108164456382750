import React, { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);
export function ContextAdmin({ children }) {
  const [userInfo, setUserInfo] = useState(null);
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserInfo(JSON.parse(user));
    }
  }, [localStorage.getItem("user")]);

  const collection = {
    setUserInfo,
    userInfo,
    handleUserInfo,
  };
  return (
    <AdminContext.Provider value={collection}>{children}</AdminContext.Provider>
  );
}
