import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { manageResourceOrderApi } from "../../DAL/Resource";
import { enqueueSnackbar } from "notistack";

const INITIAL_VALUES = {
  order: "",
};

export default function AddOrUpdateProgrammeResourceDrawer(props) {
  const { selectedObject, onCloseDrawer, programId, fetchResources } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [orderError, setOrderError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "order") {
      if (value <= 0) {
        setOrderError("Order must be greater than 0");
      } else {
        setOrderError("");
      }
    }
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.order <= 0) {
      setOrderError("Order must be greater than 0");
      return;
    }

    setIsLoading(true);
    const payload = {
      program_id: programId,
      resource_id: selectedObject._id,
      order: inputs.order,
    };

    const response = await manageResourceOrderApi(payload);

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });

      onCloseDrawer();
      fetchResources();
    } else {
      console.error("Error updating resource order", response.message);
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedObject) {
      setInputs(selectedObject);
    }
  }, [selectedObject]);

  return (
    <form className="drawer-form" onSubmit={handleSubmit}>
      <TextField
        label="order"
        type="number"
        variant="outlined"
        value={inputs.order}
        name="order"
        onChange={handleChange}
        fullWidth
        error={Boolean(orderError)}
        helperText={orderError}
      />

      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
