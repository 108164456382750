import { invokeApi } from "../utils/invokeApi";

export const RecordingApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/recording/list_recording?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addRecordingApi = async (data) => {
  const requestObj = {
    path: `api/recording/add_recording`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateRecordingApi = async (recording_slug, data) => {
  const requestObj = {
    path: `api/recording/update_recording/${recording_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getActiveCategories = async () => {
  const requestObj = {
    path: `api/program_recording_category/list_active_program_recording_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getRecordingDetail = async (recording_slug) => {
  const requestObj = {
    path: `api/recording/detail_recording/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteRecordingApi = async (recording_slug) => {
  const requestObj = {
    path: `api/recording/delete_recording/${recording_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const ListManageRecording = async (page, limmit, data) => {
  const requestObj = {
    path: `api/recording/list_category_recording?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateOrder = async (data) => {
  const requestObj = {
    path: `api/recording/manage_recording_order`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
