import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import sidepic from "../../../assets/images/7ead8c3a-23eb-4b24-8ab2-a21d3017d84d.png";
import logo from "../../../assets/images/appheaderlogo.png";
import VerificationInput from "../verificationInput/verificationInput";
import { useSnackbar } from "notistack";
import { passwordPinApi } from "../../../DAL/passwordpin";
import { s3baseUri } from "../../../config/config";
import { GetDeatailAdmin } from "../../../DAL/auth";

const PasswordPin = () => {
  const location = useLocation();
  const email = location.state?.email || "dynamiclogix@gmail.com";

  const INITIAL_VALUES = {
    otp: "",
    email: email,
    loading: false,
    resendDisabled: true,
    timer: 60,
  };

  const [state, setState] = useState(INITIAL_VALUES);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [adminSettings, setAdminSettings] = useState({});
  const fetchAdminSettings = async () => {
    const response = await GetDeatailAdmin();
    if (response.code === 200) {
      setAdminSettings(response.admin_portal_settings);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchAdminSettings();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const interval = setInterval(() => {
      setState((prevState) => {
        if (prevState.timer <= 1) {
          clearInterval(interval);
          return { ...prevState, resendDisabled: false, timer: 0 };
        }
        return { ...prevState, timer: prevState.timer - 1 };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleOtpVerification = async () => {
    if (state.otp.length !== 6) {
      enqueueSnackbar("Please fill all OTP fields!", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }

    setState((prevState) => ({ ...prevState, loading: true }));

    const response = await passwordPinApi({
      email: state.email,
      verification_code: state.otp,
    });

    if (response.code === 200) {
      enqueueSnackbar("OTP verified successfully!", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setTimeout(() => {
        navigate("/confirm-password");
      }, 2000);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const handleResendOtp = async () => {
    enqueueSnackbar("Please wait, resend OTP is being processed...", {
      variant: "info",
      autoHideDuration: 2000,
    });

    setState((prevState) => ({
      ...prevState,
      resendDisabled: true,
      timer: 60,
    }));

    const interval = setInterval(() => {
      setState((prevState) => {
        if (prevState.timer <= 1) {
          clearInterval(interval);
          return { ...prevState, resendDisabled: false, timer: 0 };
        }
        return { ...prevState, timer: prevState.timer - 1 };
      });
    }, 1000);

    const response = await passwordPinApi({ email: state.email });

    if (response.code === 200) {
      enqueueSnackbar("New OTP sent successfully!", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const setOtp = (otp) => {
    setState((prevState) => ({ ...prevState, otp }));
  };

  return (
    <div className="vh-100 d-flex">
      <div className="row g-0 flex-grow-1">
        <div className="col-md-4 d-flex flex-column align-items-center justify-content-center bg-light p-4 admin-login-logo">
          <img
            style={{ width: "80px" }}
            src={s3baseUri + adminSettings.brand_logo?.thumbnail_1}
            alt="Logo"
          />{" "}
          <h4 className="mb-3">Password Reset Verification</h4>
          <VerificationInput onInputFilled={setOtp} />
          <button
            className="w-100 login-button mt-3"
            onClick={handleOtpVerification}
            disabled={state.loading}
          >
            {state.loading ? (
              <div className="spinner-border spinner-border-sm" role="status" />
            ) : (
              "Submit"
            )}
          </button>
          <div
            className="d-flex text-end"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div
              className={`resend-text text-end mt-3 ${
                state.resendDisabled ? "disabled" : ""
              }`}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "right",
                cursor: state.resendDisabled ? "not-allowed" : "pointer",
              }}
              onClick={!state.resendDisabled ? handleResendOtp : null}
            >
              {state.resendDisabled
                ? `Resend OTP (Wait ${state.timer}s)`
                : "Resend Code"}
            </div>
          </div>
          <Link className="custom-link mt-5" to={"/login"}>
            Back to Login
          </Link>
        </div>
        <div className="col-12 col-md-8 d-flex align-items-center justify-content-center p-0 overflow-hidden">
          <div className="password-pin-pic w-100 overflow-hidden">
            <img
              className="object-fit-cover h-100 w-100"
              src={
                s3baseUri + adminSettings.login_page_image?.thumbnail_1 ||
                sidepic
              }
              alt="forgot"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordPin;
