import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { deleteTransaction, TransactionListApi } from "../../DAL/transaction";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import FilterTrnsaction from "../../components/transactions/FilterTransaction";
import { convertCurrencyToSign } from "../../utils/constant";
import { Button, Box, Badge } from "@mui/material";
import { project_mode } from "../../config/config";

const EMPTY_FILTER = {
  selectedRow: null,
  loading: false,
  confirmDeleteOpenMember: false,
  drawerOpen: false,
  selectedCategory: null,
  members: [],
  page: 0,
  rowsPerPage: 50,
  totalCount: 0,
  pageCount: 1,
  searchText: "",
  transaction_Status: "succeeded",
  startDate: "",
  endDate: "",
  transaction_mode: project_mode === "dev" ? "sandBox" : "live",
  sale_page: null,
  status: true,
  school: null,
};

const Transactions = () => {
  const [state, setState] = useState(EMPTY_FILTER);
  const [filterState, setFilterState] = useState({
    transaction_Status: "",
    transaction_mode: "",
    school: "",
    startDate: "",
    endDate: "",
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingMemberId, setDeletingMemberId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [csvData, setCsvDara] = useState("");
  const [chips, setChips] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedChips, setSelectedChips] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setIsLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const navigate = useNavigate();
  const handleChipClick = (chip) => {
    if (selectedChips.includes(chip)) {
      setSelectedChips(selectedChips.filter((c) => c !== chip));
    } else {
      setSelectedChips([...selectedChips, chip]);
    }
  };

  const countActiveFilters = () => {
    let count = 0;

    if (state.startDate) count += 1;
    if (state.endDate) count += 1;
    if (state.transaction_Status.length > 0) count += 1;
    if (state.transaction_mode) count += 1;
    if (state.school) count += 1;

    return count;
  };
  const fetchData = async (clearFilter) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const postData = {
      search: clearFilter ? "" : state.searchText,
      start_date: clearFilter ? null : state.startDate,
      end_date: clearFilter ? null : state.endDate,
      transaction_status: clearFilter
        ? []
        : state.transaction_Status && state.transaction_Status.length !== 0
        ? [state.transaction_Status]
        : [],
      transaction_mode: clearFilter ? "" : state.transaction_mode,
      sale_page: null,
    };

    if (!clearFilter && state.school) {
      postData.school = state.school._id;
    }

    const response = await TransactionListApi(page, rowsPerPage, postData);

    if (response.code === 200) {
      let chips_array = [];

      if (!clearFilter) {
        if (state.startDate) {
          chips_array.push(state.startDate);
          setChips(chips_array);
        }
        if (state.endDate) {
          chips_array.push(state.endDate);
          setChips(chips_array);
        }
        if (state.transaction_Status && state.transaction_Status.length > 0) {
          chips_array.push(state.transaction_Status);
          setChips(chips_array);
        }
        if (state.transaction_mode) {
          chips_array.push(state.transaction_mode);
          setChips(chips_array);
        }
        if (state.school) {
          chips_array.push(state.school?.school_name);
          setChips(chips_array);
        }
      }
      chips_array = chips_array.filter((chip) => chip !== "ChipToRemove");

      const transactions = response.transactions?.map((transaction) => {
        const currency = transaction?.currency
          ? convertCurrencyToSign(transaction?.currency)
          : "N/A";
        const totalAmount = transaction?.total_amount
          ? `${currency} ${transaction.total_amount}`
          : "N/A";
        const paidAmount = transaction?.paid_amount
          ? `${transaction.paid_amount} ${currency}`
          : "N/A";
        const schoolName = transaction?.school_name || "No School";
        let commissionAmount = "";
        if (
          transaction.commission_info &&
          transaction.commission_info.commission_type === "fixed"
        ) {
          commissionAmount =
            "£" + transaction.commission_info.school_commission_amount;
        } else if (transaction.commission_info) {
          commissionAmount =
            transaction.commission_info.school_commission_amount + "%";
        } else {
          commissionAmount = "£0";
        }

        return {
          id: transaction._id,
          name:
            transaction.member_info?.name +
              (transaction.member_info?.email
                ? ` (${transaction.member_info.email})`
                : "") || "N/A",
          transaction_date:
            moment(transaction.transaction_date).format("DD-MM-YYYY hh:mm A") ||
            "N/A",
          transaction_type: transaction?.transaction_type,
          status: transaction?.status,
          payment_mode: transaction?.payment_mode,
          total_amount: totalAmount,
          paid_amount: paidAmount,
          plan_name: transaction?.plan_info?.plan_title || "",
          currency: currency,
          sale_page_title: transaction.sale_page?.sale_page_title || "",
          schoolName: schoolName,
          commissionInfo: commissionAmount,
        };
      });
      setTotalCount(response.total_transactions);
      setTotalPages(Math.ceil(response.total_transactions / rowsPerPage) - 1);

      setState((prevState) => ({
        ...prevState,
        members: transactions,
        loading: false,
      }));
      setChips(chips_array);
      setIsSubmit(false);
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
      console.error(
        "Error fetching data:",
        response?.message || "Unknown error"
      );
    }
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const clearFilters = () => {
    setState((prevState) => ({
      ...prevState,
      searchText: "",
      startDate: null,
      endDate: null,
      sale_page: null,
      status: "",
      transaction_mode: "",
      page: 0,
      school: null,
      transaction_Status: [],
    }));
    setChips([]);
    fetchData(true);
    handleCloseDrawer();
  };

  const applyFilters = () => {
    fetchData();
    handleCloseDrawer();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setState((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectOther = (name, value) => {
    setState((values) => ({ ...values, [name]: value }));
  };

  const handleDeleteMember = async (memberId) => {
    setDeleteLoading(true);
    const response = await deleteTransaction(memberId);
    console.log(response, "response delete api of transctions.........");

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setDeleteDialogOpen(false);
      fetchData();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setDeleteDialogOpen(false);
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    fetchData(false);
  }, [page, rowsPerPage, state.searchText, state.selectedCategory]);
  useEffect(() => {
    if (isSubmit) {
      fetchData();
    }
  }, [
    state.transaction_Status,
    state.transaction_mode,
    state.school,
    state.endDate,
    state.startDate,
  ]);

  // const handleChangeRowsPerPage = (event) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     rowsPerPage: parseInt(event.target.value, 10),
  //     page: 0,
  //   }));
  // };

  // const handleChangePage = (event, newPage) => {
  //   setState((prevState) => ({ ...prevState, page: newPage }));
  // };

  const searchFunction = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, page: 0 }));
    fetchData();
  };

  const handleExportCsv = async () => {
    fetchData(false, true);
    const { members } = state;

    download_csv_file(headers, members);
  };

  function download_csv_file(headers, data) {
    const headerRow = headers.map((column) => column.label);
    const csvRows = [headerRow];

    data.forEach((row) => {
      const dataRow = headers.map((column) => row[column.key] || "");
      csvRows.push(dataRow);
    });

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = "transactions.csv";
    hiddenElement.click();
  }

  const handleDelete = (row) => {
    setDeletingMemberId(row.id);
    setDeleteDialogOpen(true);
  };

  const handleFamilyMembers = (row) => {
    navigate(`/members/family-member-operation/${row.user_id}`);
  };

  const handleFilter = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };

  const handleCloseDrawer = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  };
  const handleDeleteChip = (chip) => {
    setIsSubmit(true);
    let updatedState = { ...state };

    if (chip === state.startDate) {
      updatedState.startDate = null;
    } else if (chip === state.endDate) {
      updatedState.endDate = null;
    } else if (chip === state.transaction_Status) {
      updatedState.transaction_Status = "";
    } else if (chip === state.transaction_mode) {
      updatedState.transaction_mode = "";
    } else if (chip === state.school?.school_name) {
      updatedState.school = null;
    }

    const updatedChips = chips.filter((c) => c !== chip);
    setChips(updatedChips);
    const newFilterCount = countActiveFilters(updatedState);

    setState((prevState) => ({
      ...updatedState,
      filterCount: newFilterCount,
    }));

    // fetchData();
  };
  const handleClearAll = () => {
    setChips([]);

    setState((prevState) => ({
      ...prevState,
      searchText: "",
      page: 0,
    }));

    fetchData();
  };

  const getChipStyle = (chip) => {
    switch (chip) {
      case "Success":
      case "SandBox":
        return {
          "& .MuiChip-deleteIcon": {
            color: "#EA7423",
          },
        };
      default:
        return {};
    }
  };
  function download_csv_file(csv, csvFileData) {
    const headerRow = csv?.map((column) => column.label);
    const csvRows = [headerRow];

    csvFileData?.forEach((row) => {
      const dataRow = csv?.map((column) => {
        const value = row[column.key] || "";
        return value.toString();
      });

      csvRows.push(dataRow);
    });

    const csvContent = csvRows?.map((row) => row.join(",")).join("\n");

    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = "data.csv";
    hiddenElement.click();
  }
  const handleExportClick = () => {
    download_csv_file(headers, csvData);
  };
  const headers = [
    { label: "Name", key: "name" },
    // { label: "Email", key: "email" },
    { label: "Transaction Date", key: "transaction_date" },
    { label: "Transaction Mode", key: "payment_mode" },
    { label: "Plan Name", key: "plan_name" },
    { label: "status", key: "status" },
    { label: "schoolName", key: "schoolName" },
    { label: "total_amount", key: "total_amount" },
    { label: "sale_page_title", key: "sale_page_title" },
    { label: "transaction_type", key: "transaction_type" },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Member" },
    { id: "schoolName", label: "School" },

    { id: "transaction_date", label: "Transaction Date" },
    { id: "transaction_type", label: "Type" },
    { id: "status", label: "Status", type: "transaction_Status" },
    { id: "payment_mode", label: "Payment Mode" },
    { id: "total_amount", label: " Amount" },
    { id: "commissionInfo", label: "Commission" },

    // { id: "paid_amount", label: "Amount Paid" },
    { id: "plan_name", label: "Plan Name" },
    { id: "sale_page_title", label: "Sale Page Title" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDelete,
    },
  ];

  if (state.loading) {
    return <PageLoading />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Transactions</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <button
            className="theme-button-contained mx-2"
            onClick={handleExportCsv}
          >
            <DownloadIcon sx={{ mr: 1 }} />
            Export CSV
          </button>
          <Button
            sx={{
              color: "#fff",
              boxShadow: "none",
              textTransform: "capitalize",
              height: "42px",
            }}
            variant="contained"
            startIcon={<FilterListIcon style={{ color: "white" }} />}
            onClick={() =>
              setState((prevState) => ({ ...prevState, drawerOpen: true }))
            }
            style={{ position: "relative", paddingRight: "30px" }}
          >
            Filter
            {countActiveFilters() > 0 && (
              <Badge
                badgeContent={countActiveFilters()}
                color="secondary"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                }}
              />
            )}
          </Button>
        </div>

        <div className="col-lg-12 col-sm-12 mb-2 d-flex align-items-center">
          <Box>
            {chips.length > 0 && (
              <div
                className="d-flex justify-content-between mb-2"
                alignItems="center"
              >
                <div className="d-flex text-center align-items-center">
                  <h5 className="my-2 mx-2">Filtered by:</h5>{" "}
                  {chips.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      className="me-2"
                      color="default"
                      variant="filled"
                      sx={getChipStyle(chip)}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#FFF",
                            backgroundColor: "#EA7423",
                            borderRadius: "20px",
                            height: "17px",
                            width: "17px",
                          }}
                        />
                      }
                      onDelete={() => handleDeleteChip(chip)}
                    />
                  ))}
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={clearFilters}
                  size="small"
                  endIcon={
                    <ClearIcon
                      style={{
                        backgroundColor: "#EA7423",
                        color: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  }
                  sx={{
                    minWidth: "50px",
                    height: "35px",
                    borderRadius: "20px",
                    padding: 1,
                    textTransform: "capitalize",
                    color: "black",
                    borderColor: "#9C9C9C",
                  }}
                >
                  Clear All
                </Button>
              </div>
            )}
          </Box>
        </div>

        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={state.members}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            search={{
              placeholder: "Search transactions",
              searchFunction,
            }}
            actionOnClick={(row) => handleFamilyMembers(row)}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        </div>

        <CustomDrawer
          isOpenDrawer={state.drawerOpen}
          onOpenDrawer={handleFilter}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={"Filter Transaction"}
          componentToPassDown={
            <FilterTrnsaction
              setDrawerOpen={handleCloseDrawer}
              fetchData={fetchData}
              setState={setState}
              applyFilters={applyFilters}
              handleSelectOther={handleSelectOther}
              clearFilters={clearFilters}
              state={state}
              handleOnChange={handleFilterChange}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
            />
          }
        />

        <CustomConfirmation
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          title={"Are you sure you want to delete this Transaction?"}
          handleAgree={() => handleDeleteMember(deletingMemberId)}
        />
      </div>
    </div>
  );
};

export default Transactions;
