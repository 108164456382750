import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { deleteMemberApi } from "../../DAL/members";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import FilterSubscription from "../../components/subscriptionList/FilterSubscription";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import { subscriptionList } from "../../DAL/subscriptionList";
import { Button, Box, Badge } from "@mui/material";
import { project_mode } from "../../config/config";
import Label from "../../components/Label";

const SubscriptionList = () => {
  const [state, setState] = useState({
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    subscriptions: [],
    page: 0,
    rowsPerPage: 50,
    totalCount: 0,
    pageCount: 1,
    searchText: "",
    startDate: "",
    endDate: "",
    payment_mode: project_mode === "dev" ? "sandBox" : "live",
    sale_page: null,
    status: "",
    payment_plan: "",
    transaction_Status: "",
    subscription_status: "",
    school: null,
  });
  const countActiveFilters = () => {
    let count = 0;

    // if (state.transaction_Status.length > 0) count += 1;
    if (state.payment_mode) count += 1;
    if (state.school) count += 1;

    return count;
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingSubscriptionId, setDeletingSubscriptionId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [chips, setChips] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChangeRowsPerPage = (event) => {
    setIsLoading(true); // Set loading to true before fetching data
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const navigate = useNavigate();
  const fetchData = async (clearFilter = false) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const postData = {
      search: clearFilter ? "" : searchText,
      transaction_status: clearFilter ? [] : state.transaction_Status,
      payment_mode: clearFilter ? "" : state.payment_mode,
      sale_page: null,
      subscription_status: clearFilter ? "" : state.subscription_status,
    };

    if (!clearFilter && state.school) {
      postData.school = state.school._id;
    }

    const response = await subscriptionList(
      page,
      rowsPerPage,
      postData || searchText
    );

    if (response.code === 200) {
      let chips_array = [];
      if (!clearFilter) {
        if (state.school) {
          chips_array.push(state?.school?.school_name);
          setChips(chips_array);
        }
        if (state.payment_mode) {
          chips_array.push(state.payment_mode);
          setChips(chips_array);
        }
      }

      const subscriptions = response.subscriptions.map((subscription) => {
        let statusTab = "Active";
        if (subscription?.is_cancelled == true) {
          statusTab = "Cancelled";
        } else if (subscription?.cancelation_requested == true) {
          let date = moment(subscription.subscription_cancelled_date).format(
            "DD MMMM YYYY"
          );
          statusTab = "Cancels at: " + date;
        } else if (subscription?.is_on_trial == true) {
          let date = moment(subscription?.trial_ending_date).format(
            "DD MMMM YYYY"
          );
          statusTab = "Trial Ends at: " + date;
        }
        return {
          ...subscription,
          title:
            subscription.member_info?.name +
              (subscription.member_info?.email
                ? ` (${subscription.member_info.email})`
                : "") || "N/A",
          date_of_birth: moment(subscription.last_action_date).format(
            "DD/MM/YYYY hh:mm"
          ),
          school: subscription?.school_name || "No School",
          payment_mode: subscription?.payment_mode || "",
          // subscription_status: subscription?.status,
          plan: subscription?.plan_info?.plan_title,
          subscription_status:
            subscription?.is_cancelled == true
              ? statusTab
              : subscription?.cancelation_requested == true
              ? statusTab
              : subscription?.is_on_trial === true
              ? statusTab
              : subscription.status === "active"
              ? "Active"
              : "Expired",
          concatenated_info: `${
            subscription.sale_page?.sale_page_title || ""
          } ${(subscription.plan_info?.plan_title && "|") || ""} ${
            subscription.plan_info?.plan_title || ""
          }`,
        };
      });
      setTotalCount(response.total_subscriptions);
      setTotalPages(Math.ceil(response.total_subscriptions / rowsPerPage) - 1);

      setState((prevState) => ({
        ...prevState,
        subscriptions: subscriptions,
        totalCount: response.total_subscriptions,

        loading: false,
      }));
      setChips(chips_array); // Update chips array
      setIsSubmit(false);
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteSubscription = async (subscriptionId) => {
    setDeleteLoading(true);
    const response = await deleteMemberApi(subscriptionId);

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setDeleteDialogOpen(false);
      fetchData();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setDeleteDialogOpen(false);
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isSubmit) {
      fetchData();
    }
  }, [
    state.subscription_status,
    state.payment_mode,
    state.transaction_Status,
    state.school,
  ]);

  const applyFilters = () => {
    setIsSubmit(true);
    fetchData();
    handleCloseDrawer();
  };
  const handleDeleteChip = (chip) => {
    setIsSubmit(true);
    let updatedState = { ...state };

    // Remove chip based on its type
    if (chip === state.startDate) {
      updatedState.startDate = "";
    } else if (chip === state.endDate) {
      updatedState.endDate = "";
    } else if (chip === state.transaction_Status) {
      updatedState.transaction_Status = "";
    } else if (chip === state.payment_mode) {
      updatedState.payment_mode = "";
    } else if (chip === state.school?.school_name) {
      updatedState.school = null;
    }

    const updatedChips = chips.filter((c) => c !== chip);
    setChips(updatedChips);

    const newFilterCount = countActiveFilters(updatedState);
    setState((prevState) => ({
      ...prevState,
      ...updatedState,
      filterCount: newFilterCount,
    }));

    // fetchData();
  };

  const clearFilters = () => {
    setState((prevState) => ({
      ...prevState,
      searchText: "",
      startDate: "",
      endDate: "",
      sale_page: null,
      status: "",
      payment_mode: "",
      payment_plan: "",
      page: 0,
      subscription_status: null,
      school: null,
    }));
    setChips([]);
    fetchData(true);
    handleCloseDrawer();
  };
  const getChipStyle = (chip) => {
    switch (chip) {
      case "Success":
      case "SandBox":
        return {
          "& .MuiChip-deleteIcon": {
            color: "#EA7423",
          },
        };
      default:
        return {};
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  const handleEdit = (row) => {
    navigate(`/members/edit-members`, {
      state: { member: row },
    });
  };

  const handleDelete = (row) => {
    setDeletingSubscriptionId(row._id);
    setDeleteDialogOpen(true);
  };

  const handleFilter = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };

  const handleCloseDrawer = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  };

  const handleSelectOther = (name, value) => {
    setState((values) => ({ ...values, [name]: value }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setState((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Member" },
    { id: "concatenated_info", label: "Product" },
    { id: "school", label: "School" },
    { id: "payment_mode", label: "Payment Mode" },
    {
      id: "subscription_status",
      label: "Subscription Status",
      renderData: (row) => {
        let className = "pending-ticket";
        return (
          <div style={{ display: "flex" }}>
            <Label
              variant="ghost"
              color={
                row.subscription_status.includes("Cancels") ||
                row.subscription_status.includes("Cancelled")
                  ? "warning"
                  : row.subscription_status.includes("Trial")
                  ? "info"
                  : row.subscription_status === "Expired"
                  ? "error"
                  : "success"
              }>
              {row.subscription_status.includes("Cancels") && (
                <AccessTimeIcon
                  style={{ marginRight: "5px", width: "18px", height: "18px" }}
                />
              )}

              {row.subscription_status}
            </Label>
          </div>
        );
      },
    },
    { id: "date_of_birth", label: "Created" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDelete,
    },
  ];

  if (state.loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Subscription List</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <Button
            sx={{
              color: "#fff",
              boxShadow: "none",
              textTransform: "capitalize",
              height: "42px",
            }}
            variant="contained"
            startIcon={<FilterListIcon style={{ color: "white" }} />}
            onClick={() =>
              setState((prevState) => ({ ...prevState, drawerOpen: true }))
            }
            style={{ position: "relative", paddingRight: "30px" }}>
            Filter
            {countActiveFilters() > 0 && (
              <Badge
                badgeContent={countActiveFilters()}
                color="secondary"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                }}
              />
            )}
          </Button>
        </div>
        <div className="col-lg-12 col-sm-12 mb-2 d-flex align-items-center">
          <Box>
            {chips.length > 0 && (
              <div
                className="d-flex justify-content-between mb-2"
                alignItems="center">
                <div className="d-flex text-center align-items-center">
                  <h5 className="my-2 mx-2">Filtered by:</h5>
                  {chips.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      className="me-2"
                      color="default"
                      variant="filled"
                      sx={getChipStyle(chip)}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#FFF",
                            backgroundColor: "#EA7423",
                            borderRadius: "20px",
                            height: "17px",
                            width: "17px",
                          }}
                        />
                      }
                      onDelete={() => handleDeleteChip(chip)}
                    />
                  ))}
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={clearFilters}
                  size="small"
                  endIcon={
                    <ClearIcon
                      style={{
                        backgroundColor: "#EA7423",
                        color: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  }
                  sx={{
                    minWidth: "50px",
                    height: "35px",
                    borderRadius: "20px",
                    padding: 1,
                    textTransform: "capitalize",
                    color: "black",
                    borderColor: "#9C9C9C",
                  }}>
                  Clear All
                </Button>
              </div>
            )}
          </Box>
        </div>

        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={state.subscriptions}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        </div>
      </div>

      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this subscription?"}
        handleAgree={() => handleDeleteSubscription(deletingSubscriptionId)}
        loadingAgree={deleteLoading}
      />

      <CustomDrawer
        isOpenDrawer={state.drawerOpen}
        onOpenDrawer={handleFilter}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={"Filter Subscription"}
        componentToPassDown={
          <FilterSubscription
            setDrawerOpen={handleCloseDrawer}
            fetchData={fetchData}
            setState={setState}
            applyFilters={applyFilters}
            handleSelectOther={handleSelectOther}
            clearFilters={clearFilters}
            state={state}
            handleOnChange={handleFilterChange}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
          />
        }
      />
    </div>
  );
};

export default SubscriptionList;
