import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import {
  deleteSchoolTransactions,
  deleteTransaction,
  TransactionListApi,
} from "../../DAL/transaction";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import FilterTransaction from "../../components/transactions/FilterTransaction";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import FilterSubscription from "../../components/subscriptionList/FilterSubscription";
import FilterTrnsaction from "../../components/transactions/FilterTransaction";
import { convertCurrencyToSign } from "../../utils/constant";
import { schoolTransactionsList } from "../../DAL/Payments/Transactions/Transactions";

const EMPTY_FILTER = {
  selectedRow: null,
  loading: false,
  confirmDeleteOpenMember: false,
  drawerOpen: false,
  selectedCategory: null,
  members: [],
  page: 0,
  rowsPerPage: 50,
  totalCount: 0,
  pageCount: 1,
  searchText: "",
  transaction_Status: [],
  startDate: "",
  endDate: "",
  transaction_mode: "",
  sale_page: null,
  status: true,
  school: "",
};

const SchoolTransactions = () => {
  const [state, setState] = useState(EMPTY_FILTER);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingMemberId, setDeletingMemberId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [chips, setChips] = useState(["SandBox", "Success"]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const fetchData = async (clearFilter) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const handleChangePages = (event, newPage) => {
      if (newPage <= 0) {
        setPage(0);
        setPageCount(1);
      } else {
        setPage(newPage - 1);
        setPageCount(newPage);
      }
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      if (newPage <= 0) {
        setPageCount(1);
      } else {
        setPageCount(newPage + 1);
      }
    };

    const postData = {
      search: searchText,
      // start_date: clearFilter ? "" : state.startDate,
      // end_date: clearFilter ? "" : state.endDate,
      // transaction_status: clearFilter ? [] : state.transaction_Status,
      // transaction_mode: clearFilter ? "" : state.transaction_mode,
    };

    if (!clearFilter && state.sale_page) {
      postData.sale_page = state.sale_page._id;
    }

    const response = await schoolTransactionsList(
      state.page,
      state.rowsPerPage,
      postData
    );

    if (response.code === 200) {
      const transactions = response.school_transaction.map(
        (transaction, index) => {
          const total_amount = transaction?.amount
            ? `${transaction.amount} ${convertCurrencyToSign(
                transaction.currency
              )}`
            : "N/A";

          return {
            id: transaction._id,
            name: transaction.title,
            transaction_date:
              moment(transaction.transaction_date).format(
                "DD-MM-YYYY hh:mm A"
              ) || "N/A",
            transaction_type: transaction.transaction_note,
            status: "Success",
            payment_mode: "N/A",
            total_amount: "£ " + total_amount,
            plan_name: "N/A",
            school: transaction.school_name || "N/A",
          };
          // setTotalCount(response.total_members);
          // setTotalPages(Math.ceil(response.total_members / rowsPerPage) - 1);
        }
      );

      setState((prevState) => ({
        ...prevState,
        members: transactions,
        totalCount: response.total_transaction,
        pageCount:
          Math.ceil(response.total_transaction / state.rowsPerPage) - 1,
        loading: false,
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
      console.error(
        "Error fetching data:",
        response?.message || "Unknown error"
      );
    }
  };

  const clearFilters = () => {
    setState((prevState) => ({
      ...prevState,
      searchText: "",
      startDate: "",
      endDate: "",
      sale_page: null,
      status: "",
      transaction_mode: "",
      page: 0,
    }));
    fetchData(true);
    handleCloseDrawer();
  };
  const applyFilters = () => {
    fetchData();
    handleCloseDrawer();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setState((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectOther = (name, value) => {
    setState((values) => ({ ...values, [name]: value }));
  };

  const handleDeleteMember = async (memberId) => {
    setDeleteLoading(true);
    const response = await deleteSchoolTransactions(memberId);

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setDeleteDialogOpen(false);
      fetchData();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setDeleteDialogOpen(false);
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [state.page, state.rowsPerPage, state.searchText, state.selectedCategory]);

  const handleChangeRowsPerPage = (event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, page: 0 }));
    fetchData();
  };

  const handleExportCsv = () => {
    navigate("/school-transactions/add-school-transaction");
  };

  const handleDelete = (row) => {
    setDeletingMemberId(row.id);
    setDeleteDialogOpen(true);
  };

  const handleFamilyMembers = (row) => {
    navigate(`/members/family-member-operation/${row.user_id}`);
  };

  const handleFilter = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };

  const handleCloseDrawer = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  };

  const handleDeleteChip = () => {};

  const handleClearAll = () => {
    setChips([]);

    setState((prevState) => ({
      ...prevState,
      searchText: "",
      page: 0,
    }));

    fetchData();
  };

  const getChipStyle = (chip) => {
    switch (chip) {
      case "Success":
      case "SandBox":
        return {
          "& .MuiChip-deleteIcon": {
            color: "#EA7423",
          },
        };
      default:
        return {};
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    // { id: "name", label: "Member" },
    { id: "school", label: "School Name" },

    { id: "transaction_date", label: "Transaction Date" },

    // { id: "transaction_type", label: "Type" },
    // { id: "status", label: "Status" },
    // { id: "payment_mode", label: "Payment Mode" },
    { id: "total_amount", label: " Amount" },
    // { id: "paid_amount", label: "Amount Paid" },
    // { id: "plan_name", label: "Plan Name" },
    // { id: "sale_page_title", label: "Sale Page Title" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDelete,
    },
  ];

  if (state.loading) {
    return <PageLoading />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>School Transaction</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <button
            className="theme-button-contained mx-2"
            onClick={handleExportCsv}
          >
            Add Transaction
          </button>
          {/* <button className="theme-button-contained" onClick={handleFilter}>
            <FilterListIcon sx={{ mr: 1 }} />
            Filter
          </button> */}
        </div>

        {/* <div className="col-lg-12 col-sm-12 mb-2 d-flex align-items-center">
       
          <b className="me-3 pt-1 ms-2">Filtered by:</b>
          {chips.map((chip) => (
            <Chip
              key={chip}
              label={chip}
              onDelete={handleDeleteChip}
              className="me-2"
              color="default"
              variant="filled"
              sx={getChipStyle(chip)}
            />
          ))}
          <Chip
            label="Clear All"
            onClick={handleClearAll}
            icon={<ClearIcon style={{ color: "#EA7423" }} />}
            className="me-2"
            color="default"
            variant="outlined"
          />
        </div> */}

        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={state.members}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: state.totalCount,
              rows_per_page: state.rowsPerPage,
              page: state.page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            actionOnClick={(row) => handleFamilyMembers(row)}
          />
        </div>

        <CustomDrawer
          isOpenDrawer={state.drawerOpen}
          onOpenDrawer={handleFilter}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={"Filter Transaction"}
          componentToPassDown={
            <FilterTrnsaction
              setDrawerOpen={handleCloseDrawer}
              fetchData={fetchData}
              setState={setState}
              applyFilters={applyFilters}
              handleSelectOther={handleSelectOther}
              clearFilters={clearFilters}
              state={state}
              handleOnChange={handleFilterChange}
            />
          }
        />

        <CustomConfirmation
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          title={"Are you sure you want to delete this Transaction?"}
          handleAgree={() => handleDeleteMember(deletingMemberId)}
        />
      </div>
    </div>
  );
};

export default SchoolTransactions;
