import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import AddOrUPdateFamilyDrawer from "../../components/familyMembers/AddorUpdateFamilydrawer";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import moment from "moment";
import { deletefamilyMembers, familyMembers } from "../../DAL/familyMembers";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import { enqueueSnackbar } from "notistack";

const FamilyMembers = () => {
  const INITIAL_VALUES = {
    selectedRow: null,
    dialogOpen: false,
    loading: true,
    confirmDeleteOpen: false,
    isDrawerOpen: false,
    members: [],
  };

  const [familyMemberState, setFamilyMemberState] = useState(INITIAL_VALUES);
  const [membersList, setMembersList] = useState([]);
  const [familyMemberId, setFamilyMemberId] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchQuerry, setSearcQuerry] = useState(" ");
  const [isLoading, setIsLoading] = useState(true);
  const { member_user_id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFamilyMemberState((old) => ({ ...old, loading: false }));
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    let body = {
      search: searchText,
      member_user_id: member_user_id,
    };
    const response = await familyMembers(page, rowsPerPage, body);
    if (response.code === 200) {
      let members = response.members?.map((member) => {
        return {
          ...member,
          name_profile: {
            title: member.name,
            image: s3baseUri + member.profile_image?.thumbnail_1,
          },
          date_of_birth_iso: member.date_of_birth,
          date_of_birth: moment(member.date_of_birth).format(
            "DD/MM/YYYY hh:mm"
          ),
          relationship: member.family_relation_infomation?.relationship,
        };
      });
      setTotalCount(response.total_members);
      setTotalPages(Math.ceil(response.total_members / rowsPerPage) - 1);
      setMembersList([...members]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  const navigate = useNavigate();

  const handleAddMember = () => {
    setFamilyMemberId(null);
    setFamilyMemberState((old) => ({ ...old, isDrawerOpen: true }));
  };

  const handleOpenEditDrawer = (member) => {
    setSelectedMember(member);
    setFamilyMemberState((old) => ({ ...old, isDrawerOpen: true }));
  };

  const handleMenuClose = () => {
    setFamilyMemberState((old) => ({
      ...old,
      selectedRow: null,
      setOpenMenu: false,
    }));
  };

  const handleEdit = () => {
    navigate("/members/edit-members");
    handleMenuClose();
  };

  const handleDelete = () => {
    setFamilyMemberState((old) => ({ ...old, confirmDeleteOpen: true }));
    handleMenuClose();
  };

  const handleConfirmDelete = () => {
    handleDeleteMember(familyMemberId);
  };

  const handleCloseDeleteDialog = () => {
    setFamilyMemberState((old) => ({ ...old, confirmDeleteOpen: false }));
  };

  const handleCloseDrawer = () => {
    setFamilyMemberState((old) => ({ ...old, isDrawerOpen: false }));
    setSelectedMember(null);
  };

  const handleDeleteMember = async (memberId) => {
    const response = await deletefamilyMembers(memberId);
    if (response.code === 200) {
      // Refresh the members list
      enqueueSnackbar(response.message, {
        variant: "success",
      });

      setFamilyMemberState((old) => ({ ...old, confirmDeleteOpen: false }));
      fetchData();
    } else {
      setFamilyMemberState((old) => ({ ...old, confirmDeleteOpen: false }));
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "name_profile", label: "Name ", type: "title_with_img" },
    { id: "email", label: "Email " },

    { id: "relationship", label: "Relationship" },
    { id: "gender", label: "Gender" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: async (row, index) => {
        setSelectedMember(row);
        setFamilyMemberId(row.user_id._id);
        handleOpenEditDrawer(row);
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: (row, index) => {
        setFamilyMemberId(row.user_id._id);
        setFamilyMemberState((old) => ({ ...old, confirmDeleteOpen: true }));
      },
    },
  ];

  if (isLoading) return <PageLoading />;

  return (
    <>
      {familyMemberState.loading ? (
        <div className="contaner-fluid family-members"></div>
      ) : (
        <>
          <div className="container col-12 mt-3" xs={12} ml={2} mt={1}>
            <Link className="custom-link" to={"/members"}>
              Members
            </Link>{" "}
            {" / "} Family Members
          </div>
          <div className="container d-flex justify-content-between mb-2 mt-2">
            <h4>Family Members</h4>
            <div className="d-flex gap-2">
              <button
                className="theme-button-contained"
                onClick={handleAddMember}>
                Add Member
              </button>
            </div>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={membersList}
              pagePagination={true}
              MENU_OPTIONS={MENU_OPTIONS}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
          <CustomDrawer
            isOpenDrawer={familyMemberState.isDrawerOpen}
            onOpenDrawer={handleCloseDrawer}
            onCloseDrawer={handleCloseDrawer}
            pageTitle={`${familyMemberId ? "Update" : "Add"} Family Member`}
            componentToPassDown={
              <AddOrUPdateFamilyDrawer
                onCloseDrawer={handleCloseDrawer}
                selectedObject={selectedMember}
                member_user_id={member_user_id}
                family_member_user_id={familyMemberId}
                familyMembers={fetchData}
              />
            }
          />
          <CustomConfirmation
            open={familyMemberState.confirmDeleteOpen}
            setOpen={handleCloseDeleteDialog}
            title={"Are you sure you want to delete?"}
            handleAgree={handleConfirmDelete}
          />
        </>
      )}
    </>
  );
};

export default FamilyMembers;
