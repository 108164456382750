import React from "react";
import ReactEditor from "react-text-editor-kit";
import { s3baseUri } from "../../config/config";
import { s3ImageUpload } from "../../DAL/uploadImage";
import { enqueueSnackbar, useSnackbar } from "notistack";

function TextEditor({
  inputs,
  setInputs,
  name,
  is_array,
  index,
  value,
  className,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (value) => {
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };

  const editorValue = () => {
    let valueData = "";
    if (is_array && inputs[index][name]) {
      valueData = inputs[index][name];
    } else if (inputs[name]) {
      valueData = inputs[name];
    } else if (value) {
      valueData = value;
    }
    return valueData;
  };

  const handleImageUpload = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    formData.append("width", 600);

    const response = await s3ImageUpload(formData);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });

      return s3baseUri + response.image_path;
    } else {
      enqueueSnackbar(response.message, { variant: "error" });

      return "";
    }
  };

  const getEditorRef = (value) => {};

  return (
    <div className={`App ${className}`}>
      <ReactEditor
        className={`custom-editor ${className}`}
        value={editorValue()}
        getEditorRef={getEditorRef}
        onChange={handleChange}
        placeholder="Write your text here"
        style={{ textAlign: "start", height: "400px" }} // Adjust height here
        image_handler={handleImageUpload}
      />
    </div>
  );
}

export default TextEditor;
