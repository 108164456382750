import { invokeApi } from "../utils/invokeApi";
export const programmeListApi = async (data) => {
  const { page, rowsPerPage, postData, load_more } = data;
  const requestObj = {
    path: load_more
      ? `api${load_more}`
      : `api/program/list_program?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const addProgrammes = async (data) => {
  const requestObj = {
    path: `api/program/add_program`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const programmeDetailApi = async (program_slug) => {
  const requestObj = {
    path: `api/program/detail_program/${program_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editProgrammeApi = async (program_slug, data) => {
  const requestObj = {
    path: `api/program/update_program/${program_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programmeDeleteApi = async (program_slug) => {
  const requestObj = {
    path: `api/program/delete_program/${program_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ActiveProgrammeList = async () => {
  const requestObj = {
    path: `api/program/list_active_programs`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// *************************************Programme Module end ****************************//

// *************************************Manage Resources in programmes****************************//

export const manageResourcesApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/resources/list_program_resources?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// *************************************Manage Resources  end****************************//
