import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  addRecordingApi,
  updateRecordingApi,
  getActiveCategories,
  getRecordingDetail,
} from "../../DAL/recording";
import { s3baseUri } from "../../config/config";
import TextEditor from "../generalComponents/TextEditor";

const INITIAL_VALUES = {
  title: "",
  description: "",
  categories: [],
  status: true,
  image: null,
  imagePreview: null,
  videoURL: "",
};

export default function AddOrUpdateRecordingDrawer(props) {
  const { selectedObject, onCloseDrawer, refreshList } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [categories, setCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({
      ...old,
      [name]: Array.isArray(value) ? value : [value],
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputs((old) => ({
        ...old,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!selectedObject && !inputs.image) {
      enqueueSnackbar("Image is required", { variant: "error" });
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("detailed_description", inputs.description);
    formData.append("video_url", inputs.videoURL);
    formData.append("status", inputs.status);
    formData.append(
      "program_recording_categories",
      JSON.stringify(inputs.categories)
    );

    if (inputs.image) {
      formData.append("image", inputs.image);
    }

    let response;
    if (selectedObject) {
      response = await updateRecordingApi(
        selectedObject.recording_slug,
        formData
      );
    } else {
      response = await addRecordingApi(formData);
    }

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      refreshList();
      onCloseDrawer();
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
    setSubmitting(false);
  };

  const fetchCategories = async () => {
    const response = await getActiveCategories();
    if (response.code === 200) {
      setCategories(response.program_recording_category_list);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedObject) {
      const data = selectedObject;
      setInputs({
        title: data.title,
        description: data.detailed_description,
        categories: data.program_recording_category_info.map(
          (category) => category._id
        ),
        status: data.status,
        imagePreview: s3baseUri + data?.image?.thumbnail_1,
        videoURL: data.video_url,
      });
    }
  }, [selectedObject]);

  return (
    <form onSubmit={handleSubmit} className="drawer-form">
      <TextField
        required
        label="Title"
        variant="outlined"
        value={inputs.title}
        name="title"
        onChange={handleChange}
        fullWidth
      />
      {/* <TextField
        required
        label="Description"
        variant="outlined"
        multiline
        rows={4}
        value={inputs.description}
        name="description"
        onChange={handleChange}
        fullWidth
      /> */}
      <label className="mb-0">Description*</label>
      <TextEditor
        required
        inputs={inputs}
        setInputs={setInputs}
        onChange={handleChange}
        name="description"
      />
      <TextField
        required
        label="Video URL"
        variant="outlined"
        value={inputs.videoURL}
        name="videoURL"
        onChange={handleChange}
        fullWidth
      />

      <FormControl fullWidth required variant="outlined">
        <InputLabel id="categories-label">Categories</InputLabel>
        <Select
          required
          labelId="categories-label"
          label="Categories"
          multiple
          value={inputs.categories}
          name="categories"
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 200, // This limits the height of the dropdown list
                width: 50,
              },
            },
          }}
        >
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          required
          labelId="status-label"
          label="Status"
          value={inputs.status}
          name="status"
          onChange={handleChange}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>

      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div>
            {inputs.imagePreview && (
              <img
                src={inputs.imagePreview}
                alt="Preview"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            <p className="mb-0 mt-0">Upload image*</p>
          </div>
        </div>

        <label htmlFor="image-selection" className="theme-button-contained">
          <FileUpload />
          Upload
          <input
            type="file"
            id="image-selection"
            hidden
            onChange={handleImageChange}
          />
        </label>
      </div>
      <div className="mt-1 image-family-member">
        <p>Recommended image size (300x300) and file types (jpg, png)</p>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
