import React from "react";
import ReactEditor from "react-text-editor-kit";
import { s3ImageUpload } from "../../DAL/uploadImage";
import { s3baseUri } from "../../config/config";
import { useSnackbar } from "notistack";

function WebsiteTextEditor({
  inputs,
  setInputs,
  name,
  is_array,
  index,
  value,
  className,
  setTemplateFieldsData,
  templateFieldsData,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (value) => {
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };
  const handleChangeTemplate = (value) => {
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setTemplateFieldsData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };
  const handleImageUpload = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    formData.append("width", 600);

    const response = await s3ImageUpload(formData);
    if (response.code === 200) {
      return s3baseUri + response.image_path;
    } else {
      enqueueSnackbar(response.message, { variant: "error" });

      return "";
    }
  };
  const getEditorRef = (value) => {};

  return (
    <div className={`App ${className}`}>
      <ReactEditor
        className={`custom-editor ${className}`}
        getEditorRef={getEditorRef}
        value={
          templateFieldsData !== undefined
            ? templateFieldsData[name]
            : inputs.name
        }
        onChange={
          templateFieldsData !== undefined ? handleChangeTemplate : handleChange
        }
        placeholder="Write your text here"
        style={{ textAlign: "start", height: "400px" }}
        image_handler={handleImageUpload}
      />
    </div>
  );
}

export default WebsiteTextEditor;
