import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { pageUnpublish } from "../../DAL/webPages/webPages";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UnpublishSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    title: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data_obj = {
      unpublish_link: inputs.title,
    };
    let social_sharing = {
      unpublish_page_access_info: data_obj,
      sale_page_id: params?.id,
    };
    setIsLoading(true);
    const result = await pageUnpublish(social_sharing);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    if (state == null || state === "" || state === undefined) {
    } else {
      let unpublishData = state.unpublish_page_access_info;
      setInputs((inputs) => ({
        ...inputs,
        ["title"]: unpublishData?.unpublish_link,
      }));
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button d-flex"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12">
          <h2>Page Unpublish Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Enter Link"
              variant="outlined"
              fullWidth
              name="title"
              required
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="theme-button-contained">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
