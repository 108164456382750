import React, { useEffect, useState } from "react";
import {
  TextField,
  Divider,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { FileUpload } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import { addMembers } from "../../DAL/members";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { activeSchools } from "../../DAL/school";

const AddMembers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    image: "",
    lastName: "",
    shortDescription: "",
    email: "",
    repeatEmail: "",
    contactNumber: "",
    status: true,
    password: "",
    repeatPassword: "",
    dob: "",
    gender: "male",
    city: "",
    zipCode: "",
    memberShip: true,
    country: "",
    is_member_paid: true,
    membership_date: "",
    school: "no-school",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [schoolsstatea, setSchools] = useState([]);

  const [showMembershipDate, setShowMembershipDate] = useState(
    formData.is_member_paid
  );

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    let newValue = value;
    if (files) {
      newValue = files[0]; // Handle
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
    if (name === "is_member_paid") {
      setShowMembershipDate(value === "true");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const memberData = {
    //   ...formData,
    //   // school: formData.school === "no-school" ? null : formData.school, // Set to null if "No School"
    // };
    // if (formData.firstName) {
    //   if (formData.email == "") {
    //     enqueueSnackbar("Email is required.", { variant: "error" });
    //     return;
    //   }
    //   if (formData.email.trim() === "") {
    //     enqueueSnackbar("Email is required.", { variant: "error" });
    //     return;
    //   }
    //   if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //     enqueueSnackbar("Invalid email format.", { variant: "error" });
    //     return;
    //   }
    //   if (formData.repeatEmail.trim() === "") {
    //     enqueueSnackbar("Re-enter Email is required.", { variant: "error" });
    //     return;
    //   }
    //   if (!/\S+@\S+\.\S+/.test(formData.repeatEmail)) {
    //     enqueueSnackbar("Invalid email format in Re-enter Email.", {
    //       variant: "error",
    //     });
    //     return;
    //   }
    // }

    // if (formData.city.trim() === "") {
    //   enqueueSnackbar("City is required.", { variant: "error" });
    //   return;
    // }
    // if (formData.zipCode.trim() === "") {
    //   enqueueSnackbar("Zip Code is required.", { variant: "error" });
    //   return;
    // }
    // if (!/^\d+$/.test(formData.zipCode)) {
    //   enqueueSnackbar("Zip Code must contain only numbers.", {
    //     variant: "error",
    //   });
    //   return;
    // }
    if (!formData.image) {
      enqueueSnackbar("Image is required.", { variant: "error" });
      return;
    }

    if (formData.email != formData.repeatEmail) {
      enqueueSnackbar(
        "Your Emails don’t match. Please ensure both Email fields are same",
        {
          variant: "error",
        }
      );
      return;
    }
    if (formData.password != formData.repeatPassword) {
      enqueueSnackbar(
        "Your Passwords don’t match. Please ensure both Password fields are same",
        {
          variant: "error",
        }
      );
      return;
    }
    // if (formData.school === "" || formData.school === "no-school") {
    // }
    const memberData = {
      ...formData,
      // school: formData.school === "no-school" ? null : formData.school, // Set to null if "No School"
    };
    setLoading(true);

    const formDataObj = new FormData();

    formDataObj.append("name", formData.firstName);
    formDataObj.append("email", formData.email);
    formDataObj.append("contact_number", formData.contactNumber);
    formDataObj.append("date_of_birth", formData.dob);
    formDataObj.append("gender", formData.gender);
    formDataObj.append("city", formData.city);
    formDataObj.append("zipcode", formData.zipCode);
    formDataObj.append("is_member_paid", formData.is_member_paid);
    formDataObj.append("password", formData.password);
    if (formData.is_member_paid) {
      formDataObj.append("membership_date", formData.membership_date);
    }
    formDataObj.append("status", formData.status);
    // formDataObj.append("school", formData.school);
    if (formData.school !== "no-school") {
      formDataObj.append("school", memberData.school); // Ensure this is null or valid ObjectId
    }

    formDataObj.append("profile_image", formData.image);
    formDataObj.append("country", formData.country);
    formDataObj.append("biography", formData.shortDescription);

    const response = await addMembers(formDataObj);
    if (response.code === 200) {
      navigate("/members");
      enqueueSnackbar("Member added successfully.", { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };
  const fetchSchools = async () => {
    const response = await activeSchools();
    if (response.code === 200) {
      setSchools(response.schools); // Update to use the correct structure
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchSchools();
  }, []);

  const renderImagePreview = (file) => {
    if (!file) return null;
    const url = URL.createObjectURL(file);
    return (
      <img
        src={url}
        alt="Preview"
        style={{ maxWidth: "100px", maxHeight: "100px" }}
      />
    );
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name.includes("Icon")) {
      setSettings({ ...settings, [name]: files[0] });
    } else {
      setSettings({ ...settings, [name]: value });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="container-fluid">
      <div className="col-md-6 d-flex mb-2 mt-2 " style={{ marginLeft: "5px" }}>
        <Link to="/members">
          <ArrowBackIcon />
        </Link>
        <h4 className="container">Add Member</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          className="container admin-defaukt-settings-div"
          container
          spacing={2}>
          {/* Existing fields */}
          <div className="div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                label="Name"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                type="email"
                label="Email"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>{" "}
          <div className="div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                type="email"
                label="Re-enter Email"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="repeatEmail"
                value={formData.repeatEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <TextField
                fullWidth
                type="number"
                label="Contact Number"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                select
                label="Status"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="status"
                value={formData.status}
                onChange={handleInputChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                type="date"
                label="Date of Birth"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="div-gapmembers">
            {/* Gender Field */}
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                select
                label="Gender"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                select
                label="Paid"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="is_member_paid"
                value={formData.is_member_paid}
                onChange={handleInputChange}>
                <MenuItem value={true}>Unlock</MenuItem>
                <MenuItem value={false}>Lock</MenuItem>
              </TextField>
            </div>
          </div>
          <div className="col-12 d-flex div-gapmembers">
            <div className="col-md-6">
              <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  name="password"
                  label="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { height: 23, borderRadius: 12 } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2 },
                  }}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="password">Re-enter Password</InputLabel>
                <OutlinedInput
                  id="repeatPassword"
                  type={showRepeatPassword ? "text" : "password"}
                  value={formData.repeatPassword}
                  onChange={handleInputChange}
                  name="repeatPassword"
                  label="Re-enter Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showRepeatPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { height: 23, borderRadius: 12 } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2 },
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="col-12 d-flex div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                type="date"
                label="Membership Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="membership_date"
                value={formData.membership_date}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* Address Details Section */}
          <div className="col-lg-12">
            <h5>Address Details</h5>
          </div>
          <div className="div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                required
                label="City"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-6">
              <TextField
                fullWidth
                type="number" // Set the input type to 'number'
                inputProps={{ min: 1 }} // Set minimum value allowed (optional)
                required
                label="Zip Code"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="d-flex div-gapmembers">
            <div className="col-md-6">
              <TextField
                fullWidth
                inputProps={{ min: 1 }}
                required
                label="Country"
                variant="outlined"
                InputProps={{ style: { height: 56, borderRadius: 8 } }}
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <FormControl fullWidth variant="outlined">
                <TextField
                  select
                  label="School"
                  name="school"
                  required
                  value={formData.school}
                  onChange={handleInputChange}
                  InputProps={{ style: { height: 56, borderRadius: 8 } }}
                  id="school">
                  <MenuItem value="no-school">No School</MenuItem>

                  {schoolsstatea.map((school) => (
                    <MenuItem key={school.id} value={school._id}>
                      {school.school_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            {" "}
            <Divider />{" "}
          </div>
          <div className="col-12 d-flex mt-4" style={{ alignItems: "center" }}>
            <div className="col-4 m-0">
              <p>Upload image*</p>
              <p className="image-guide-members m-0">
                {" "}
                Recommended image size (300x300) and file types (jpg,png){" "}
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              {renderImagePreview(formData.image)}
            </div>
            <div className="d-flex justify-content-end col-4">
              <label className="theme-button-contained" htmlFor="image-upload">
                <FileUpload />
                Upload
              </label>
              <input
                id="image-upload"
                type="file"
                hidden
                name="image"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            {" "}
            <Divider />{" "}
          </div>
          <div className="col-12">
            <TextField
              fullWidth
              multiline
              rows={8}
              label="Short Description"
              variant="outlined"
              InputProps={{ style: { borderRadius: 8, height: 200 } }}
              name="shortDescription"
              value={formData.shortDescription}
              onChange={handleInputChange}
            />
            <p className="add-member-p mt-1">
              Programme short description (Maximum limit 300 characters)
            </p>
          </div>
          <FormControl fullWidth required variant="outlined">
            {/* <Select
            required
            labelId="categories-label"
            label="Schools"
            multiple
            value={formData.school}
            name="school"
            onChange={handleInputChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 200, // This limits the height of the dropdown list
                  width: 50,
                },
              },
            }}
          >
            {schools.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.title}
              </MenuItem>
            ))}
          </Select> */}
          </FormControl>
          <div className="col-12">
            <Divider />
          </div>
          {/* Submit Button */}
          <div className="col-12 d-flex justify-content-end">
            <button className="theme-button-contained ">
              {loading ? <CircularProgress size={15} /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddMembers;
