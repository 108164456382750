import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { updateManageRecordinCatgApi } from "../../DAL/recordingCategories";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

const INITIAL_VALUES = {
  title: "",
  description: "",
  programme: [],
  status: true,
  image: null,
  imagePreview: null,
  dob: "",
  contact: "",
  email: "",
  zipcode: "",
  gender: true,
  recording: "",
  order: "",
};

export default function AddOrUpdateManageCatogeryDrawer(props) {
  const { selectedObject, onCloseDrawer, fetchCategories, programId } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [orderError, setOrderError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
    if (name === "order" && value <= 0) {
      setOrderError("Order must be greater than 0");
    } else {
      setOrderError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.order <= 0) {
      setOrderError("Order must be greater than 0");
      return;
    }
    setLoading(true);

    const response = await updateManageRecordinCatgApi({
      program_id: programId,
      program_recording_category_id: selectedObject._id,
      order: inputs.order,
    });

    if (response.code === 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });

      onCloseDrawer();
      fetchCategories();
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
    setLoading(false);

    const postData = {
      title: inputs.title,
      description: inputs.description,
      programme: inputs.programme,
      status: inputs.status,
      image: inputs.image,
    };
  };

  useEffect(() => {
    if (selectedObject) {
      setInputs({
        ...selectedObject,
        status: Boolean(selectedObject.status),
      });
    }
  }, [selectedObject]);

  return (
    <form onSubmit={handleSubmit} className="drawer-form">
      <TextField
        required
        type="number"
        label="Order"
        variant="outlined"
        value={inputs.order}
        name="order"
        onChange={handleChange}
        fullWidth
        error={Boolean(orderError)}
        helperText={orderError}
      />
      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          variant="contained"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
