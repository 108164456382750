import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";

const VerificationInput = ({ onInputFilled }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (otp.join("").length === 6) {
      onInputFilled(otp.join(""));
    }
  }, [otp, onInputFilled]);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="d-flex justify-content-center">
      {otp.map((value, index) => (
        <div key={index} className="p-1">
          <TextField
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            margin="dense"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeyPress(e, index)}
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: "center",
                width: 23,
                borderColor: "grey",
                height: "12px",
                marginLeft: "2px",
              },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              onFocus: (e) => {
                e.target.style.borderColor = "gold"; // Change border color on focus
              },
              onBlur: (e) => {
                e.target.style.borderColor = "grey"; // Reset border color on blur
              },
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default VerificationInput;
