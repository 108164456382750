import { invokeApi } from "../utils/invokeApi";

export const TransactionListApi = async (page, limmit, data) => {
  const requestObj = {
    path: `api/admin/all_transaction_list?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTransaction = async (member_user_id) => {
  const requestObj = {
    path: `api/admin/delete_transaction/${member_user_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteSchoolTransactions = async (member_user_id) => {
  const requestObj = {
    path: `api/school_transaction/delete_transaction/${member_user_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
