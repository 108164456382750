import React, { useState, useEffect } from "react";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import AddorUpdateSchools from "../../components/schools/addorupdateschool";
import { useSnackbar } from "notistack";
import { deleteSchoolApi, schoolMemberList } from "../../DAL/school";
import PageLoading from "../../components/generalComponents/PageLoading";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { convertCurrencyToSign } from "../../utils/constant";

const SchoolMembers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [totalPages, setTotalPages] = useState(0);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Name" },
    { id: "school", label: "School" },
    { id: "transactionDate", label: "Transaction Date" },
    { id: "transaction", label: "Product" },
    { id: "paidAmount", label: "Amout" },
  ];

  const INITIAL_VALUES = {
    deleteSchoolId: null,
    deleteDialogOpen: false,
    drawerOpen: false,
    selectedSchool: null,
  };

  const params = useParams();
  const [schoolListState, setSchoolListState] = useState(INITIAL_VALUES);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchSchools = async (page, rowsPerPage) => {
    let postData = {
      school: params.id,
      search: searchText,
    };

    setLoading(true);
    const response = await schoolMemberList(page, rowsPerPage, postData);

    if (response.code === 200) {
      const schoolsWithDetails = response.members.map((school, index) => {
        const transactionInfo = school?.transaction_info || {};
        const salePageTitle =
          transactionInfo?.sale_page?.sale_page_title || "N/A";
        const planTitle = transactionInfo?.plan?.plan_title || null;
        const transactionTitle = planTitle
          ? `${salePageTitle} | ${planTitle}`
          : salePageTitle;
        const currency = transactionInfo?.currency || "N/A";
        const paidAmount = transactionInfo?.paid_amount
          ? `${transactionInfo.paid_amount} ${convertCurrencyToSign(currency)}`
          : "N/A";

        return {
          ...school,
          number: (page - 1) * rowsPerPage + index + 1,
          title: school.name,
          school: school?.school_info?.school_name,
          transaction: transactionTitle,
          transactionDate: school?.transaction_info?.transaction_date
            ? new Date(school.transaction_info.transaction_date).toLocaleString(
                "en-US",
                {
                  dateStyle: "medium",
                  timeStyle: "short",
                  hour12: true,
                }
              )
            : "N/A",
          paid: school?.transaction_info?.currency,
          paidAmount: school?.transaction_info
            ? `${school?.transaction_info?.paid_amount ?? "N/A"} ${
                school?.transaction_info?.currency
                  ? convertCurrencyToSign(school.transaction_info.currency)
                  : "N/A"
              }`
            : "N/A",
        };
      });

      setTotalCount(response.total_members);
      setTotalPages(Math.ceil(response.total_members / rowsPerPage) - 1);
      setSchools(schoolsWithDetails);
    } else {
      enqueueSnackbar("Failed to fetch members", { variant: "error" });
    }
    setLoading(false);
  };
  //

  useEffect(() => {
    fetchSchools(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleMembers = (row) => {
    navigate(`/schoolmembers/${params.id}/transactions/${row._id}`);
  };

  const handleDeleteSchool = async () => {
    const response = await deleteSchoolApi(schoolListState.deleteSchoolId);
    if (response.code === 200) {
      setSchools((oldSchools) =>
        oldSchools.filter(
          (school) => school._id !== schoolListState.deleteSchoolId
        )
      );
      enqueueSnackbar("School deleted successfully", { variant: "success" });
    } else {
      enqueueSnackbar("Failed to delete school", { variant: "error" });
    }
    setSchoolListState((old) => ({
      ...old,
      deleteDialogOpen: false,
      deleteSchoolId: null,
    }));
    setDeleteDialogOpen(false);
  };

  const handleCloseDrawer = () => {
    setSchoolListState((old) => ({ ...old, drawerOpen: false }));
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchSchools();
  };

  const MENU_OPTIONS = [
    {
      label: "Transactions",
      icon: "radix-icons:font-family",
      handleClick: handleMembers,
    },
  ];

  return (
    <>
      <div className="row mb-3 mt-3 schoolList">
        <div className="col-lg-6 col-md-6 d-flex align-items-center">
          <Link to="/schoolList" className="me-2">
            <ArrowBackIcon />
          </Link>
          <h4 className="mb-0">School Members</h4>
        </div>
      </div>
      <div className="col-12">
        {loading ? (
          <PageLoading />
        ) : (
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={schools}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            pageCount={pageCount}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        )}
      </div>
      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this Member?"}
        handleAgree={handleDeleteSchool}
      />

      <CustomDrawer
        isOpenDrawer={schoolListState.drawerOpen}
        onOpenDrawer={handleCloseDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${
          schoolListState.selectedSchool ? "Update" : "Add"
        } Member`}
        componentToPassDown={
          <AddorUpdateSchools
            onCloseDrawer={handleCloseDrawer}
            selectedObject={schoolListState.selectedSchool}
            refreshList={fetchSchools}
          />
        }
      />
    </>
  );
};

export default SchoolMembers;
