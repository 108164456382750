import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Link } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomPopoverSection from "../../../components/customMUITable/CustomPopoverSection";
import CustomConfirmation from "../../../components/generalComponents/CustomConfirmation";
import ReactPlayer from "react-player";

import { s3baseUri } from "../../../config/config";
import {
  programmeDeleteApi,
  programmeDetailApi,
} from "../../../DAL/programmes";
import PageLoading from "../../../components/generalComponents/PageLoading";

const DetailPageProgrammeCard = () => {
  const [programDetails, setProgramDetails] = useState({
    title: "",
    image: "",
    description: "",
    openDialog: false,
    video_url: "",
  });
  const [deleteProgramme, setDeleteProgramme] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataList, setDataList] = useState({});
  const [loading, setLoading] = useState(true);

  const { program_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProgramDetails = async () => {
      const response = await programmeDetailApi(program_slug);
      if (response.code === 200) {
        setProgramDetails(response.program);
        setDataList(response.program);
      } else {
        enqueueSnackbar("Failed to fetch program details", {
          variant: "error",
        });
      }

      setLoading(false);
    };

    fetchProgramDetails();
  }, [program_slug, enqueueSnackbar]);

  const handleDelete = async () => {
    const response = await programmeDeleteApi(program_slug);
    if (response.code === 200) {
      enqueueSnackbar("Program deleted successfully", { variant: "success" });
      navigate("/programmes");
    } else {
      enqueueSnackbar("Failed to delete program", { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick() {
        navigate(`/programmes/edit-programme`, {
          state: { program: dataList },
        });
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick() {
        setDeleteProgramme(dataList.program_slug);
        setDeleteDialogOpen(true);
      },
    },
    {
      label: "Manage Resources",
      icon: "grommet-icons:resources",
      handleClick() {
        navigate(`/programmes/manage-resource/${dataList._id}`, {
          state: { program: dataList },
        });
      },
    },
    {
      label: "Manage Recording category",
      icon: "bxs:category-alt",
      handleClick() {
        navigate(`/programmes/manage-recording-categories/${dataList._id}`, {
          state: { program: dataList },
        });
      },
    },
  ];

  if (loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <>
        <div className="row mt-1">
          <div className="col-lg-12">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ textDecoration: "none" }}
                className="custom-link"
                component={RouterLink}
                to="/programmes"
              >
                Programmes
              </Link>
              <p className="p-0 m-0">{programDetails.title}</p>
            </Breadcrumbs>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center program-detail-card mt-2 mb-1">
          <div className="detail-card-item">
            <h6>{programDetails.title}</h6>
          </div>

          <div style={{ marginRight: "25px" }}>
            <CustomPopoverSection menu={MENU_OPTIONS} />
          </div>
        </div>

        <div className="detail-card-img">
          {programDetails.video_url ? (
            <div className="video-player-wrapper">
              <ReactPlayer
                className="react-player"
                url={programDetails.video_url}
                style={{ width: "100%", height: "100%" }}
                width="100%"
                height="90vh"
                controls
              />
            </div>
          ) : (
            <img
              className="detail-card-img"
              src={s3baseUri + programDetails.main_image?.thumbnail_1}
              alt={programDetails.title}
            />
          )}
        </div>

        <div className="space-section col-12"></div>
        <p
          dangerouslySetInnerHTML={{
            __html: programDetails.detailed_description,
          }}
        ></p>

        <CustomConfirmation
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          title={"Are you sure you want to delete this programme?"}
          handleAgree={handleDelete}
        />
      </>
    </div>
  );
};

export default DetailPageProgrammeCard;
