import React, { useState } from "react";
import {
  TextField,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextEditor from "../../../components/generalComponents/TextEditor";
import { addProgrammes } from "../../../DAL/programmes";
const INITIAL_VALUES = {
  title: "",
  email: "",
  short_description: "",
  detailed_description: "",
  mainImageFile: "",
  mainImagePreview: "",
  video_url: "",
  status: true,
  is_recommended: false,
};

const AddProgrammes = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    let updated = { [name]: value };
    if (name === "mainImageFile") {
      updated = {
        [name]: files[0],
        mainImagePreview: URL.createObjectURL(files[0]),
      };
    }
    setInputs((old) => ({ ...old, ...updated }));
  };

  const handleAddProgramme = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!inputs.mainImageFile) {
      enqueueSnackbar("Please upload an image.", { variant: "error" });
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("video_url", inputs.video_url);
    formData.append("status", inputs.status);
    formData.append("is_recommended", inputs.is_recommended);
    formData.append("main_image", inputs.mainImageFile);

    const result = await addProgrammes(formData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/programmes");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleAddProgramme}>
        <div className="col-12">
          <Link to={"/programmes"}>
            <ArrowBackIcon />
          </Link>
        </div>
        <div className="col-12 mt-3">
          <h4>Add Programme</h4>
        </div>

        <div className="col-md-6 mt-3">
          <TextField
            fullWidth
            required
            label="Title"
            name="title"
            variant="outlined"
            value={inputs.title}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-md-6 mt-3">
          <TextField
            fullWidth
            name="video_url"
            label="Video URL"
            variant="outlined"
            value={inputs.video_url}
            onChange={(e) => handleChange(e)}
            type="url"
          />
        </div>
        <div className="col-md-6 mt-3">
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              required
              value={inputs.status}
              onChange={(e) => handleChange(e)}
              label="Status"
              variant="outlined"
              name="status">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-6 mt-3">
          <FormControl fullWidth>
            <InputLabel>Is Recommended</InputLabel>
            <Select
              value={inputs.is_recommended}
              onChange={(e) => handleChange(e)}
              label="Is Recommended"
              variant="outlined"
              name="is_recommended">
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-start mt-3">
          <div className="col-md-4">
            <p className="m-0"> Programme Image*</p>
            <p className="add-programme-p mt-1">
              Recommended image size (1000x670) and file types (jpg, png)
            </p>
          </div>
          <div className="col-md-4 text-center">
            {inputs.mainImagePreview && (
              <img
                className="mb-3 ml-3"
                src={inputs.mainImagePreview}
                alt="Preview"
                style={{
                  maxWidth: "30%",
                  marginTop: 15,
                  borderRadius: 8,
                  marginRight: "30px",
                }}
              />
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="my-3 d-flex align-items-center">
              <label
                className="theme-button-contained"
                htmlFor="main-image-upload">
                <FileUpload />
                Upload
              </label>
              <input
                id="main-image-upload"
                type="file"
                hidden
                accept="image/*"
                name="mainImageFile"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <Divider />
        </div>
        <div className="col-12 mt-4">
          <TextField
            required
            fullWidth
            multiline
            rows={5}
            label="Short Description"
            variant="outlined"
            name="short_description"
            value={inputs.short_description}
            onChange={(e) => handleChange(e)}
          />
          <p className="add-member-p mt-1">
            Member Biography (Maximum limit 300 characters)
          </p>
        </div>
        <div className="col-12 mt-4">
          <label htmlFor="">Long Description</label>
          <TextEditor
            required
            inputs={inputs}
            setInputs={setInputs}
            name="detailed_description"
          />
        </div>
        <div className="col-12 d-flex justify-content-end mt-4">
          <button className="theme-button-contained" disabled={loading}>
            {loading ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProgrammes;
