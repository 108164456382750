import React, { useState, useEffect } from "react";
import { TextField, Divider, CircularProgress } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { adminsettings, getAdminSettings } from "../../DAL/adminDefaultSetting";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";

const AdminGeneralSettings = () => {
  const [settings, setSettings] = useState({
    admin_login_page_text: "",
    admin_portal_welcome_text: "",
    brand_favicon: null,
    brand_logo: null,
    login_page_image: null,
    contact_us_support_email: "",
    instagram_link: "",
    facebook_link: "",
    twitter_link: "",
    linkedin_link: "",
    copyright_text: "",
    website_brand_logo: null,
    website_brand_favicon: null,
    footer_logo: null,
  });
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [branFavIconLogogo, setBrandfavIconLogoo] = useState(null);
  const [adminLogo, setAdminLogo] = useState(null);
  const [loginPageImage, setLoginPageImage] = useState(null);
  const [websiteLogo, setWebsiteLogo] = useState(null);
  const [websiteFav, setWebsiteFav] = useState(null);
  const [websiteFooter, setWebsiteFooter] = useState(null);
  const [faviconPreview, setFaviconPreview] = useState(null);
  const [adminlogoPreview, setAdminlogoPreview] = useState(null);
  const [loginPagePreview, setLoginPagePreview] = useState(null);
  const [websiteLogoPreview, setWebsiteLogoPreview] = useState(null);
  const [websiteFavPreview, setWebsiteFavPreview] = useState(null);
  const [websiteFooterPreview, setWebsiteFooterPreview] = useState(null);
  const [adminportalPreview, setadminportalPreview] = useState(null);
  const [adminPortalLogo, setAdminPortalLogo] = useState(null);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getAdminSettings();
      if (response.code === 200) {
        const data = response.admin_portal_settings.admin_portal_settings;
        setSettings({
          admin_login_page_text: data.admin_login_page_text || "",
          admin_portal_welcome_text: data.admin_portal_welcome_text || "",
          contact_us_support_email: data.contact_us_support_email || "",
          facebook_link: data.facebook_link || "",
          instagram_link: data.instagram_link || "",
          linkedin_link: data.linkedin_link || "",
          twitter_link: data.twitter_link || "",
          copyright_text: data.copyright_text || "",
        });
        setFaviconPreview(s3baseUri + data.brand_favicon?.thumbnail_1);
        setLoginPagePreview(s3baseUri + data.login_page_image?.thumbnail_1);
        setadminportalPreview(s3baseUri + data.admin_portal_logo?.thumbnail_1);
        setWebsiteLogoPreview(s3baseUri + data.website_brand_logo?.thumbnail_1);
        setWebsiteFavPreview(
          s3baseUri + data.website_brand_favicon?.thumbnail_1
        );
        setWebsiteFooterPreview(s3baseUri + data.footer_logo);
        setAdminlogoPreview(s3baseUri + data.brand_logo?.thumbnail_1);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setLoading(false);
    };

    fetchSettings();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      setSettings((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setSettings((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFavicon = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBrandfavIconLogoo(file);
      setFaviconPreview(URL.createObjectURL(file));
    }
  };
  const handleLoginPageImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoginPageImage(file);
      setLoginPagePreview(URL.createObjectURL(file));
    }
  };
  const handleportallogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAdminPortalLogo(file);
      setadminportalPreview(URL.createObjectURL(file));
    }
  };
  const handleWebsiteLogoImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWebsiteLogo(file);
      setWebsiteLogoPreview(URL.createObjectURL(file));
    }
  };
  const handleWebsiteFavImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWebsiteFav(file);
      setWebsiteFavPreview(URL.createObjectURL(file));
    }
  };
  const handleWebsiteFooter = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWebsiteFooter(file);
      setWebsiteFooterPreview(URL.createObjectURL(file));
    }
  };
  const handleAdminLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAdminLogo(file);
      setAdminlogoPreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    const errors = {};
    let isValid = true;

    Object.keys(settings).forEach((key) => {
      if (
        !settings[key] &&
        key !== "brand_favicon" &&
        key !== "brand_logo" &&
        key !== "website_brand_logo" &&
        key !== "website_brand_favicon" &&
        key !== "footer_logo" &&
        key !== "login_page_image"
      ) {
        errors[key] = `${key.replace(/_/g, " ").toUpperCase()} is required`;
        isValid = false;
      }
    });

    setErrors(errors);

    if (isValid) {
      setSubmitting(true);
      const formData = new FormData();

      for (const key in settings) {
        if (settings[key]) {
          formData.append(key, settings[key]);
        }
      }
      if (branFavIconLogogo) {
        formData.append("brand_favicon", branFavIconLogogo);
      }
      if (adminPortalLogo) {
        formData.append("admin_portal_logo", adminPortalLogo);
      }
      if (adminLogo) {
        formData.append("brand_logo", adminLogo);
      }
      if (loginPageImage) {
        formData.append("login_page_image", loginPageImage);
      }
      if (websiteLogo) {
        formData.append("website_brand_logo", websiteLogo);
      }
      if (websiteFav) {
        formData.append("website_brand_favicon", websiteFav);
      }
      if (websiteFooter) {
        formData.append("footer_logo", websiteFooter);
      }
      const response = await adminsettings(formData);
      if (response.code === 200) {
        enqueueSnackbar("Settings updated successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setSubmitting(false);
    } else {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
    }
  };

  const renderImagePreview = (file) => {
    if (!file) return null;
    const url = typeof file === "string" ? file : URL.createObjectURL(file);
    return (
      <img
        src={url}
        alt="Preview"
        style={{ maxWidth: "100px", maxHeight: "100px" }}
      />
    );
  };

  const renderImageGuide = (label) => (
    <p className="image-guide-text">
      {label} (Recommended Size {label})
    </p>
  );

  if (loading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-4">
        <h4>Admin Default Settings</h4>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="row">
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="Contact Us Support Email"
              variant="outlined"
              name="contact_us_support_email"
              value={settings.contact_us_support_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="Copyright Text"
              variant="outlined"
              name="copyright_text"
              value={settings.copyright_text}
              onChange={handleChange}
            />
          </div>
        </div>
        <TextField
          multiline
          rows={4}
          fullWidth
          label="Admin Login Page Text"
          variant="outlined"
          name="admin_login_page_text"
          value={settings.admin_login_page_text}
          onChange={handleChange}
          error={!!errors.admin_login_page_text}
          helperText={errors.admin_login_page_text}
          required
        />
        <TextField
          multiline
          rows={4}
          fullWidth
          label="Admin Portal Welcome Text"
          variant="outlined"
          name="admin_portal_welcome_text"
          value={settings.admin_portal_welcome_text}
          onChange={handleChange}
          error={!!errors.admin_portal_welcome_text}
          helperText={errors.admin_portal_welcome_text}
          required
        />
        <Divider />
        <div className="d-flex flex-column gap-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Favicon Logo </p>
              {renderImageGuide("100 x 100")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(faviconPreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="brand_favicon-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="brand_favicon-upload"
              type="file"
              hidden
              name="brand_favicon"
              onChange={handleFavicon}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Admin Logo </p>
              {renderImageGuide("300 x 300")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(adminlogoPreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="brand_logo-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="brand_logo-upload"
              type="file"
              hidden
              name="brand_logo"
              onChange={handleAdminLogo}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Login Page Image </p>
              {renderImageGuide("1920 x 1080")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(loginPagePreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="login_page_image-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="login_page_image-upload"
              type="file"
              hidden
              name="login_page_image"
              onChange={handleLoginPageImage}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Website Logo</p>
              {renderImageGuide("150 x 22")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(websiteLogoPreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="website_brand_logo-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="website_brand_logo-upload"
              type="file"
              hidden
              name="website_brand_logo"
              onChange={handleWebsiteLogoImage}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Website FavIcon</p>
              {renderImageGuide("32 x 32")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(websiteFavPreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="website_brand_favicon-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="website_brand_favicon-upload"
              type="file"
              hidden
              name="website_brand_favicon"
              onChange={handleWebsiteFavImage}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Website Footer Logo</p>
              {renderImageGuide("100 x 92")}
            </div>
            <div className="d-flex justify-content-center">
              {renderImagePreview(websiteFooterPreview)}
            </div>
            <label
              className="theme-button-contained"
              htmlFor="website_brand_footer-upload">
              <FileUpload />
              Upload
            </label>
            <input
              id="website_brand_footer-upload"
              type="file"
              hidden
              name="footer_logo"
              onChange={handleWebsiteFooter}
            />
          </div>
        </div>
        <Divider />
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p>Admin Portal Logo</p>
            {renderImageGuide("32 x 32")}
          </div>
          <div className="d-flex justify-content-center">
            {renderImagePreview(adminportalPreview)}
          </div>
          <label className="theme-button-contained" htmlFor="admin_portal_logo">
            <FileUpload />
            Upload
          </label>
          <input
            id="admin_portal_logo"
            type="file"
            hidden
            name="admin_portal_logo"
            onChange={handleportallogo}
          />
        </div>
      </div>
      <Divider />
      <div>
        <h4 className="mt-2">Social Links</h4>
        <div className="row">
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="Facebook Link"
              variant="outlined"
              name="facebook_link"
              value={settings.facebook_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="Instagram Link"
              variant="outlined"
              name="instagram_link"
              value={settings.instagram_link}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="Twitter Link"
              variant="outlined"
              name="twitter_link"
              value={settings.twitter_link}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-sm-12 mt-3">
            <TextField
              required
              fullWidth
              label="LinkedIn Link"
              variant="outlined"
              name="linkedin_link"
              value={settings.linkedin_link}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-end mt-2">
        <button
          className="theme-button-contained"
          onClick={handleSave}
          disabled={submitting}>
          {submitting ? <CircularProgress size={15} /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AdminGeneralSettings;
