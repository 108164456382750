import { invokeApi } from "../../utils/invokeApi";

export const getMenuListApi = async (data) => {
  const requestObj = {
    path: `api/menu/list_menu`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pagesListingApiNew = async () => {
  const requestObj = {
    path: `api/sale_page/get_sale_page_for/menu`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addMenuApi = async (data) => {
  const requestObj = {
    path: `api/menu/add_menu`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editMenuApi = async (data, id) => {
  const requestObj = {
    path: `api/menu/update_menu/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteMenuApi = async (id) => {
  const requestObj = {
    path: `api/menu/delete_menu/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
