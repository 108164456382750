import { useRef, useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@iconify/react";
import MenuPopover from "./MenuPopover";
import CustomPopoverSectionItems from "./CustomPopoverSectionItems";

export default function CustomPopoverSection(props) {
  const { menu, data } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [popoverWidth, setPopoverWidth] = useState(0);

  useEffect(() => {
    // console.log(data, "value of data.....");
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function calculateMaxLabelLength(data) {
    let maxLetters = 0;
    data.forEach((item) => {
      const labelLength = item.label.length;
      if (labelLength > maxLetters) {
        maxLetters = labelLength;
      }
    });
    return maxLetters;
  }

  useEffect(() => {
    if (menu.length > 8) {
      const maxLabelLength = calculateMaxLabelLength(menu);
      setPopoverWidth(maxLabelLength * 9.2 + 34);
    }
  }, []);

  return (
    <div
      style={{ cursor: "pointer" }}
      className="remove-border menu-background-circle"
    >
      <MoreVertIcon
        style={{
          cursor: "pointer",
          fontSize: 20,
        }}
        className="pointer custom-popover-icon"
        ref={anchorRef}
        onClick={handleOpen}
      />
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          marginLeft: 1.8,
          maxHeight: 300,
          minWidth: 2,
          width: popoverWidth ? popoverWidth : "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      >
        {/* {menu.length > 8 && (
          <div
            className="menu-search-box"
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Icon
              fontSize={20}
              className="menu-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="menu-search-input"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        )} */}
        {menu
          .filter((option) =>
            option.label.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((option) => (
            <CustomPopoverSectionItems
              key={option.id}
              item={option}
              data={data}
              setOpen={setOpen}
            />
          ))}
      </MenuPopover>
    </div>
  );
}
