import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import "@react-pdf-viewer/core/lib/styles/index.css";
import CustomMUITable from "../../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../../components/generalComponents/CustomDrawer";
import { manageResourcesApi } from "../../../DAL/programmes";
import AddOrUpdateProgrammeResourceDrawer from "../../../components/managResources/AddOrUpdateProgrammeResourceDrawer";
import PageLoading from "../../../components/generalComponents/PageLoading";

const ManageProgrammeResources = () => {
  const initialValues = {
    openDeleteDialog: false,
    editProgrammeResourceDrawerOpen: false,
    addProgrammeResourceDrawerOpen: false,
    selectedCategory: null,
  };

  const [values, setValues] = useState(initialValues);
  const [resourceData, setResourceData] = useState([]);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { program_id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const program = location.state?.program;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchResources = async () => {
    setLoading(true);

    const postData = {
      program_id: program_id,
      search: searchText,
    };

    const response = await manageResourcesApi(page, rowsPerPage, postData);
    if (response.code === 200) {
      setResourceData(response.resources);
      setTotalCount(response.total_resources);
      setTotalPages(Math.ceil(response.total_resources / rowsPerPage) - 1);
    } else {
      enqueueSnackbar("Failed to fetch resources", { variant: "error" });
    }
    setLoading(false);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchResources();
  };

  useEffect(() => {
    fetchResources();
  }, [page, rowsPerPage]);

  const handleOpenEditDrawer = (category) => {
    setValues((old) => ({
      ...old,
      selectedCategory: category,
      editProgrammeResourceDrawerOpen: true,
    }));
  };

  const handleDelete = () => {
    setValues((old) => ({
      ...old,
      openDeleteDialog: true,
    }));
  };

  const handleCancel = () => {
    setValues((old) => ({
      ...old,
      openDeleteDialog: false,
    }));
  };

  const handleConfirmDelete = () => {
    setValues((old) => ({
      ...old,
      openDeleteDialog: false,
    }));
    enqueueSnackbar("Resource deleted successfully.", { variant: "success" });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick(row) {
        handleOpenEditDrawer(row);
      },
    },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleDelete,
    // },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Resources" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const Loader = () => (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );

  return (
    <div className="container">
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div
            className="mb-4 programmes-resources"
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="col-12">
              <Link className="custom-link" to={"/programmes"}>
                Programmes
              </Link>{" "}
              {" / "}
              <Link className="custom-link" to={-1}>
                Previous page
              </Link>
              {" / "}Manage Resources {" / "}
              {program.title}
            </div>
            <div className="col-lg-12 col-md-8 col-sm-6 mb-3 programmes-resources-content">
              <h4>Programme Resources</h4>
              {/* <button onClick={handleAddResource}>Add resource</button> */}
            </div>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={resourceData}
              pagePagination={true}
              MENU_OPTIONS={MENU_OPTIONS}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
          <CustomConfirmation
            open={values.openDeleteDialog}
            setOpen={handleCancel}
            title={"Are you sure you want to delete this category?"}
            handleAgree={handleConfirmDelete}
          />

          <CustomDrawer
            isOpenDrawer={
              values.addProgrammeResourceDrawerOpen ||
              values.editProgrammeResourceDrawerOpen
            }
            onOpenDrawer={() => {}} // You may want to add appropriate functionality
            onCloseDrawer={() => {
              setValues((old) => ({
                ...old,
                addProgrammeResourceDrawerOpen: false,
                editProgrammeResourceDrawerOpen: false,
              }));
            }}
            pageTitle={`${
              values.selectedCategory ? "Update" : "Add"
            } Resources`}
            componentToPassDown={
              <AddOrUpdateProgrammeResourceDrawer
                onCloseDrawer={() => {
                  setValues((old) => ({
                    ...old,
                    addProgrammeResourceDrawerOpen: false,
                    editProgrammeResourceDrawerOpen: false,
                  }));
                }}
                selectedObject={values.selectedCategory}
                programId={program_id}
                fetchResources={fetchResources}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default ManageProgrammeResources;
