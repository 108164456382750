import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextEditor from "../../components/generalComponents/TextEditor";
import {
  getEmailTemplate,
  updateEmailTemplate,
} from "../../DAL/emailTemplatesListApi";
import PageLoading from "../../components/generalComponents/PageLoading";

const EditEmailTemplate = () => {
  const [template, setTemplate] = useState({
    title: "",
    cc_email: "",
    email_subject: "",
    email_message: "",
    status: true,
    emailBody: "",
    errors: {},
  });

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { email_template_slug } = useParams();

  const textFieldStyles = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "&.Mui-focused fieldset": {
        borderColor: "#EA7423",
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "#EA7423",
      },
    },
  };

  const editorStyles = {
    borderRadius: "8px",
    padding: "8px",
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await getEmailTemplate(email_template_slug);
      if (response.code === 200) {
        setTemplate({
          ...response.email_template,
          status: response.email_template.status,
          errors: {},
        });
      } else {
        enqueueSnackbar("Failed to fetch template details", {
          variant: "error",
        });
      }
      console.error("Error fetching email template:");
    };
    setLoading(false);

    fetchTemplate();
  }, [email_template_slug, enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const handleSave = async () => {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("title", template.title);
    formData.append("cc_email", template.cc_email);
    formData.append("email_subject", template.email_subject);
    formData.append("email_message", template.email_message);
    formData.append("status", template.status ? true : false);

    const response = await updateEmailTemplate(email_template_slug, formData);

    if (response.code === 200) {
      enqueueSnackbar("Template updated successfully!", {
        variant: "success",
      });
      setTimeout(() => {
        navigate("/email-templates");
      }, 2000);
    } else {
      enqueueSnackbar("Failed to update template", { variant: "error" });
    }

    setButtonLoading(false);
  };

  if (loading) return <PageLoading />;

  return (
    <>
      <div className="container-fluid d-flex justify-content-start align-items-center mt-2 mb-2">
        <Link className="custom-link" to="/email-templates">
          Email Templates
        </Link>
        {"  /  "}
        Edit Email Template
      </div>
      <div className="container-fluid p-2">
        <h4 className="container">Edit Email Template</h4>

        <div className="container gap-2">
          <div className="div-gapmembers d-flex mt-3 mb-3">
            <div className="col-6 ">
              <TextField
                fullWidth
                required
                label="Title"
                variant="outlined"
                name="title"
                value={template.title}
                onChange={handleChange}
                sx={textFieldStyles}
                error={!!errors.title}
                helperText={errors.title}
              />
            </div>
            <div className="col-6">
              <TextField
                required
                fullWidth
                label="CC Email"
                variant="outlined"
                name="cc_email"
                value={template.cc_email}
                onChange={handleChange}
                sx={textFieldStyles}
                error={!!errors.cc_email}
                helperText={errors.cc_email}
              />
            </div>
          </div>

          <div className="div-gapmembers d-flex mt-3 mb-3">
            <div className="col-6">
              <TextField
                required
                fullWidth
                label="Email Subject"
                variant="outlined"
                name="email_subject"
                value={template.email_subject}
                onChange={handleChange}
                sx={textFieldStyles}
                error={!!errors.email_subject}
                helperText={errors.email_subject}
              />
            </div>

            <div className="col-6">
              <FormControl fullWidth variant="outlined" sx={textFieldStyles}>
                <InputLabel>Status</InputLabel>
                <Select
                  required
                  label="Status"
                  name="status"
                  value={template.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="div-gapmembers d-flex mt-3 mb-3">
            <div className="col-12"></div>
          </div>

          <div className="col-12 mt-2">
            <p className="mb-0">Email Body</p>
            <div style={editorStyles}>
              <TextEditor
                inputs={template}
                setInputs={setTemplate}
                name="email_message"
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-6 col-sm-4 container mt-2 d-flex justify-content-end">
            <button
              className="theme-button-contained"
              onClick={handleSave}
              disabled={buttonLoading}
            >
              {buttonLoading ? <CircularProgress size={15} /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmailTemplate;
