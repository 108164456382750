import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import NavSection from "./NavSection";
import { sidebarConfig } from "./sidebarConfig";
import { getAdminSettings } from "../../DAL/adminDefaultSetting";
import { s3baseUri } from "../../config/config";

export default function SidebarContent(props) {
  const { searchTerm, setSearchTerm } = props;
  const [adminSettings, setAdminSettings] = useState(null);

  useEffect(() => {
    const fetchAdminSettings = async () => {
      const response = await getAdminSettings();
      setAdminSettings(response.admin_portal_settings.admin_portal_settings);
    };

    fetchAdminSettings();
  }, []);

  const filteredMenuItems = sidebarConfig.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.children &&
        item.children.some((child) =>
          child.title.toLowerCase().includes(searchTerm.toLowerCase())
        ))
  );

  return (
    <div>
      <div className="d-flex justify-content-center p-4">
        {adminSettings ? (
          <img
            src={s3baseUri + adminSettings.admin_portal_logo?.thumbnail_1}
            alt="Admin Portal Logo"
            style={{ width: "100%", maxWidth: "150px", height: "auto" }}
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Grid
        sx={{ p: 1, mt: 0, ml: 0.7, display: "flex", justifyContent: "center" }}
      >
        <TextField
          variant="outlined"
          placeholder="Search..."
          autoComplete="off"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            sx: {
              color: "#EA7423",
            },
          }}
          sx={{
            marginRight: "auto",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              height: "40px",
              width: "240px",
              "& fieldset": {
                borderColor: "lightgray",
              },
              "&:hover fieldset": {
                borderColor: "lightgray",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EA7423",
              },
            },
          }}
        />
      </Grid>
      <NavSection navConfig={filteredMenuItems} />
    </div>
  );
}
