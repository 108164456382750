import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { addSchool, updateSchool } from "../../DAL/school";

const INITIAL_VALUES = {
  title: "",
  description: "",
  status: true,
};

export default function AddorUpdateSchools(props) {
  const { selectedObject, onCloseDrawer, refreshList } = props;
  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append("school_name", inputs.title);
    formData.append("short_description", inputs.description);
    formData.append("status", inputs.status);

    let response;
    if (selectedObject) {
      response = await updateSchool(selectedObject._id, formData);
    } else {
      response = await addSchool(formData);
    }

    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      refreshList();
      onCloseDrawer();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (selectedObject) {
      const data = selectedObject;
      setInputs({
        title: data.school_name,
        description: data.short_description,
        status: data.status,
      });
    }
  }, [selectedObject]);

  return (
    <form onSubmit={handleSubmit} className="drawer-form">
      <TextField
        required
        label="Name"
        variant="outlined"
        value={inputs.title}
        name="title"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        multiline
        rows={5}
        required
        label="Short Description"
        variant="outlined"
        value={inputs.description}
        name="description"
        onChange={handleChange}
        fullWidth
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          required
          labelId="status-label"
          label="Status"
          value={inputs.status}
          name="status"
          onChange={handleChange}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
      <div className="d-flex justify-content-end">
        <button
          className="theme-button-contained"
          type="submit"
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress size={15} />
          ) : selectedObject ? (
            "Update"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
