import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import SidebarContent from "./SidebarContent";

function AppSidebar({ drawerWidth, mobileOpen, handleDrawerClose }) {
  const [searchTerm, setSearchTerm] = useState("");

  const contentProps = { searchTerm, setSearchTerm };

  let drawer_style = {
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  };

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        sx={{
          display: { xs: "block", sm: "none" },
          drawer_style,
        }}
      >
        <SidebarContent {...contentProps} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          drawer_style,
        }}
        open
      >
        <SidebarContent {...contentProps} />
      </Drawer>
    </Box>
  );
}

export default AppSidebar;
