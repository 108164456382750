import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  addStripeSettingApi,
  stripeSettingApi,
} from "../../DAL/AllSettings/AllSettings";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const StripeSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = React.useState({
    key: "Active Campaign",
    secretKey: "",
    publishKey: "",
    livesSecretKey: "",
    stripeMode: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await stripeSettingApi();
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ["key"]: result?.stripe_setting?.sandBox_publish_key,
        ["secretKey"]: result?.stripe_setting?.sandBox_secret_key,
        ["publishKey"]: result?.stripe_setting?.live_publish_key,
        ["livesSecretKey"]: result?.stripe_setting?.live_secret_key,
        ["stripeMode"]: result?.stripe_setting?.stripe_mode,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      sandBox_publish_key: inputs.key,
      sandBox_secret_key: inputs.secretKey,
      live_publish_key: inputs.publishKey,
      live_secret_key: inputs.livesSecretKey,
      stripe_mode: inputs.stripeMode,
    };

    setIsLoading(true);
    const result = await addStripeSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <h4>Stripe Setting</h4>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Sandbox Publish Key"
            variant="outlined"
            fullWidth
            required
            aria-readonly
            name="key"
            value={inputs.key}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Sandbox Secret Key "
            variant="outlined"
            fullWidth
            required
            aria-readonly
            name="secretKey"
            value={inputs.secretKey}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Live Publish Key"
            variant="outlined"
            fullWidth
            required
            name="publishKey"
            value={inputs.publishKey}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Live Secret Key"
            variant="outlined"
            fullWidth
            required
            name="livesSecretKey"
            value={inputs.livesSecretKey}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Stripe Mode *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="stripeMode"
              value={inputs.stripeMode}
              label="Stripe Mode *"
              onChange={handleChange}>
              <MenuItem value="sandBox">SandBox</MenuItem>
              <MenuItem value="live">Live</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="theme-button-contained">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default StripeSettings;
