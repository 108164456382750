import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  emailTemplatesListApi,
  getEmailTemplate,
} from "../../DAL/emailTemplatesListApi";
import PageLoading from "../../components/generalComponents/PageLoading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { enqueueSnackbar } from "notistack";

const EmailTemplatePreview = () => {
  const { slug } = useParams();
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await getEmailTemplate(slug);
      if (response.code === 200) {
        setTemplate({
          ...response.email_template,
          email_body: response.email_body._email_body,
        });
      } else {
        enqueueSnackbar(response.error, {
          variant: "error",
        });
      }
      setLoading(false);
    };

    fetchTemplate();
  }, [slug]);

  if (loading) return <PageLoading />;

  if (!template) return <div>Template not found</div>;

  return (
    <div className="container">
      <div className="col-md-6 d-flex mb-3">
        <Link to="/email-templates">
          <ArrowBackIcon />
        </Link>
      </div>
      <h2>{template.email_subject}</h2>
      <iframe
        className="responsive-iframe"
        srcDoc={template.email_body}
        width="100%"
        height="1600px"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default EmailTemplatePreview;
