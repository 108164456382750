export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
export const resources = [
  {
    name: "Resource 1",
    description: "This is Resource 1 description.",
    status: "Active",
    url: "path/to/resource1.pdf",
    programme: "Lizard 1",
  },
  {
    name: "Resource 2",
    description: "This is Resource 2 description.",
    status: "Inactive",
    url: "path/to/resource2.pdf",
    programme: "Lizard 2",
  },
  {
    name: "Resource 3",
    description: "This is Resource 3 description.",
    status: "Active",
    url: "path/to/resource3.pdf",
    programme: "Lizard 3",
  },
  {
    name: "Resource 4",
    description: "This is Resource 4 description.",
    status: "Inactive",
    url: "path/to/resource4.pdf",
    programme: "Lizard 3",
  },
  {
    name: "Resource 5",
    description: "This is Resource 5 description.",
    status: "Active",
    url: "path/to/resource5.pdf",
    programme: "Lizard 5",
  },
  {
    name: "Resource 6",
    description: "This is Resource 6 description.",
    status: "Inactive",
    url: "path/to/resource6.pdf",
    programme: "Lizard 6",
  },
];

export const resourcesList = [
  {
    name: "Resource 1",
    description: "This is Resource 1 description.",
    status: "Active",
    url: "path/to/resource1.pdf",
    programme: "Lizard 1",
  },
  {
    name: "Resource 2",
    description: "This is Resource 2 description.",
    status: "Inactive",
    url: "path/to/resource2.pdf",
    programme: "Lizard 2",
  },
  {
    name: "Resource 3",
    description: "This is Resource 3 description.",
    status: "Active",
    url: "path/to/resource3.pdf",
    programme: "Lizard 3",
  },
  {
    name: "Resource 4",
    description: "This is Resource 4 description.",
    status: "Inactive",
    url: "path/to/resource4.pdf",
    programme: "Lizard 3",
  },
  {
    name: "Resource 5",
    description: "This is Resource 5 description.",
    status: "Active",
    url: "path/to/resource5.pdf",
    programme: "Lizard 5",
  },
  {
    name: "Resource 6",
    description: "This is Resource 6 description.",
    status: "Inactive",
    url: "path/to/resource6.pdf",
    programme: "Lizard 6",
  },
];
export const handleImageExtensions = (menu) => {
  let val = menu?.map((el) => {
    return '"' + el.substring(1).toLowerCase() + '"' + ",";
  });
  let unique = val?.filter((item, i, ar) => ar.indexOf(item) === i);
  return unique;
};
export const convertCurrencyToSign = (currency) => {
  if (currency === "gbp" || currency === "GBP") {
    return "£";
  } else if (currency === "eur" || currency === "EUR") {
    return "€";
  } else if (currency === "usd" || currency === "USD") {
    return "$";
  } else {
    return "";
  }
};
