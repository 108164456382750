import { invokeApi } from "../../utils/invokeApi";

export const webPageModuleListing = async (data) => {
  const requestObj = {
    path: `api/page_modules/list_page_modules`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addWebPageModuleListing = async (data) => {
  const requestObj = {
    path: `api/page_modules/add_module`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editWebPageModuleListing = async (data, id) => {
  const requestObj = {
    path: `api/page_modules/update_module_detail/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWebPageModule = async (id) => {
  const requestObj = {
    path: `api/page_modules/delete_module/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailWebPageModule = async (id) => {
  const requestObj = {
    path: `api/module_configuration/get_module_configration_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailModule = async (id) => {
  const requestObj = {
    path: `api/page_modules/get_module_details/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const uploadModuleImageOns3 = async (data) => {
  const requestObj = {
    path: `api/page_modules/update_image_on_s3_for_module`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
