import { invokeApi } from "../utils/invokeApi";

export const subscriptionList = async (page, limmit, data) => {
  const requestObj = {
    path: `api/admin/all_subscription_list?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
