import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@cyntler/react-doc-viewer/dist/index.css";
import CustomMUITable from "../../../components/customMUITable/CustomMUITable";
import { pdflogo } from "../../../assets";
import GeneralMuiModal from "../../../components/generalComponents/GeneralMuiModal";
import { deleteResourceApi, ResourcesListApi } from "../../../DAL/Resource";
import { enqueueSnackbar } from "notistack";
import PageLoading from "../../../components/generalComponents/PageLoading";
import CustomConfirmation from "../../../components/generalComponents/CustomConfirmation";
import ResourceAction from "../programmeComponents/ResourceAction";

const ManageResourceOperation = () => {
  const INITIAL_VALUES = {
    dialogOpen: false,
    pdfDialogOpen: false,
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      renderData: (row) => (
        <ResourceAction
          item={row}
          MENU_OPTIONS={MENU_OPTIONS}
          setPdfDialogOpen={setPdfDialogOpen}
          setActiveDoc={setActiveDoc}
        />
      ),
    },
    { id: "number", label: "#", type: "number" },
    { id: "icon", label: "Icon", type: "thumbnail" },
    { id: "title", label: "Title" },
    // { id: "description", label: "Description" },
    {
      id: "programme",
      label: "Programme",
      renderData: (row) => {
        return (
          <>
            {row.program_info && row.program_info.length > 0
              ? row.program_info.map((item, index) => (
                  <p key={index} style={{ marginBottom: "0px" }}>
                    {item.program_title}
                  </p>
                ))
              : "N/A"}
          </>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const [inputs, setInputs] = useState(INITIAL_VALUES);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteResources, setDeleteResources] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [activeDoc, setActiveDoc] = useState(null);
  const [delloading, setDelLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const navigate = useNavigate();

  const fetchResources = async () => {
    let postData = {
      search: searchText,
    };

    const response = await ResourcesListApi(page, rowsPerPage, postData);
    if (response.code === 200) {
      const formattedResources = response.resources.map((resource) => ({
        _id: resource._id,
        resource_slug: resource.resource_slug,
        title: resource.title,
        description: resource.description,
        status: resource.status,
        icon: {
          src: pdflogo,
          alt: resource.title,
        },
        programme: resource.program_info
          .map((info) => info.program_title)
          .join(", "),
        url: resource.document_file_url,
        program_info: resource.program_info,
        documentType: resource.document_file_type,
      }));
      setResources(formattedResources);
      setTotalCount(response.total_resources);
      setTotalPages(Math.ceil(response.total_resources / rowsPerPage) - 1);
    } else {
      console.error(response.message);
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchResources();
  };

  useEffect(() => {
    fetchResources();
  }, [page, rowsPerPage]);

  const setPdfDialogOpen = useCallback(
    (value) => {
      setInputs((old) => ({ ...old, pdfDialogOpen: value }));
    },
    [setInputs]
  );
  const hadleEdit = (row) => {
    navigate(`/manage-programme-resources/edit-resources`, {
      state: { program: row },
    });
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick(row, index) {
        hadleEdit(row);
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick(row, index) {
        setDeleteResources(row.resource_slug);
        setDeleteDialogOpen(true);
      },
    },
  ];

  const handleDeleteMember = async (resourceId) => {
    setDelLoading(true);
    const response = await deleteResourceApi(resourceId);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setResources(
        resources.filter((resource) => resource.resource_slug !== resourceId)
      );
      setDeleteDialogOpen(false);
    } else {
      setDeleteDialogOpen(false);
    }
    setDelLoading(false);
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="col-lg-12 col-sm-6 col-md-6 mb-3 mt-2 resource-content">
            <h4>Resources</h4>
            <button
              className="theme-button-contained"
              onClick={() =>
                navigate("/manage-programme-resources/add-resources")
              }
            >
              Add Resource
            </button>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={resources}
              pagePagination={true}
              MENU_OPTIONS={MENU_OPTIONS}
              loading={loading}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>

          <GeneralMuiModal
            open={inputs.pdfDialogOpen}
            setOpen={setPdfDialogOpen}
            title="View Document"
            paperClass="resource-pdf"
            componentToPass={
              <>
                <iframe src={activeDoc} width="100%" height="500px" />
              </>
            }
          />
          <CustomConfirmation
            open={deleteDialogOpen}
            setOpen={setDeleteDialogOpen}
            title={"Are you sure you want to delete this resource?"}
            handleAgree={() => {
              handleDeleteMember(deleteResources);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ManageResourceOperation;
