import React, { useState, useEffect } from "react";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";
import CustomDrawer from "../../components/generalComponents/CustomDrawer";
import AddOrUpdateRecordingDrawer from "../../components/recordingComponents/AddOrUpdateRecordingDrawer";
import { useSnackbar } from "notistack";
import { deleteRecordingApi, RecordingApi } from "../../DAL/recording";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";

const RecordingList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "profile_photo", label: "Image", type: "thumbnail" },
    { id: "title", label: "Title" },
    // { id: "detailed_description", label: "Description" },
    {
      id: "category",
      label: "Category",
      renderData: (row) => {
        return (
          <>
            {row.program_recording_category_info &&
            row.program_recording_category_info.length > 0
              ? row.program_recording_category_info.map((item, index) => (
                  <p key={index} style={{ marginBottom: "0px" }}>
                    {item.category_name}
                  </p>
                ))
              : "N/A"}
          </>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const INITIAL_VALUES = {
    deleteRecordingId: null,
    deleteDialogOpen: false,
    drawerOpen: false,
    selectedCategory: null,
    image: "",
  };

  const [recordingListState, setRecordingListState] = useState(INITIAL_VALUES);
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteProgramme, setDeleteProgramme] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const fetchRecordings = async () => {
    let postData = {
      search: searchText,
    };
    const response = await RecordingApi(page, rowsPerPage, postData);
    if (response.code === 200) {
      const recordingsWithCategories = response.recordings.map(
        (recording, index) => ({
          ...recording,
          number: index + 1,
          description: recording.detailed_description,
          profile_photo: {
            src: s3baseUri + recording.image?.thumbnail_1,
            alt: recording.title,
          },
        })
      );
      setTotalCount(response.total_recordings);
      setTotalPages(Math.ceil(response.total_recordings / rowsPerPage) - 1);
      setRecordings(recordingsWithCategories);
    } else {
      enqueueSnackbar("Failed to fetch recordings", { variant: "error" });
    }
    setLoading(false);
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchRecordings();
  };

  useEffect(() => {
    fetchRecordings();
  }, [page, rowsPerPage]);

  const handleOpenDeleteDialog = (recordingId) => {
    setRecordingListState((old) => ({
      ...old,
      deleteRecordingId: recordingId,
      deleteDialogOpen: true,
    }));
  };

  const handleOpenEditDrawer = (category) => {
    setRecordingListState((old) => ({
      ...old,
      selectedCategory: category,
      drawerOpen: true,
    }));
  };

  const handleDeleteCategory = async () => {
    setDeleteLoading(true);

    const response = await deleteRecordingApi(
      recordingListState.deleteRecordingId
    );
    if (response.code === 200) {
      setRecordings((oldRecordings) =>
        oldRecordings.filter(
          (recording) =>
            recording.recording_slug !== recordingListState.deleteRecordingId
        )
      );

      enqueueSnackbar("Recording deleted successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Failed to delete recording", { variant: "error" });
    }
    setRecordingListState((old) => ({ ...old, deleteDialogOpen: false }));
    setDeleteLoading(false);
  };

  const handleOpenDrawer = () => {
    setRecordingListState((old) => ({
      ...old,
      drawerOpen: true,
      selectedCategory: null,
    }));
  };

  const handleCloseDrawer = () => {
    setRecordingListState((old) => ({ ...old, drawerOpen: false }));
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick(row) {
        handleOpenEditDrawer(row);
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick(row, index) {
        setDeleteProgramme(row.recording_slug);
        setDeleteDialogOpen(true);
      },
    },
  ];

  const handleDeleteMember = async (programmeID) => {
    const response = await deleteRecordingApi(programmeID);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setRecordings(
        recordings.filter(
          (programme) => programme.recording_slug !== programmeID
        )
      );
      setDeleteDialogOpen(false);
    } else {
      setDeleteDialogOpen(false);
    }
  };

  return (
    <>
      <div className="row mb-3 mt-3 recordingCategoryList">
        <div className="col-lg-6 col-md-6 d-flex align-items-center">
          <h4 className="mb-0">Recording List</h4>
        </div>
        <div className="col-lg-6 col-md-6 d-flex justify-content-end align-items-center ">
          <button
            className="theme-button-contained py-2 px-3"
            onClick={handleOpenDrawer}
          >
            Add recording
          </button>
        </div>
      </div>
      <div className="col-12">
        {loading ? (
          <>
            <PageLoading />
          </>
        ) : (
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={recordings}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        )}
      </div>
      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this recording?"}
        handleAgree={() => {
          handleDeleteMember(deleteProgramme);
        }}
      />{" "}
      <CustomDrawer
        isOpenDrawer={recordingListState.drawerOpen}
        onOpenDrawer={handleCloseDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${
          recordingListState.selectedCategory ? "Update" : "Add"
        } Recording`}
        componentToPassDown={
          <AddOrUpdateRecordingDrawer
            onCloseDrawer={handleCloseDrawer}
            selectedObject={recordingListState.selectedCategory}
            refreshList={fetchRecordings}
          />
        }
      />
    </>
  );
};

export default RecordingList;
